import {
  GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_INITIATED,
  GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_SUCCEED,
  GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_FAILED,
  POST_CREATE_PERSONA_API_INITIATED,
  POST_CREATE_PERSONA_API_SUCCEED,
  POST_CREATE_PERSONA_API_FAILED,
} from "../constants/createPersonaConstants";

export const getLineOfBusinessWithCategoriesApiInitiated = () => ({
  type: GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_INITIATED,
});

export const getLineOfBusinessWithCategoriesApiSucceed = (payload: any) => ({
  type: GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_SUCCEED,
  payload: payload,
});

export const getLineOfBusinessWithCategoriesApiFailed = (payload: any) => ({
  type: GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_FAILED,
  payload: payload,
});

export const postCreatePersonaApiInitiated = () => ({
  type: POST_CREATE_PERSONA_API_INITIATED,
});

export const postCreatePersonaApiSucceed = (payload: any) => ({
  type: POST_CREATE_PERSONA_API_SUCCEED,
  payload: payload,
});

export const postCreatePersonaApiFailed = (payload: any) => ({
  type: POST_CREATE_PERSONA_API_FAILED,
  payload: payload,
});
