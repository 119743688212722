import {
  GET_PERSONA_LIST_API_INITIATED,
  GET_PERSONA_LIST_API_SUCCEED,
  GET_PERSONA_LIST_API_FAILED,
} from "../constants/personaListConstants";

const initialState = {
  personaListApiCallInitiated: false,
  personaListApiResponse: [],
  personaListApiCallFailed: {},
};

const personaListReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PERSONA_LIST_API_INITIATED:
      return {
        personaListApiCallInitiated: true,
        personaListApiResponse: [],
        personaListApiCallFailed: {},
      };
    case GET_PERSONA_LIST_API_SUCCEED:
      return {
        personaListApiCallInitiated: false,
        personaListApiResponse: action.payload,
        personaListApiCallFailed: {},
      };
    case GET_PERSONA_LIST_API_FAILED:
      return {
        personaListApiCallInitiated: false,
        personaListApiResponse: [],
        personaListApiCallFailed: action.payload,
      };
    default:
      return state;
  }
};
export default personaListReducers;
