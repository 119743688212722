import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./PersonaView.scss";
import { Link } from "react-router-dom";
import { plusSymbol } from "../../../assets";
import {
  PersonaCard,
  Card,
  EmptyMessageComponent,
  ProcessingDialog,
  ErrorPopup,
} from "../../../components/index";
import { AxiosError, AxiosResponse } from "axios";
import {
  getPersonaListApiInitiated,
  getPersonaListApiSucceed,
  getPersonaListApiFailed,
} from "../../../stateManagement/actions/personaListActions";
import {
  clearCacheAndCookies,
  getAuthToken,
  getUserEmail,
} from "../../../utils/Utils";
import { useAuth } from "../../../routes/useAuth";
import { useNavigate } from "react-router-dom";
import { initiateApiCall } from "../../../api";

const PersonaView = () => {
  const {
    personaListApiCallInitiated,
    personaListApiResponse,
    personaListApiCallFailed,
  } = useSelector((state: any) => state.personaListReducers, shallowEqual);

  const { setAuthToken }: any = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchPersonaList();
  }, []);
  const handleCloseError = () => {
    dispatch(getPersonaListApiFailed({}));
  };
  const fetchPersonaList = async () => {
    dispatch(getPersonaListApiInitiated());
    try {
      const response: AxiosResponse<any> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/persona`,
        null,
        {
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          User: getUserEmail(),
          "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
        }
      );
      console.log("response code", typeof response.data.code);
      if (response.data.code === 200) {
        if (response.data.result === null) {
          setErrorMessage("Please create your own persona");
          dispatch(getPersonaListApiSucceed([]));
        } else {
          dispatch(getPersonaListApiSucceed(response.data.result.personas));
        }
      } else if (response.data.code === 401) {
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        setErrorMessage("Something Went Wrong !!");
        dispatch(
          getPersonaListApiFailed({
            boldMessage: response.data.message,
            message: response.data.error.error,
          })
        );
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as any;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          setErrorMessage("Something Went Wrong !!");
          dispatch(
            getPersonaListApiFailed({
              boldMessage: "Error",
              message: axiosError.response.data.error,
            })
          );
        } else {
          setErrorMessage("Something Went Wrong !!");
          dispatch(
            getPersonaListApiFailed({
              boldMessage: "Error",
              message: "Something Went Wrong",
            })
          );
        }
      }
    }
  };

  return (
    <div className="persona-container">
      {personaListApiCallInitiated && <ProcessingDialog message="Loading..." />}
      <div className="manage-persona-title">
        <div className="persona-manage-label">Assign/Manage Personas</div>
        <div className="create-persona-button">
          <div>
            <img className="plus-symbol" src={plusSymbol} alt="Close" />
          </div>
          <div>
            <Link to="/createPersona">Create Persona</Link>
          </div>
        </div>
      </div>
      {personaListApiResponse && personaListApiResponse?.length ? (
        <div className="persona-list-container">
          {personaListApiResponse?.map((personaDetail: any, index: any) => {
            return (
              <Card key={index}>
                <PersonaCard
                  personaDetail={personaDetail}
                  fetchPersonaList={fetchPersonaList}
                />
              </Card>
            );
          })}
        </div>
      ) : (
        !personaListApiCallInitiated && (
          <div className="persona-list-empty-message-container">
            <EmptyMessageComponent message={errorMessage} />
          </div>
        )
      )}

      {personaListApiCallFailed &&
      Object.keys(personaListApiCallFailed)?.length ? (
        <ErrorPopup
          boldMessage={personaListApiCallFailed.boldMessage}
          message={personaListApiCallFailed.message}
          onClose={handleCloseError}
        />
      ) : null}
    </div>
  );
};

export default PersonaView;
