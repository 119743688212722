import React, { useEffect, useState } from "react";
import "./MyTeam.scss";
import { add_icon } from "../../../assets";
import CreateTeamPopup from "./createTeamPopup/CreateTeamPopup";
import { useAuth } from "../../../routes/useAuth";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { ApiResponse } from "../../appDetailsScreen/AppDetailsScreen";
import { initiateApiCall } from "../../../api";
import { clearCacheAndCookies, fetchUserName, getAuthToken, getUserEmail } from "../../../utils/Utils";
import { EmptyMessageComponent, ErrorPopup, ProcessingDialog, SuccessPopup } from "../../../components";
import { getTeamApiFailed, getTeamApiFinished, getTeamApiInitiated, getTeamApiSucceed } from "../../../stateManagement/actions/myTeamActions";
import { ErrorResponse } from "../../../interfaces/common/ErrorResponse";
import TeamTable from "../../../components/myTeam/TeamTable";
import { getMyAppsApiFailed } from "../../../stateManagement/actions/myAppsActions";
import { processErrorResponse, processSuccessResponse } from "../../../utils/ResponseProcessor";

export interface TeamMemberRole {
  'member-role-id': number;
  'member-role-name': string;
}

export interface TeamMemberData {
  'member-id': number;
  'member-name': string;
  'member-email-id': string;
  'member-role': TeamMemberRole;
}

export interface TeamResponse {
  code: number;
  message: string;
  result: {
    'team-id': number;
    'team-name': string;
    'team-owner-email-id': string;
    'team-members': TeamMemberData[];
  };
  error: string;
}

const MyTeam = () => {
  const [enablePopup, setEnablePopup] = useState(false);
  const [teamMembers, setTeamMembers] = useState([
    { name: "", email: "" },
  ]);
  const { setAuthToken }: any = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const [successPopupData, setSuccessPopupData] = useState({
    boldMessage: "",
    message: "",
  });

  const [userName, setUserName] = useState<string>("");
  const [editData, setEditData] = useState<{ member: TeamMemberData; isEdit: boolean; isDelete: boolean } | null>(null);
  const {
    apiCallInitiated,
    myTeamApiResponse,
    myTeamApiFailed
  } = useSelector((state: any) => state.myTeamReducers, shallowEqual);

  const [showLoader, setShowLoader] = useState(false);

  const handleEdit = (member: TeamMemberData) => {
    setEditData({ member: member, isEdit: true, isDelete: false });
    setEnablePopup(true);
  };

  const handleDelete = (member: TeamMemberData) => {
    setEditData({ member: member, isEdit: false, isDelete: true });
    setEnablePopup(true);
  };

  const handleSaveTeamMembers = (members: { name: string, email: string, errMsgForName: string, errMsgForEmail: string, memberId: number, memberRoleId: number }[]) => {
    if (editData) {
      callEditMemberApi(members[0].memberId, members[0].name, members[0].memberRoleId);
    } else {
      setTeamMembers(members);
      callCreateTeamMembersApi(members);
    }
    setEnablePopup(false);
  };

  const handleDeleteTeamMembers = (member: TeamMemberData) => {
    if (editData) {
      callDeleteMemberApi(member);
    }
    setEnablePopup(false);
  }

  const handleCloseError = () => {
    setIsErrorPopupVisible(false);
    callGetTeamMembersListApi();
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
    callGetTeamMembersListApi();
  };

  function handleSuccessMessage(message: string) {
    setSuccessPopupData({
      boldMessage: "",
      message: message,
    });
    setIsSuccessPopupVisible(true);
  }

  useEffect(() => {
    const getUserName = async () => {
      dispatch(getTeamApiInitiated());
      try {
        const name = await fetchUserName();
        if (name) {
          setUserName(name);
          callGetTeamMembersListApi();
        }
      } catch (error: any) {
        dispatch(getTeamApiFinished());
        if (error instanceof AxiosError) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 401) {
            clearCacheAndCookies();
            setAuthToken("");
            navigate("/");
          }
        }
      }
    };
    getUserName();
  }, []);

  const callGetTeamMembersListApi = async () => {
    dispatch(getTeamApiInitiated());
    try {
      const response: AxiosResponse<TeamResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/platform/team/team-members`,
        null,
        {
          User: getUserEmail(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          Authorization: getAuthToken()
        }
      );

      if (response.status === 200 && response.data.code === 200) {
        dispatch(getTeamApiSucceed(response.data.result["team-members"]))
      } else {
        dispatch(getTeamApiFailed(response.data.message))
        processSuccessResponse(
          response.data,
          (errorData) => {
            if (errorData.boldMessage === "Unauthorized") {
              clearCacheAndCookies();
              setAuthToken("");
              navigate("/");
            } else {
              setErrorPopupData(errorData);
              setIsErrorPopupVisible(true);
            }
          },
          (message) => {
            handleSuccessMessage(message);
            setIsSuccessPopupVisible(true);
          }
        );
      }
    } catch (error: any) {
      dispatch(getTeamApiFailed("Failed"));
      processErrorResponse(
        error,
        (errorData) => {
          if (errorData.boldMessage === "Unauthorized") {
            clearCacheAndCookies();
            setAuthToken("");
            navigate("/");
          } else {
            setErrorPopupData(errorData);
            setIsErrorPopupVisible(true);
          }
        }
      );
    }
  }

  const callCreateTeamMembersApi = async (teamMembers: any) => {
    setShowLoader(true);
    const requestBody = {
      "team-members": teamMembers.map((member: any) => ({
        "member-name": member.name,
        "member-email-id": member.email,
      })),
    };
    try {
      const response: AxiosResponse<TeamResponse> = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/platform/team/team-members`,
        requestBody,
        {
          User: getUserEmail(),
          "User-Name": userName,
          apikey: `${process.env.REACT_APP_API_KEY}`,
          Authorization: getAuthToken()
        }
      );

      if (response.status == 200) {
        processSuccessResponse(
          response.data,
          (errorData) => {
            if (errorData.boldMessage === "Unauthorized") {
              clearCacheAndCookies();
              setAuthToken("");
              navigate("/");
            } else {
              setErrorPopupData(errorData);
              setIsErrorPopupVisible(true);
            }
          },
          (message) => {
            handleSuccessMessage(message);
            setIsSuccessPopupVisible(true);
          }
        );
        setShowLoader(false);
        callGetTeamMembersListApi();
      }
    } catch (error: any) {
      setShowLoader(false);
      processErrorResponse(
        error,
        (errorData) => {
          if (errorData.boldMessage === "Unauthorized") {
            clearCacheAndCookies();
            setAuthToken("");
            navigate("/");
          } else {
            setErrorPopupData(errorData);
            setIsErrorPopupVisible(true);
          }
        }
      );
    }
  };

  const callEditMemberApi = async (memberId: number, memberName: string, memberRoleId: number) => {
    setShowLoader(true);
    try {
      const response: AxiosResponse<TeamResponse> = await initiateApiCall(
        "put",
        `${process.env.REACT_APP_BASE_URL}/platform/team/team-members`,
        {
          "team-members": [
            {
              "member-id": memberId,
              "member-name": memberName,
              "member-role-id": memberRoleId,
            },
          ],
        },
        {
          User: getUserEmail(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          Authorization: getAuthToken()
        }
      );

      if (response.status == 200) {
        processSuccessResponse(response.data,
          (errorData) => {
            setErrorPopupData(errorData);
            setIsErrorPopupVisible(true);
          },
          (message) => {
            handleSuccessMessage(message);
            setIsSuccessPopupVisible(true);
          }
        );
        setShowLoader(false);
      }
    } catch (error: any) {
      setShowLoader(false);
      processErrorResponse(error, (errorData) => {
        if (errorData.boldMessage === "Unauthorized") {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else {
          setErrorPopupData(errorData);
          setIsErrorPopupVisible(true);
        }
      });
    } finally {
      setEditData(null);
    }
  };

  const callDeleteMemberApi = async (teamMembers: TeamMemberData) => {
    setShowLoader(true);
    try {
      const response: AxiosResponse<TeamResponse> = await initiateApiCall(
        "delete",
        `${process.env.REACT_APP_BASE_URL}/platform/team/team-members`,
        {
          "team-member-id-list": [
            teamMembers["member-id"]
          ],
        },
        {
          User: getUserEmail(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          Authorization: getAuthToken()
        }
      );

      if (response.status == 200) {
        processSuccessResponse(response.data,
          (errorData) => {
            setErrorPopupData(errorData);
            setIsErrorPopupVisible(true);
          },
          (message) => {
            handleSuccessMessage(message);
            setIsSuccessPopupVisible(true);
          }
        );
        setShowLoader(false);
      }
    } catch (error: any) {
      setShowLoader(false);
      processErrorResponse(error, (errorData) => {
        if (errorData.boldMessage === "Unauthorized") {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else {
          setErrorPopupData(errorData);
          setIsErrorPopupVisible(true);
        }
      });
    } finally {
      setEditData(null);
    }
  };

  const togglePopup = (value: boolean) => {
    setEnablePopup(value);
    if (!value) {
      setEditData(null);
    }
  }

  return (
    <>
      < div className="myTeam-container">
        {enablePopup && <CreateTeamPopup setEnablePopup={togglePopup} onSaveTeam={handleSaveTeamMembers} initialValues={editData} onDeleteTeam={handleDeleteTeamMembers} />}
        {(showLoader || apiCallInitiated) && <ProcessingDialog message="Loading..." />}
        <div className="create-team-heading-container">
          <div>Create / Manage Team </div>
          <button
            className="create-team-button"
            onClick={() => setEnablePopup(true)}
          >
            <img
              src={add_icon}
              className="create-team-icons"
              onClick={() => setEnablePopup(false)}
            ></img>
            Add Members
          </button>
        </div>
        {Array.isArray(myTeamApiResponse) && myTeamApiResponse.length > 0 ? (
          <>
            <div className="my-team-count-text">{myTeamApiResponse.length} Members in the Team</div>
            <TeamTable list={myTeamApiResponse} onEdit={handleEdit} onDelete={handleDelete} />
          </>
        ) : (
          !apiCallInitiated && <EmptyMessageComponent message="No Team Members Added" />
        )}
        {isErrorPopupVisible && (
          <ErrorPopup
            boldMessage={errorPopupData.boldMessage}
            message={
              errorPopupData.message
            }
            onClose={handleCloseError}
          />
        )}
        {isSuccessPopupVisible && (
          <SuccessPopup
            message={successPopupData.message}
            boldMessage={""}
            onClose={handleCloseSuccessPopup} />
        )}
      </div>
    </>
  );
};

export default MyTeam;
