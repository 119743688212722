import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/cookiesPolicy">Cookies policy</Link>
        <Link to="/privacyPolicy">Privacy policy</Link>
        <Link to="/termsOfUse">Terms and Conditions of Use </Link>
      </div>
      <div className="footer-content">
        Copyright ©2024 Michelin. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
