import React from "react";
import { Link } from "react-router-dom";

export const HowToRefuse = () => {
  return (
    <>
      <ul>
        <li className="static-pages-bullets">Browser controls</li>
      </ul>
      <div className="static-pages-content">
        <div>
          Most browsers allow you to control cookies through their settings,
          which may be adapted to reflect your consent to the use of cookies.
          Further, most browsers also enable you to review and erase cookies. To
          learn more about browser controls, please consult the documentation
          that your browser manufacturer provides.
        </div>
        <div>
          To learn more on how to configure cookies, you can visit the following
          website:{" "}
          <span className="static-pages-keyword-with-link">
            <a href="https://www.youronlinechoices.com/">
              https://www.youronlinechoices.com/{" "}
            </a>
          </span>
        </div>
      </div>
      <ul>
        <li className="static-pages-bullets">
          Manage your preferences through a Cookies management solution
        </li>
      </ul>
      <div className="static-pages-content">
        <div>
          Michelin provide a Cookie preference solution to manage users &apos;
          consent on our websites or applications regarding cookies. This
          solution makes it possible to collect, store and transmit the consents
          of the users to Michelin and the concerned partners.
        </div>
        <div>
          During your first visit to our site, this dashboard allows you to
          indicate your choice. You can accept them, configure them, decline
          them or access our cookie policy by clicking on the buttons available
          in the Cookie management solution.
        </div>
        <div>
          You can indicate your preference either globally, or purpose by
          purpose, or even by categories of partners (vendors). You also have
          access, for all partners, to their privacy policy.
        </div>
        <div>
          You are free to accept or decline cookies but be aware that a refusal
          could make your browsing on our website less user-friendly and with
          less relevant content.
        </div>
        <div>
          The consent given is valid for a period not exceeding the expiration
          date of the cookie regarding its purpose and no longer than necessary
          in accordance with the legislation. If you refuse any cookies,
          Michelin also remembers your choice and no longer presents the Cookie
          management solution to you for a defined period. At the end of this
          retention period, or if you delete the cookie related to the storage
          of your choice, the cookies management solution will be proposed to
          you again.
        </div>
        <div>
          Whatever your initial choice, from time to time, you can always
          withdraw your consent or adjust your settings by using the Cookies
          management solution.
        </div>
      </div>
    </>
  );
};

export const TypesOfCookies = () => {
  return (
    <>
      <div className="static-pages-content">
        <div>A cookie can be classified in different ways.</div>
        <div>By lifespan, a cookie is either a:</div>
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          <span className="static-pages-bullets-keyword">Session cookie</span>{" "}
          which is erased when the user closes the browser.
        </li>
        <li className="static-pages-points-with-content">
          <span className="static-pages-bullets-keyword">
            Persistent cookie
          </span>{" "}
          which remains on the user&apos;s computer/device for a pre-defined
          period of time according to the span that is attributed and the
          parameters of your internet browser. Retention periods vary from
          cookie to cookie. We determine the duration of cookies based on what
          is proportionate in relation to the intended outcome and then limit
          this to what is necessary to achieve the purpose.
        </li>
      </ul>
      <div className="static-pages-content">
        As for the domain to which it belongs, they are either:
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          <span className="static-pages-bullets-keyword">
            First-party cookies
          </span>{" "}
          which are set by the web server of the visited page and share the same
          domain, in this case Michelin or its processors.
        </li>
        <li className="static-pages-points-with-content">
          <span className="static-pages-bullets-keyword">
            Third-party cookies
          </span>{" "}
          stored by a different domain to the visited page&apos;s domain. they
          are placed by a third party, partners that Michelin has authorised
          (for example an advertising agency). When they use cookies, these
          third-party partners are responsible for the cookies they use. We
          invite you to consult their Privacy policies available through our
          cookies management solution. You can also use their own cookies
          management tools to set your preferences.
        </li>
      </ul>
      <div className="static-pages-content">
        <div>Besides these characteristics relating to their functioning:</div>
        <div>
          Cookies can pursue several purposes. Please note that the titles of
          categories may vary from one actor to another. To a better
          understanding, Michelin define them regarding the categories below.
        </div>
      </div>
      <ul>
        <li className="static-pages-bullets">Strictly Necessary Cookies</li>
      </ul>
      <div className="static-pages-content">
        <div>
          Strictly necessary cookies are cookies that are indispensable due to
          the fact that the functionality of the website is contingent on their
          existence. Their deactivation will cause important difficulties for
          using the site, or even the impossibility to use the services that it
          proposes. Many purposes are covered by these cookies. They permit, for
          example, to identify the terminals to dispatch the communication, to
          assign numbers to the data “packets” in order to dispatch them in the
          requested order, and to detect transmission errors or data loss, keep
          in memory the content of a basket during the session, customise the
          user interface (language, presentation of a service) or even
          authentication with a service.
        </div>
        <div>
          These cookies also record the choice expressed by users regarding
          their cookies preferences so as not to request them again for a
          defined duration.
        </div>
        <div>
          These cookies are based on Michelin&apos;s legitimate interest.
          Because they are strictly necessary for your navigation, your prior
          consent is not required. These cookies are NOT managed by our Cookie
          management solution.
        </div>
        <div className="">
          <table className="static-pages-table">
            <tbody>
              <tr className="static-pages-table-first-row">
                <td className="static-pages-keyword-bold">Nom du cookie</td>
                <td className="static-pages-keyword-bold">Mise en place par</td>
                <td className="static-pages-keyword-bold">Objectif</td>
                <td className="static-pages-keyword-bold">Durée</td>
              </tr>
              <tr>
                <td className="static-pages-keyword-bold">Session cookie</td>
                <td>Michelin</td>
                <td>Maintain user session</td>
                <td>1 day</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ul>
        <li className="static-pages-bullets">Functional cookies</li>
      </ul>
      <div className="static-pages-content">
        <div>
          Functional cookies are used to improve your browsing experience. They
          remember the choices you make (your username, save your language
          preference for example) and provide personalised services. Thanks to
          these cookies, you do not have to enter certain information each time
          you connect to our website or application. They can remember changes
          you have made to text size, font, or other parts of web pages that can
          be customised.
        </div>
        <div>
          Functional cookies will not be used to send you advertisements on our
          site or on other websites.
        </div>
        <div>
          You are free to accept or refuse them, but by blocking them, it is
          also possible that your previous service selections will not be kept
          in memory. These cookies are used on the basis of your prior consent.
        </div>
      </div>
      <ul>
        <li className="static-pages-bullets">
          Analytics and audience measurement cookies
        </li>
      </ul>
      <div className="static-pages-content">
        <div>
          The audience measurement cookies help to recognize visitors of the
          website across their different visits. These cookies only stock an
          internet user&apos;s ID (specific to each cookie) and is in no case
          used to collect nominative information belonging to the visitors. The
          data collected makes it possible to provide anonymous statistical data
          on visits (the number of visitors and pages visited, their frequency
          of return the duration of each visit, and the error messages
          encountered…). They help to enables a performance increase of the
          Michelin internet websites.
        </div>
        <div>
          The analytics cookies can be installed and managed by partners.
        </div>
        <div>
          You are free to accept or refuse them, but by blocking them, Michelin
          will have less information to improve the performance of its site.
        </div>
        <div>These cookies are used on the basis of your prior consent.</div>
      </div>
    </>
  );
};

export const IntellectualProperty = () => {
  return (
    <div>
      <div className="static-pages-content">
        <div>
          We and our licensors own, and shall retain, all rights, title and
          interest in the patents, copyrights, moral rights, trademarks or
          service marks, logos and designs, trade secrets, and other
          intellectual property embodied by, or contained in the Michelin Mobile
          Platform, Content, Michelin Trademarks and Documentation, any
          derivatives of the foregoing (collectively,{" "}
          <span className="static-pages-keyword-bold">“Michelin IP” </span>
          ). Michelin IP is protected by copyright, trade secret, patent, and
          other intellectual property Laws, and all rights in Michelin IP not
          expressly granted to you in under these Terms are reserved.
        </div>
        <div>
          No title to or ownership of any Michelin IP is granted or otherwise
          transferred to you under these Terms. No rights on the Michelin Mobile
          Platform is granted by us to you other than those expressly stipulated
          in these Terms. Any reproduction and/or representation of the Contents
          in any form or by any means whatsoever by you without our prior
          authorisation is strictly prohibited.
        </div>
        <div>
          Nothing in these Term, grants you any rights to use any Michelin
          Trademarks.
        </div>
        <div>
          Subject to your compliance with these Terms and the payment by you of
          the due amounts if the Michelin Mobile Platform is not provided for
          free, we grant you a non-exclusive and non-transferable right to use
          the Michelin Mobile Platform in the Territory. You are not authorised
          to transfer or grant a right to use the Michelin Mobile Platform to a
          third party, free of charge or for payment, in any way whatsoever.
        </div>

        <div>You shall not:</div>
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Alter or remove any copyright, trademark, trade name or other
          proprietary notices, legends, symbols or labels appearing on or in the
          Michelin Mobile Platform or any reproduction thereof;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Sublicense (or purport to sublicense), distribute or disclose any of
          the Michelin Mobile Platform, in whole or in part, to any third party;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Distribute or otherwise provide all or any portion of the Michelin
          Mobile Platform outside of the Territory or otherwise use or export
          any Michelin Mobile Platform in violation of applicable laws or
          regulations;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Engage in any activity, including the development or distribution of
          any software (whether in the form of object code or source code), that
          interferes with, disrupts, damages, or accesses in an unauthorized
          manner any platforms, servers, or systems, owned by us or any third
          party;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Make any statements that your Application is approved or otherwise
          endorsed, or that its performance is guaranteed, by us;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Decompile, reverse engineer, disassemble, rent, lease, loan,
          distribute, or, create derivative works from the Michelin Mobile
          Platform or any portion thereof, except within the limits permitted by
          law and in compliance with Michelin IP;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Use the Content, or extract, scrape or otherwise deconstruct any of
          the Content, for using individual data elements, combining data
          elements, compiling, enhancing, verifying, supplementing, or otherwise
          modifying databases, lists, or directories of any kind;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Attempt to circumvent any security measures or technical limitations;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          To perform reverse engineering operations or to decompile or
          disassemble the Michelin Mobile Platform
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Use the Michelin Mobile Platform in any manner or for any purpose that
          violates any law or any right of any person, including but not limited
          to any Intellectual Property Rights or rights of privacy;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Not to use any of the Michelin Mobile Platform in conjunction with
          Mobile Platform of competitors, unless expressly agreed;
        </li>
        <li className="static-pages-points-with-content">
          Otherwise use or exploit the Michelin Mobile Platform for any purpose
          other than as expressly permitted by these Terms.
        </li>
      </ul>
      <div className="static-pages-content">
        <div>
          You undertake to display and not to damage, modify and/or remove the
          copyright notices and statements of Michelin and any of their
          suppliers whose names appear in the Contents.
        </div>
        <div>
          You undertake to take all necessary measures to ensure that end-users
          comply with the restrictions on use of the Mobile Platform.
        </div>
        <div>
          You also undertake not to use Michelin Mobile Platform in a manner
          which could damage their performance, accessibility and availability,
          to receive or transmit illegal, defamatory or counterfeit information
          and content.
        </div>
        <div>
          In the event you send us data, we undertake not to use it or its
          content for purposes other than provision of the Michelin Mobile
          Platform and Contents. We will put technical and organisational
          measures in place that comply with industry standards to protect such
          data against accidental or illegal destruction, accidental loss,
          modification, disclosure or unauthorised access.
        </div>
        <div>
          You may not: (i) claim or register ownership of Michelin IP on your
          behalf or on behalf of others; (ii) sublicense any rights in Michelin
          IP granted by us; (iii) import or export any Michelin IP to a person
          or country in violation of any country&apos;s export control laws;
          (iv) use Michelin IP in a manner that violates these Terms or laws; or
          (v) attempt to do any of the foregoing.
        </div>
        <div>
          <span className="static-pages-keyword-bold">
            Open Source Software.
          </span>{" "}
          The Michelin Mobile Platform or Content may contain software that is
          subject to terms that, as a condition of use, copying, modification or
          redistribution, require such software and derivative works thereof to
          be disclosed or distributed in source code form, to be licensed for
          the purpose of making derivative works, or to be redistributed free of
          charge, including without limitation software distributed under the
          GNU General Public License or GNU Lesser/Library GPL (“Open Source
          Software”). In such a case, it will be expressly set out in the
          Documentation. To the extent any such license requires terms with
          respect to such Open Source Software that are inconsistent with these
          Terms, then such rights in the applicable Open Source Software license
          shall take precedence over the rights granted under the Terms, but
          solely with respect to such Open Source Software. You agree not to use
          any Open Source Software in the development of your Application in
          such a way that would cause any portions of the Michelin Mobile
          Platform or Contents to be subject to any Open Source Software
          licensing terms or obligations.
        </div>
        <div>
          <span className="static-pages-keyword-bold">Feedback.</span> If you
          transmit to us any unsolicited submission or any feedback, in writing,
          orally, or otherwise, you grant us a royalty-free, fully paid,
          transferable, sublicensable, non-exclusive, irrevocable, perpetual,
          unrestricted, worldwide license to publish, transmit, perform,
          display, modify, create derivative works from and otherwise use or
          exploit such feedback and any related intellectual property rights for
          any purpose, in any media, now or hereafter known, without any credit,
          notice, approval or compensation to you. Without limitation thereof,
          you agree that we are free to use any ideas, concepts, know-how or
          techniques contained in any feedback you provide for any purpose
          whatsoever, including, without limitation, developing and marketing
          products, services and content. Furthermore, any feedback you transmit
          to us, even if marked as confidential, shall not create any
          confidentiality obligations on us unless otherwise agreed in a
          separate, signed agreement.
        </div>
      </div>
    </div>
  );
};

export const Definitions = () => {
  return (
    <>
      <div className="static-pages-content">
        <div>
          <span className="static-pages-keyword-bold">“Affiliate”</span> means,
          for purposes of these Terms, with respect to Michelin, mean and
          include all affiliates and subsidiaries of la Manufacture Française
          des Pneumatiques Michelin (MFPM) and any affiliate or subsidiary of a
          company that controls directly or indirectly MFPM.
        </div>
        <div>
          <span className="static-pages-keyword-bold">
            “Mobile Platform” is a one stop digital solution to -
          </span>
        </div>
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Manage all Michelin Mobile apps,
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Do impact analysis,
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Envision customers digital journey,
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Manage app personas
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Manage obsolescence
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Explore new services,
        </li>
        <li className="static-pages-points-with-content">
          Access shared resources.
        </li>
      </ul>
      <div className="static-pages-content">
        <div>
          <span className="static-pages-keyword-bold">“Application”</span> means
          software programs that you develop to interoperate with the Michelin{" "}
          <span className="static-pages-keyword-bold">Mobile Platform</span>{" "}
          using the Michelin{" "}
          <span className="static-pages-keyword-bold">Mobile Platform</span> in
          accordance with the applicable Documentation.
        </div>
        <div>
          <span className="static-pages-keyword-bold">
            “Confidential Information”
          </span>{" "}
          means any non-public data or information, oral or written, that
          relates to us, including, without limitation, Michelin IP, and other
          technical, business, product, marketing and financial information,
          pricing, plans, data.
        </div>
        <div>
          <span className="static-pages-keyword-bold">“Documentation”</span>{" "}
          means collectively, the operating instructions, user manuals, and
          other documentation, in written or electronic form, made available to
          you on the Portal to be used in connection with the concerned Michelin
          <span className="static-pages-keyword-bold"> Mobile Platform</span>.
        </div>
        <div>
          <span className="static-pages-keyword-bold">“Forum”</span> means the
          means provided on the Portal to allow you to post messages, upload
          content and/or otherwise communicate with other users and us.
        </div>
        <div>
          <span className="static-pages-keyword-bold">
            “Intellectual Property Rights”
          </span>{" "}
          or <span className="static-pages-keyword-bold">“IP”</span> means
          patents, copyrights, trade secrets, design rights, data rights, mask
          work rights, moral rights, Trademarks and any other intellectual
          property rights anywhere in the world, and registrations and
          applications for any of the foregoing.
        </div>
        <div>
          <span className="static-pages-keyword-bold">“Personal Data”</span>{" "}
          means information that identifies or can identify an individual
          directly or indirectly or as otherwise defined by applicable law.
        </div>
        <div>
          <span className="static-pages-keyword-bold">“Territory”</span> means
          any territory in which we provide access to the respective Michelin{" "}
          <span className="static-pages-keyword-bold">Mobile Platform</span>.
        </div>
        <div>
          <span className="static-pages-keyword-bold">“Trademark(s)”</span>{" "}
          means all trademarks, service marks, logos, trade dress, trade names,
          and service names, all registrations and applications for any of the
          foregoing, all goodwill associated with any of the foregoing, and all
          similar or related rights anywhere in the world.
        </div>
      </div>
    </>
  );
};

export const InfoAboutYou = () => {
  return (
    <div>
      <div className="static-pages-content">
        <div>
          To enable us to provide you with our services, we may receive personal
          information about you from other sources, including:
        </div>
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          We may receive information about you if you use any of the other
          websites we operate or the other products and/or services we provide.
          We also work closely with third parties (including, for example,
          business partners, sub-contractors in technical, payment services,
          analytics providers, search information providers and credit reference
          agencies) and may receive information about you from them. The legal
          basis for his processing is our legitimate interests, namely
          monitoring and improving our website, products and services.
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Information from our web servers&apos; logs, for example information
          about your computer, such as your IP address, operating system and
          browser type. We may also collect information on the searches you
          carry out on our website and your interaction with communications we
          send you, for example, when you open email communications. (“
          <span className="static-pages-keyword-bold">
            Usage and Device Data
          </span>
          ”). This information is used by us for system administration and to
          analyse how people are using our websites and digital platforms. It
          also helps us to provide the best online infrastructure for your
          online activities. The legal basis for this processing is our
          legitimate interests, namely monitoring and improving our website,
          products and services.
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          As mentioned above, we collect information using cookies or other
          similar technologies stored on your device about how you use our
          website, digital products and services. Cookies contain information
          that is transferred to your computer&apos;s hard drive. They help us
          to improve our websites and deliver a better and more personalised
          service to you. You can read our Cookie Policy{" "}
          <span className="static-pages-keyword-with-link">
            <Link to="/cookiesPolicy">here</Link>
          </span>
          .
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Our website may enable you to share your experience and connect with
          us through third party social networks such as LinkedIn. We may
          collect this information from our social media sites, including likes,
          shares and tweets, and about the content on social networking sites.
          We do not control the privacy of the information you provide on social
          media networks and the privacy policies of those social media networks
          will apply when using their services.
        </li>
      </ul>
    </div>
  );
};

export const SharingInfo = () => {
  return (
    <div>
      <div className="static-pages-content">
        <div>
          We only share your personal data with people who have a need to know
          such information to perform their responsibilities. We may share your
          personal information with the following:
        </div>
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          <span className="static-pages-keyword-bold">
            Within the Michelin Group
          </span>{" "}
          - we may disclose your personal information to any member of our
          group, which means our subsidiaries, our ultimate holding company and
          its subsidiaries to the extent necessary for the same purposes of the
          initial processing set out in this policy.
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          <span className="static-pages-keyword-bold">Service Providers</span> -
          Michelin may share your personal information with selected service
          providers that Michelin may use to process your request or provide its
          services to you. These service providers act as data processors on our
          behalf and provide IT and system administration services, such as
          hosting and email services and managing the ratings and reviews. These
          service providers are legally obligated to keep the personal
          information they may have received confidential and secure and to use
          your personal information on the basis of our instructions only. The
          main subcontractor is the company Microsoft for the hosting of our
          website.
        </li>
      </ul>
    </div>
  );
};

export const PrivacyInfoWeCollect = () => {
  return (
    <>
      <div className="static-pages-content">
        <div>This section sets out:</div>
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          how we collect your personal information;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          the categories of data that we collect;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          a description of how we plan to use the information we collect;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          the legal basis that we will rely on to process the information
          collected;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          how long we will hold the information collected; and
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          specific types of data
        </li>
      </ul>
      <div className="static-pages-content">
        <div>We may collect and hold the following information about you:</div>
        <div className="">
          <table className="static-pages-table static-pages-privacy-table">
            <tbody>
              <tr>
                <td className="static-pages-keyword-bold">Data collected</td>
                <td className="static-pages-keyword-bold">Purpose/Activity</td>
                <td className="static-pages-keyword-bold">Type of Data</td>
                <td className="static-pages-keyword-bold">
                  Lawful basis for processing
                </td>
                <td className="static-pages-keyword-bold">Retention period</td>
              </tr>
              <tr>
                <td>
                  If you register for any of our digital products or services
                  via an account, we will request information to enable us to
                  identify you and provide the products and services to you (“
                  <span className="static-pages-keyword-bold">
                    Account data
                  </span>
                  ”).
                </td>
                <td>
                  Account Data will be processed for the purpose of operating
                  our websites, providing digital services you sign up for,
                  communicating with you about those digital products and
                  services and maintaining back-ups of our services.
                </td>
                <td>
                  Account Data will include your full name, email address.
                </td>
                <td>
                  The performance of the agreement between you and us for the
                  provision of the digital product and services is the legal
                  basis for processing Account Data.
                </td>
                <td>
                  We will retain ACCOUNT DATA for as long as you have logged In
                  with us for one day.
                </td>
              </tr>
              <tr>
                <td>
                  Information you provide to us when you complete a survey or
                  respond to a questionnaire or submit a review, your
                  preferences or feedback (“
                  <span className="static-pages-keyword-bold">Review Data</span>
                  ”).
                </td>
                <td>
                  To collect information about a specific matter, for example,
                  our services to you or reviews of our products/services.In
                  many cases surveys and questionnaires are anonymous and we
                  only collect personal information for statistical data. If
                  this is not the case, we will let you know at the time of
                  collecting the information. If such functionality is
                  available, feedback and reviews may be disclosed on the
                  Website with some personal information. In such a case, you
                  will be informed.
                </td>
                <td>
                  Information may include your full name, your email address and
                  other personal data requested.
                </td>
                <td>
                  As the legal basis for processing Review Data, we will use our
                  legitimate interests in the monitoring and improvement of our
                  products and services to our customers and users.
                </td>
                <td>
                  While we like to retain reviews and feedback from you to help
                  other web users, we will anonymous or delete your reviews and
                  feedback after 10 years. All information retained will be
                  statistical data only.<div>&nbsp;</div>Any information
                  collected pursuant to a survey or questionnaire will be
                  deleted within 3 years of you submitting your responses. Any
                  information retained will be statistical data only.
                </td>
              </tr>
              <tr className="table-privacy-row">
                <td>
                  Information you provide us when you communicate with us, e.g.
                  by email or contact us form via our website (“
                  <span className="static-pages-keyword-bold">
                    Communication Data
                  </span>
                  ”)
                </td>
                <td>
                  This information is used to enable us to respond to or follow
                  up on your comments, queries or questions
                </td>
                <td>
                  Information may include your full name, your email address,
                  and other personal data you provide us to enable us to deal
                  with your communication.
                </td>
                <td>
                  We will use our legitimate interests in assisting you and
                  answering your communications as the legal basis for
                  processing Communication Data.
                </td>
                <td>
                  We will hold on to Communication Data for as long as it is
                  necessary to enable us to deal with the matter relating to
                  that communication. Once your matter has been dealt with, we
                  will delete Communication Data as per Michelin data retention
                  policy.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="static-pages-content">
        <div className="static-pages-privacy-point-margin static-pages-keyword-bold">
          Voluntary Information
        </div>
        <div>
          When you provide us with information (for example, the information set
          out in the scenarios above), in some cases we may ask you for
          additional, voluntary information. This information will be identified
          as optional and is used to help us better understand our customers and
          tailor our services to them.
        </div>
        <div className="static-pages-keyword-bold">Statistical Information</div>
        <div>
          We also collect and use aggregated data such as statistical or
          demographic data (“Aggregated Data”). Aggregated Data may be derived
          from your personal information but does not reveal your identity in
          any way. For example, we may aggregate your Usage and Device Data to
          calculate the percentage of our website users accessing a specific
          feature of our website. Aggregated Data is used for our own business
          purposes only.
        </div>
        <div className="static-pages-keyword-bold">
          Sensitive Personal Information
        </div>
        <div>
          While we do not request sensitive personal data, we do have sections
          on our websites where you can submit additional information, for
          example, when you submit a review. If you submit information on these
          free text section, only submit information you are comfortable
          providing and always bear in mind that it may be sensitive
          information. If you submit reviews or feedback for our website, please
          remember that this information will be visible to other users of our
          website.
        </div>
      </div>
    </>
  );
};
