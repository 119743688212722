import React, { useEffect, useState } from "react";
import {
  backIcon,
  filterIcon,
  dropDownIcon,
  dropDownClose,
} from "../../../assets";
import { useNavigate } from "react-router-dom";
import {
  AppCardComponent,
  ErrorPopup,
  FiltersTabComponent,
  ProcessingDialog,
  EmptyMessageComponent,
} from "../../../components";
import "./AppAnalyticsByStatusScreen.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";

import { AxiosError, AxiosResponse } from "axios";
import { initiateApiCall } from "../../../api";
import {
  postAnalyticsByStatusApiFailed,
  postAnalyticsByStatusApiInitiated,
  postAnalyticsByStatusApiSucceed,
} from "../../../stateManagement/actions/analyticsByStatusActions";
import { clearCacheAndCookies, getAuthToken } from "../../../utils/Utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../routes/useAuth";

interface ApiResponse {
  code: number;
  message: string;
  result: ResultData;
  error: string;
}

interface ResultData {
  "app-results": [
    {
      "package-name": string;
      "platform-name": string;
      "app-name": string;
      "business-type": string;
      "app-description": string;
      "store-app-description": string;
      "app-logo": string;
      "store-app-name": string;
      "account-provider": string;
      "store-app-logo": string;
      "last-published-date": Date;
      "no-of-downloads": number;
      "app-status": string;
      "app-version-state": string;
      "avg-rating": number;
      "no-of-reviews": number;
      "platform-slug": string;
      "app-status-slug": string;
      "account-provider-slug": string;
      "business-type-slug": string;
    }
  ];
  "total-count": number;
  filters: [
    {
      slug: string;
      seq: number;
      title: string;
      "show-count": boolean;
      "filter-options": [
        {
          title: string;
          count: number;
          parent_slug: string;
          slug: string;
          "is-selected": boolean;
        }
      ];
    }
  ];
}

const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
const getMonths = (slug: string) => {
  const months = slug.split("-");
  if (months.length > 1 && months[1].includes("months")) {
    return parseInt(months[0]);
  } else if (months.length > 1 && months[1].includes("year")) {
    return parseInt(months[0]) * 12;
  }
  return 0;
};
const applyFilters = (
  fitlersApplied: any,
  appList: any,
  selectedStartDate: any,
  selectedEndDate: any
) => {
  let filteredAppList = appList;
  Object.entries(fitlersApplied).forEach(([filter, option]: any) => {
    if (filter === "date") {
      const slug = option[0];
      if (slug === "none") {
        return;
      } else {
        const months = slug === "custom-date-range" ? 0 : getMonths(slug);
        const endDate =
          slug === "custom-date-range" ? selectedEndDate : new Date();
        const startDate =
          slug === "custom-date-range" ? selectedStartDate : new Date();
        if (slug !== "custom-date-range") {
          startDate.setMonth(endDate.getMonth() - months);
        }

        filteredAppList = filteredAppList.filter((app: any) => {
          if (app["last-published-date"]) {
            return (
              new Date(app["last-published-date"]) >= startDate &&
              new Date(app["last-published-date"]) <= endDate
            );
          }
          return false;
        });
      }
    } else {
      filteredAppList = filteredAppList.filter((app: any) => {
        if (filter === "business-type") {
          const bls = (app[`${filter}-slug`] || "").split(",");
          return bls.some((bl: any) => option.includes(bl));
        }
        return option.includes(app[`${filter}-slug`]);
      });
    }
  });

  return filteredAppList;
};

const getAppliedFilters = (filters: any) => {
  const filtersApplied: any = {};
  filters.forEach((filter: any) => {
    const options: any = [];
    filter["filter-options"].forEach((value: any) => {
      if (value["is-selected"]) {
        options.push(value.slug);
      }
      return value;
    });
    if (options.length) {
      // add to array only if any option is selected
      filtersApplied[filter.slug] = [
        ...(filtersApplied[filter.slug] || []),
        ...options,
      ];
    }
  });
  return filtersApplied;
};

const AppAnalyticsByStatusScreen = () => {
  const { setAuthToken }: any = useAuth();
  const { apiCallInitiated, apiResponse, apiCallFailed } = useSelector(
    (state: any) => state.analyticsByStatusReducers,
    shallowEqual
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<any>(null);
  const [appList, setAppList] = useState<any>(null);
  const [dropDownSelected, setDropDownSelected] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [dateClicked, setDateClicked] = useState("start");
  const [dropDownSelectionValue, setDropDownSelectionValue] =
    useState("- Select Period -");
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });

  useEffect(() => {
    if (Object.keys(apiResponse).length > 0) {
      let newFilters = apiResponse?.result?.filters;
      newFilters = newFilters?.map((filter: any) => {
        filter["filter-options"] = filter["filter-options"].map(
          (option: any) => {
            option["showFilter"] = true;
            return option;
          }
        );
        return filter;
      });
      setFilters(newFilters);
      const filteredAppList = applyFilters(
        getAppliedFilters(apiResponse?.result?.filters || []),
        apiResponse?.result?.["app-results"],
        selectedStartDate,
        selectedEndDate
      );
      setAppList(filteredAppList);
    }
  }, [apiResponse?.result?.filters, apiResponse?.result?.["app-results"]]);

  useEffect(() => {
    if (Object.keys(apiResponse).length > 0) {
      if (!selectedEndDate || selectedStartDate > selectedEndDate) {
        const newEndDate = new Date();
        newEndDate.setDate(selectedStartDate.getDate() + 1);
        setSelectedEndDate(newEndDate);
      }

      const filteredAppList = applyFilters(
        getAppliedFilters(apiResponse?.result?.filters || []),
        apiResponse?.result?.["app-results"],
        selectedStartDate,
        selectedEndDate
      );

      setAppList(filteredAppList);
    }
  }, [selectedStartDate, selectedEndDate]);

  const setFilterSelection = (
    selected: boolean,
    parentSlug: string,
    label: string
  ) => {
    const newFilters = [...filters];

    newFilters.forEach((filter: any) => {
      if (filter.slug === parentSlug) {
        filter["filter-options"].forEach((value: any) => {
          if (value.slug === label) {
            value["is-selected"] = selected;
          }
        });
      }
    });

    const selectedPlatforms = newFilters[0]["filter-options"]
      .filter((filter: any) => filter["is-selected"])
      .map((filter: any) => filter.slug);

    if (parentSlug === "platform") {
      newFilters.forEach((filter: any) => {
        if (
          filter.slug === "account-provider" ||
          filter.slug === "app-status"
        ) {
          filter["filter-options"].forEach((value: any) => {
            value["showFilter"] = selectedPlatforms.length
              ? selectedPlatforms.includes(value.parent_slug)
              : true;
            if (!value["showFilter"]) {
              value["is-selected"] = false;
            }
          });
        }
      });
    }

    setFilters(newFilters);

    const filtersApplied = getAppliedFilters(newFilters);
    let filteredAppList = apiResponse?.result?.["app-results"];
    filteredAppList = applyFilters(
      filtersApplied,
      filteredAppList,
      selectedStartDate,
      selectedEndDate
    );

    setAppList(filteredAppList);
  };

  const filterBasedOnDateRange = (
    selectionState: boolean,
    parentSlug: string,
    slug: string,
    rangeSelected: string
  ) => {
    const newFilters = [...filters];
    newFilters.map((filter: any) => {
      if (filter.slug === parentSlug) {
        filter["filter-options"].map((value: any) => {
          value["is-selected"] = false;
          return value;
        });
      }
      return filter;
    });

    setFilters(newFilters);
    setFilterSelection(!selectionState, parentSlug, slug);

    setDropDownSelectionValue(rangeSelected);
  };
  const resetAllFilters = () => {
    const newFilters = [...filters];
    newFilters.map((filter: any) => {
      filter["filter-options"].map((value: any) => {
        value["is-selected"] = false;
        value["showFilter"] = true;
        return value;
      });
      return filter;
    });
    setFilters(newFilters);
    setDropDownSelectionValue("- Select Period -");
    setAppList(apiResponse?.result?.["app-results"]);
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const handleSelectedStartDate = (date: any) => {
    const startDate = new Date(date);
    setSelectedStartDate(startDate);
  };

  const handleSelecteEndDate = (date: any) => {
    const endDate = new Date(date);
    setSelectedEndDate(endDate);
  };

  const handleError = (message: string, error: string) => {
    setErrorPopupData({
      boldMessage: message,
      message: error,
    });
    setIsErrorPopupVisible(true);
  };

  const handleCloseAPIError = () => {
    setIsErrorPopupVisible(false);
  };

  const fetchAppAnalyticsData = async () => {
    try {
      dispatch(postAnalyticsByStatusApiInitiated());
      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/app/analytics`,
        {
          filters: [],
        },
        {
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );
      if (response.data.code === 200) {
        dispatch(postAnalyticsByStatusApiSucceed(response.data));
      } else if (response.data.code === 401) {
        dispatch(
          postAnalyticsByStatusApiFailed({ errorMessage: "Unauthorized" })
        );
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        dispatch(
          postAnalyticsByStatusApiFailed({
            errorMessage: "Something went wrong !",
          })
        );
        handleError("Something went wrong !", "");
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        }
        dispatch(
          postAnalyticsByStatusApiFailed(axiosError.response?.statusText)
        );
        handleError("Error fetching apps!", apiCallFailed);
      }
    }
  };

  useEffect(() => {
    fetchAppAnalyticsData();
  }, []);

  const startDate = formatDateToYYYYMMDD(selectedStartDate);
  const endDate = formatDateToYYYYMMDD(selectedEndDate || new Date());
  const minDateForEnd = new Date(selectedStartDate.getDate() + 1);
  const maxDateForEnd = new Date();
  const isCustomDateRangeSelected = (filters?.find(
    (filter: any) => filter["slug"] === "date"
  ) || {})?.["filter-options"]?.find(
    (option: any) =>
      option["is-selected"] && option["slug"] === "custom-date-range"
  );

  if (isErrorPopupVisible) {
    return (
      <ErrorPopup
        boldMessage={errorPopupData.boldMessage}
        message={errorPopupData.message}
        onClose={handleCloseAPIError}
      />
    );
  }

  return (
    <div className="main-container">
      {apiCallInitiated || appList === null || filters === null ? (
        <ProcessingDialog message="Processing..." />
      ) : (
        <>
          <div className="heading analytics-by-status-heading">
            <img
              src={backIcon}
              alt="Back Icon"
              className="back-icon"
              onClick={() => navigate(-1)}
            />
            ANALYTICS BY STATUS
          </div>
          <div className="display-row analytics-by-status-container">
            <div className="analytics-by-status-filter-container">
              <div className="filter-heading">
                <div className="filter-icon-container">
                  <img
                    src={filterIcon}
                    alt="Filter Icon"
                    className="filter-icon"
                  />
                  <div>Filters</div>
                </div>
                <u className="reset-all-text" onClick={resetAllFilters}>
                  Reset All
                </u>
              </div>
              {filters?.map((filter: any, index: any) => {
                if (index !== filters.length - 1) {
                  return (
                    <FiltersTabComponent
                      key={index}
                      filter={filter}
                      setFilterSelection={setFilterSelection}
                    />
                  );
                }
              })}
              <div className="select-date-container">
                <div className="horizontal-line"></div>
                <div className="select-date-heading">
                  Filter By Last Published
                </div>
                <div className="select-date-dropdown">
                  <div className="select-date-dropdown-text">
                    <div>{dropDownSelectionValue}</div>
                    <img
                      src={dropDownSelected ? dropDownClose : dropDownIcon}
                      alt="dropdown"
                      className="icon-size"
                      onClick={() => setDropDownSelected(!dropDownSelected)}
                    />
                  </div>
                  {dropDownSelected && (
                    <>
                      <div className="horizontal-line"></div>
                      {filters[filters.length - 1]?.["filter-options"].map(
                        (rangeValue: any, index: any) => (
                          <div
                            key={index}
                            className="select-date-period"
                            onClick={() => {
                              filterBasedOnDateRange(
                                rangeValue["is-selected"],
                                filters[filters.length - 1]["slug"],
                                rangeValue.slug,
                                rangeValue.title
                              );
                              setDropDownSelected(!dropDownSelected);
                            }}
                          >
                            {rangeValue.title}
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
                {isCustomDateRangeSelected && (
                  <div className="date-selection-container">
                    <div className="date-range-controls">
                      <div
                        className="date-range-control"
                        onClick={() => setDateClicked("start")}
                      >
                        <label>From </label>
                        <input
                          className="calendar-input"
                          type="date"
                          value={startDate}
                          onChange={() => null}
                        />
                      </div>
                      <div
                        className="date-range-control"
                        onClick={() => setDateClicked("end")}
                      >
                        <label>To </label>
                        <input
                          className="calendar-input"
                          type="date"
                          value={endDate}
                          onChange={() => null}
                        />
                      </div>
                    </div>

                    {dateClicked ? (
                      <>
                        <div className="calendar-label">
                          {dateClicked === "start"
                            ? 'Select "From" Date'
                            : 'Select "To" Date'}
                        </div>
                        <Calendar
                          minDate={
                            dateClicked === "end" ? minDateForEnd : undefined
                          }
                          maxDate={
                            dateClicked === "end" ? maxDateForEnd : new Date()
                          }
                          date={
                            dateClicked === "start"
                              ? selectedStartDate
                              : selectedEndDate
                              ? selectedEndDate
                              : new Date()
                          }
                          onChange={
                            dateClicked === "start"
                              ? handleSelectedStartDate
                              : handleSelecteEndDate
                          }
                        />
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                appList?.length
                  ? "analytics-by-status-app-container"
                  : "analytics-by-status-empty-message"
              }
            >
              {appList?.length ? (
                <>
                  <div className="total-app-count">
                    {appList ? "Total Apps: " + appList.length : ""}
                  </div>
                  <div className="app-list">
                    {appList?.map((data: any, index: any) => {
                      return (
                        <AppCardComponent
                          key={`${data["package-name"]}-${index}`}
                          appData={data}
                          index={index}
                          sourceScreen={"analytics"}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <EmptyMessageComponent message="No Apps found !" />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AppAnalyticsByStatusScreen;
