import React, { useEffect } from "react";
import "./StaticPages.scss";
import "./Footer.scss";
import { useLocation } from "react-router-dom";
import {
  HowWeInform,
  SummaryParagraph,
  WhatIsACookie,
  WhyUsingCookies,
} from "./StaticPagesParagraphContent";
import {
  AboutOurPrivacy,
  OtherPolicies,
  WhoWeAre,
} from "./StaticPagesParagraphLinkContent";
import {
  HowToRefuse,
  TypesOfCookies,
} from "./StaticPagesParagraphBulletsContent";

const CookiesPolicy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const paragraphContent = [
    {
      title: "1. WHO WE ARE AND HOW WE CAN BE CONTACTED?",
      component: <WhoWeAre />,
    },
    {
      title: "2. ABOUT OUR PRIVACY DEPARTMENT",
      component: <AboutOurPrivacy />,
    },
    {
      title: "3. WHAT IS A COOKIE?",
      component: <WhatIsACookie />,
    },
    {
      title: "4. OTHER POLICIES WE ENCOURAGE YOU TO READ",
      component: <OtherPolicies />,
    },
    {
      title: "5. WHY DOES MICHELIN USE COOKIES?",
      component: <WhyUsingCookies />,
    },
    {
      title: "6. HOW DOES MICHELIN INFORM YOU OF THE COOKIES THAT IT USES?",
      component: <HowWeInform />,
    },
    {
      title: "7. HOW TO REFUSE AND/OR UNINSTALL THESE COOKIES?",
      component: <HowToRefuse />,
    },
    {
      title: "8. WHAT TYPES OF COOKIES ARE USED BY MICHELIN?",
      component: <TypesOfCookies />,
    },
  ];

  return (
    <div className="main-container">
      <div className="static-pages-heading">Cookies Policy</div>
      <div className="static-pages-container">
        <div className="static-pages-sub-container">
          <div className="static-pages-container-padding">
            <div className="static-pages-sub-heading">COOKIES POLICY</div>
            <div className="static-pages-title">HOW MICHELIN USES COOKIES</div>
            <div className="static-pages-summary">Summary</div>
            <SummaryParagraph />
            {paragraphContent.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className="static-pages-points">{item.title}</div>
                  <>{item.component}</>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicy;
