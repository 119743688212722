import {
  POST_APPS_TO_PERSONA_API_INITIATED,
  POST_APPS_TO_PERSONA_API_SUCCEED,
  POST_APPS_TO_PERSONA_API_FAILED,
  GET_APPS_TO_PERSONA_API_INITIATED,
  GET_APPS_TO_PERSONA_API_SUCCEED,
  GET_APPS_TO_PERSONA_API_FAILED,
  APPS_CHECKED,
  APPS_USAGE,
  APPS_USAGE_ON_BLUR,
  APPS_DESELECT_ALL,
} from "../constants/appsToPersonaConstants";

const initialState = {
  appsToPersonaApiCallInitiated: false,
  appsToPersonaApiCallResponse: {},
  appsToPersonaApiCallFailed: {},
  getAppsToPersonaApiCallInitiated: false,
  getAppsToPersonaApiCallResponse: [],
  getAppsToPersonaApiCallAppsAssignedResponse: [],
  getAppsToPersonaApiCallFailed: {},
};

const appsToPersonaReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case POST_APPS_TO_PERSONA_API_INITIATED:
      return {
        appsToPersonaApiCallInitiated: true,
        appsToPersonaApiCallResponse: {},
        appsToPersonaApiCallFailed: {},
      };
    case POST_APPS_TO_PERSONA_API_SUCCEED:
      return {
        appsToPersonaApiCallInitiated: false,
        appsToPersonaApiCallResponse: action.payload,
        appsToPersonaApiCallFailed: {},
      };
    case POST_APPS_TO_PERSONA_API_FAILED:
      return {
        appsToPersonaApiCallInitiated: false,
        appsToPersonaApiCallResponse: {},
        appsToPersonaApiCallFailed: action.payload,
      };
    case GET_APPS_TO_PERSONA_API_INITIATED:
      return {
        getAppsToPersonaApiCallInitiated: true,
        getAppsToPersonaApiCallAppsAssignedResponse: [],

        getAppsToPersonaApiCallFailed: {},
      };
    case GET_APPS_TO_PERSONA_API_SUCCEED: {
      const payloadCopy = [...action.payload];
      let appsAssignedArray = [];
      if (payloadCopy[1]?.length > 0) {
        appsAssignedArray = payloadCopy[1]?.map((e: any, i: any) => {
          const result = payloadCopy[0]?.find(
            (element: { [x: string]: any }) =>
              element["package-name"] === e["package-name"] &&
              element["platform-name"] === e["platform-name"]
          );
          if (result) {
            e["is-selected"] = true;
            e["app-usage-by-persona"] = result["app-usage-by-persona"];
          } else {
            e["is-selected"] = false;
            e["app-usage-by-persona"] = "";
          }
          return e;
        });
      }
      return {
        ...state,
        getAppsToPersonaApiCallInitiated: false,
        getAppsToPersonaApiCallAppsAssignedResponse: appsAssignedArray,
        getAppsToPersonaApiCallFailed: {},
      };
    }
    case GET_APPS_TO_PERSONA_API_FAILED: {
      return {
        getAppsToPersonaApiCallInitiated: false,
        getAppsToPersonaApiCallAppsAssignedResponse: [],
        getAppsToPersonaApiCallFailed: action.payload,
      };
    }
    case APPS_CHECKED: {
      return {
        ...state,
        getAppsToPersonaApiCallAppsAssignedResponse:
          action.payload.getAppsToPersonaApiCallAppsAssignedResponse.map(
            (item: any, idx: number) =>
              action.payload.packageName === item["package-name"] &&
              action.payload.platformName === item["platform-name"]
                ? { ...item, ["is-selected"]: action.payload.checked }
                : item
          ),
      };
    }
    case APPS_USAGE: {
      return {
        ...state,
        getAppsToPersonaApiCallAppsAssignedResponse:
          action.payload.getAppsToPersonaApiCallAppsAssignedResponse.map(
            (item: any, idx: number) =>
              action.payload.packageName === item["package-name"] &&
              action.payload.platformName === item["platform-name"]
                ? { ...item, ["app-usage-by-persona"]: action.payload.value }
                : item
          ),
      };
    }
    case APPS_USAGE_ON_BLUR: {
      return {
        ...state,
        getAppsToPersonaApiCallAppsAssignedResponse:
          action.payload.getAppsToPersonaApiCallAppsAssignedResponse.map(
            (item: any, idx: number) =>
              action.payload.packageName === item["package-name"] &&
              action.payload.platformName === item["platform-name"]
                ? { ...item, ["app-usage-by-persona"]: action.payload.value }
                : item
          ),
      };
    }
    case APPS_DESELECT_ALL: {
      return {
        ...state,
        getAppsToPersonaApiCallAppsAssignedResponse:
          action.payload.getAppsToPersonaApiCallAppsAssignedResponse.map(
            (item: any, idx: number) => {
              return { ...item, ["is-selected"]: false };
            }
          ),
      };
    }
    default:
      return state;
  }
};

export default appsToPersonaReducers;
