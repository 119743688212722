import React, { useEffect, useRef, useState } from "react";
import "./DashboardScreen.scss";
import {
  appstoreIcon,
  appstoreSelectedIcon,
  playstoreIcon,
  playstoreSelectedIcon,
} from "../../assets";
import { useDispatch } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";
import { initiateApiCall } from "../../api";
import { useNavigate } from "react-router-dom";
import { clearCacheAndCookies, getAuthToken } from "../../utils/Utils";
import { useAuth } from "../../routes/useAuth";
import {
  ErrorPopup,
  ProcessingDialog,
  AppCountComponent,
  CustomCheckboxCard,
  PaginationComponent,
  AllAppsComponent,
  TabsComponent,
} from "../../components";
import { validateTokenExpiry } from "../../utils/Utils";

interface ApiResponse {
  code: number;
  message: string;
  result: ResultData;
  error: string;
}

interface AppStatisticsApiResponse {
  code: number;
  message: string;
  result: AppStatisticsResultData;
  error: string;
}

interface AppStatisticsResultData {
  "app-statistics": AppStatisticsData;
}

export interface AppStatisticsData {
  overall: AppCountData;
  registered: AppCountData;
  unregistered: AppCountData;
}

interface AppCountData {
  total: number;
  android: number;
  ios: number;
}

interface ResultData {
  "all-apps": AppData[];
  page: number;
  limit: number;
  "total-pages": number;
  "total-results": number;
}

export interface AppData {
  "package-name": string;
  "platform-name": string;
  "store-app-name": string;
  "app-logo": string;
  "app-account-holder": string | null;
  "platform-registered": boolean;
  "avg-rating": number;
  "store-app-logo": string;
  "app-status": {
    "version-string": string;
    status: string;
    "app-version-state": string;
    reason: string | null;
  };
}

const DashboardScreen = () => {
  const { setAuthToken }: any = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedTab, setSelectedTab] = useState("all");
  const [allApps, setAllApps] = useState<any>(
    JSON.parse(localStorage.getItem("allApps") || "[]")
  );
  const [searchValue, setSearchValue] = useState<AppData[]>([]);
  const [lastFiveSearch, setLastFiveSearch] = useState<string[]>([]);
  const [isSearched, setIsSearched] = useState(false);
  const [filteredApps, setFilteredApps] = useState<AppData[]>([]);
  const [filterClicked, setFilteredClicked] = useState(false);
  const navigate = useNavigate();
  const [appStatistics, setAppStatistics] = useState<AppStatisticsData>(
    JSON.parse(localStorage.getItem("appStatistics") || "{}")
  );
  const [showSearchList, setShowSearchList] = useState<string[]>([]);
  const isLastAPICall = useRef(false);

  const tabs = [
    { label: "All", value: "all" },
    { label: "Registered", value: "registered" },
    { label: "Unregistered", value: "unregistered" },
  ];

  const [showProgressDialog, setShowProgressDialog] = useState(
    localStorage.getItem("allApps") !== null ? false : true
  );
  const [showAnalyticsProgressDialog, setShowAnalyticsProgressDialog] =
    useState(localStorage.getItem("appStatistics") !== null ? false : true);
  const [selectedSearch, setSelectedSearch] = useState("");
  const [showValues, setShowValues] = useState(false);
  const descRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const handleSelectedSearch = (selectedValue: any) => {
    setSelectedSearch(selectedValue);
    setSearchValue(
      allApps.filter((item: any) =>
        item["store-app-name"]
          ?.toLowerCase()
          .includes(selectedValue.toLowerCase())
      )
    );
    setIsSearched(true);
  };

  const setTab = (tab: string) => {
    setSelectedTab(tab);
    if (tab === "all") {
      setSelectedStore("");
    }
  };

  const setStore = (store: string) => {
    setSelectedStore(store);
  };

  useEffect(() => {
    setFilteredClicked(true);
    filterApps();
  }, [selectedTab, searchValue]);

  useEffect(() => {
    setFilteredClicked(true);
    filterApps();
  }, [selectedStore, searchValue]);

  useEffect(() => {
    return;
  }, [isSearched]);

  useEffect(() => {
    const values = Array.from(
      new Set(
        lastFiveSearch
          .slice(Math.max(lastFiveSearch.length - 5, 0))
          .filter((item: string) => item !== "")
      )
    );
    if (values.length > 0) {
      localStorage.setItem("searches", JSON.stringify(values));
    }
  }, [lastFiveSearch]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        descRef.current &&
        !descRef.current.contains(event.target) &&
        showValues
      ) {
        setShowValues(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [descRef]);

  useEffect(() => {
    if (isLastAPICall.current) {
      const sortedApps = sortApps(allApps);
      setAllApps(sortedApps);
      localStorage.setItem("allApps", JSON.stringify(sortedApps));
    }
  }, [allApps]);

  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });

  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);

  const handleError = (error: ErrorResponse) => {
    setErrorPopupData({
      boldMessage: error.error,
      message: error.message,
    });
    setIsErrorPopupVisible(true);
  };

  const filterApps = () => {
    let apps = searchValue.length > 0 ? searchValue : allApps;
    setFilteredClicked(true);

    if (selectedTab !== "all") {
      apps = apps.filter((app: any) =>
        selectedTab === "registered"
          ? app["platform-registered"]
          : !app["platform-registered"]
      );
    }

    if (selectedStore !== "") {
      apps = apps.filter((app: any) =>
        selectedStore === "android"
          ? app["platform-name"] === "android"
          : app["platform-name"] === "ios"
      );
    }
    setFilteredApps(apps);
  };

  const handleCloseError = () => {
    setErrorPopupData({
      boldMessage: "",
      message: "",
    });
    setIsErrorPopupVisible(false);
  };
  // to do when pagination is implemented
  // const handleNextPageClicked = (pageNumber: number) => {
  //   fetchAllApps(pageNumber - 1, 50);
  // };

  const handleMouseEnter = () => {
    setShowValues((prevState) => !prevState);
    setShowSearchList(JSON.parse(localStorage.getItem("searches") || "[]"));
  };

  const handleMouseLeave = () => {
    setShowValues((prevState) => !prevState);
  };

  const Values = () => (
    <div className="search-dropdown">
      {showSearchList.length > 0 && (
        <div className="searched-values">
          {showSearchList.map((item: any, index: any) => (
            <div
              className="searched-value"
              key={index}
              onClick={() => handleSelectedSearch(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const handleSearch = (e: any) => {
    setSelectedSearch(e.target.value);

    if (e.target.value.length > 2) {
      setTimeout(() => {
        setLastFiveSearch([...lastFiveSearch, e.target.value]);
      }, 5000);
      setTimeout(() => {
        searchRef.current?.blur();
      }, 10000);

      setIsSearched(true);
      setSearchValue(
        allApps.filter((item: any) =>
          item["store-app-name"]
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    } else if (e.target.value == "") {
      setIsSearched(false);
      setSearchValue(allApps);
    }
  };

  const sortApps = (allApps: any) => {
    return allApps.sort((a: any, b: any) => {
      if (a["platform-registered"] === b["platform-registered"]) {
        return a["store-app-name"]?.localeCompare(b["store-app-name"]);
      }
      return a["platform-registered"] ? -1 : 1;
    });
  };

  const fetchAllApps = async (pageNumber: number, pageLimit: number) => {
    if (pageNumber === 0) {
      setShowProgressDialog(true);
    }
    setFilteredClicked(false);
    setSelectedStore("");
    try {
      if (pageNumber === 0 && localStorage.getItem("user_email") === null) {
        const userInfo = await initiateApiCall(
          "get",
          `${process.env.REACT_APP_FEDERATION_USER_INFO_URI}`,
          null,
          {
            Authorization: getAuthToken(),
          }
        );
        if (userInfo.data.email !== null) {
          localStorage.setItem("user_email", userInfo.data.email);
        }
      }

      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/find/all-apps`,
        null,
        {
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
        },
        {
          page: pageNumber,
          limit: pageLimit,
          "sort-by": "appName:asc",
        }
      );

      if (response.data.code === 200) {
        const appList = response.data.result["all-apps"];
        setAllApps((prevState: any) => [...prevState, ...appList]);
        setTotalPages(response.data.result["total-pages"]);
        setShowProgressDialog(false);
        if (appList.length === 50) {
          fetchAllApps(pageNumber + 1, 50);
        } else {
          isLastAPICall.current = true;
        }
      } else if (response.data.code === 401) {
        setShowProgressDialog(false);
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        setIsErrorPopupVisible(true);
        setShowProgressDialog(false);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
        } else {
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
      }
      setShowProgressDialog(false);
    }
  };

  const fetchAppStatistics = async () => {
    setShowAnalyticsProgressDialog(true);
    try {
      const response: AxiosResponse<AppStatisticsApiResponse> =
        await initiateApiCall(
          "get",
          `${process.env.REACT_APP_BASE_URL}/app/analytics/statistics`,
          null,
          {
            Authorization: getAuthToken(),
            apikey: `${process.env.REACT_APP_API_KEY}`,
          }
        );

      if (response.data.code === 200) {
        localStorage.setItem(
          "appStatistics",
          JSON.stringify(response.data.result["app-statistics"])
        );
        setAppStatistics(response.data.result["app-statistics"]);
        setShowAnalyticsProgressDialog(false);
      } else {
        setIsErrorPopupVisible(true);
        setShowAnalyticsProgressDialog(false);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
        } else {
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
      }
      setShowAnalyticsProgressDialog(false);
    }
  };

  useEffect(() => {
    const tokenExpiry = validateTokenExpiry();
    tokenExpiry
      .then((res) => {
        if (!res) {
          setShowAnalyticsProgressDialog(false);
          setShowProgressDialog(false);
          localStorage.removeItem("allApps");
          localStorage.removeItem("appStatistics");
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else {
          if (localStorage.getItem("appStatistics") === null) {
            fetchAppStatistics();
          }
          if (localStorage.getItem("allApps") === null) {
            fetchAllApps(0, 50);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        localStorage.removeItem("allApps");
        localStorage.removeItem("appStatistics");
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      });
  }, []);

  return (
    <div className="main-container">
      {isErrorPopupVisible && (
        <ErrorPopup
          boldMessage={errorPopupData.boldMessage}
          message={errorPopupData.message}
          onClose={handleCloseError}
        />
      )}
      {showAnalyticsProgressDialog ? (
        <ProcessingDialog message="Loading..." />
      ) : (
        <div className="dashboard-sub-container">
          <div className="title-text-dark bold">Dashboard</div>
          <AppCountComponent appStatisticsData={appStatistics} />
          {showProgressDialog ? (
            <ProcessingDialog message="Loading..." />
          ) : (
            <>
              <div className="title-text-dark bold">Available Apps</div>
              <div className="dashboard-tabs-row">
                <div className="dashboard-flex-row">
                  <TabsComponent
                    selectedTab={selectedTab}
                    setTab={setTab}
                    tabs={tabs}
                  />
                  <CustomCheckboxCard
                    icon={playstoreIcon}
                    selectedIcon={playstoreSelectedIcon}
                    altText="android"
                    checked={selectedStore === "android"}
                    setSelectedStore={setStore}
                  />
                  <CustomCheckboxCard
                    icon={appstoreIcon}
                    selectedIcon={appstoreSelectedIcon}
                    altText="ios"
                    checked={selectedStore === "ios"}
                    setSelectedStore={setStore}
                  />
                </div>

                <div
                  className="search-div-wrapper"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  ref={descRef}
                >
                  <input
                    type="text"
                    value={selectedSearch}
                    className="search-box"
                    placeholder="Search apps"
                    onChange={handleSearch}
                    ref={searchRef}
                  />
                  {showSearchList.length > 0 && showValues ? <Values /> : null}
                </div>
              </div>

              {searchValue.length === 0 && isSearched ? (
                <AllAppsComponent data={[]} />
              ) : (
                <AllAppsComponent
                  data={filterClicked ? filteredApps : allApps}
                />
              )}
            </>
          )}
          {/* {totalPages > 0 && <PaginationComponent totalPageNumber={totalPages} nextPageClicked={handleNextPageClicked} />} */}
        </div>
      )}
    </div>
  );
};

export default DashboardScreen;
