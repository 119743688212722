import React from "react";
import { checkMarkLarge, crossIcon, plusSymbol } from "../../../../assets";
import "../CreatePersona.scss";
import InputField from "../../../../components/inputField/InputField";

const CreatePersonaStepTwo = ({
  selectedLB,
  selectedBusinessCategory,
  businessCategory,
  handleOnClick,
  newBusinessCategory,
  handleRemoveCategoryClick,
  hideAddCategoryLink,
  handleAddInput,
  hideAddCategoryInput,
  inputs,
  handleChange,
  error,
  handleOnAddClick,
}: any) => {
  return (
    <li
      className={`StepProgress-item ${
        selectedBusinessCategory !== "" && "is-done"
      } ${
        selectedLB.length > 0 && selectedBusinessCategory == "" && "current"
      }`}
    >
      <div
        className={`create-persona-select-business-category ${
          selectedLB.length === 0 && "create-persona-label-disable"
        }`}
      >
        Select Business Category
      </div>
      <div
        className={`create-persona-business-lines-capsule-container ${
          selectedLB.length === 0 && "create-persona-section-hide"
        }`}
      >
        {selectedLB?.[0]?.["business-categories"]?.map(
          (item: any, index: any) => {
            return (
              <div
                key={index}
                className={`persona-business-category-capsule ${
                  selectedBusinessCategory["business-category-id"] ===
                  item["business-category-id"]
                    ? "persona-business-line-capsule-color"
                    : ""
                }`}
                onClick={(e) => handleOnClick(e, item, "business category")}
              >
                {item["business-category-name"]}
              </div>
            );
          }
        )}
        {newBusinessCategory.length > 0 &&
          newBusinessCategory.map((item: any, index: any) => {
            return (
              <div
                key={index}
                className={`persona-business-category-capsule ${
                  selectedBusinessCategory === item["business-category"]
                    ? "persona-business-line-capsule-color"
                    : ""
                }`}
              >
                <span
                  onClick={(e) =>
                    handleOnClick(
                      e,
                      item["business-category"],
                      "business category"
                    )
                  }
                >
                  {item["business-category"]}
                </span>
                {selectedBusinessCategory === item["business-category"] &&
                  "persona-business-line-capsule-color" && (
                    <img
                      src={crossIcon}
                      alt="close"
                      className="create-persona-new-category-remove"
                      onClick={() =>
                        handleRemoveCategoryClick(item["business-category"])
                      }
                    />
                  )}
              </div>
            );
          })}

        <div
          className="create-persona-new-category"
          onClick={() => handleAddInput()}
        >
          <div className="persona-business-lines-capsule create-persona-business-lines-capsule-border">
            Create New Category
            <img className="plus-symbol" src={plusSymbol} alt="Close" />
          </div>
        </div>
      </div>
      {!hideAddCategoryInput && (
        <div className="create-persona-add-new-category-heading">
          {inputs?.map((item: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <div className="input_container">
                  <div className="create-persona-input-category-div">
                    <InputField
                      title="New Category"
                      placeholder="New Category"
                      styleType="input-field input-new-category"
                      value={item.BusinessCategory}
                      onChange={(value: string) => {
                        handleChange(value, "BusinessCategory", index);
                      }}
                      validationError={error}
                    />
                  </div>
                  <div>
                    {item.BusinessCategory && (
                      <div
                        className="create-persona-save-category"
                        onClick={(e) =>
                          handleOnAddClick(
                            e,
                            item.BusinessCategory,
                            "business category"
                          )
                        }
                      >
                        <img
                          className="create-persona-check-mark-large"
                          src={checkMarkLarge}
                          alt="Close"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </li>
  );
};

export default CreatePersonaStepTwo;
