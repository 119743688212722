import React from "react";
import "./AllAppsComponent.scss";
import { AppCardComponent } from "../../components";
import { AppData } from "../../screens/dashboardScreen/DashboardScreen";
import EmptyMessageComponent from "../emptyMessageComponent/EmptyMessageComponent";

type Props = {
  data: AppData[];
};

const AllAppsComponent = ({ data }: Props) => {
  return (
    <>
      {data.length > 0 ? (
        <div className="all-aps-container">
          {data.map((item: any, index: any) => {
            return (
              <AppCardComponent
                key={`${item["package-name"]}_${index}`}
                appData={item}
                index={index}
                sourceScreen={"dashboard"}
              />
            );
          })}
        </div>
      ) : (
        <EmptyMessageComponent
          className="empty-message"
          message="No apps found!"
        />
      )}
    </>
  );
};

export default AllAppsComponent;
