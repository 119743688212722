import React from "react";
import "./AssignedAppsCard.scss";

const AssignedAppsCard = ({ imageURL, title, icon }: any) => {
  return (
    <div className="card-new">
      <div className="container">
        <div className="image-title-container">
          <img className="image" src={imageURL} alt={title} />
          <span className="title">{title}</span>
        </div>
        <img className="app-icon" src={icon} alt={title} />
      </div>
    </div>
  );
};

export default AssignedAppsCard;
