import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CreatePersona.scss";
import CreatePersonaStepOne from "./CreatePersonaStepper/CreatePersonaStepOne";
import CreatePersonaStepTwo from "./CreatePersonaStepper/CreatePersonaStepTwo";
import CreatePersonaStepThree from "./CreatePersonaStepper/CreatePersonaStepThree";
import { backIcon, uploadIcon } from "../../../assets";
import { AxiosResponse, AxiosError } from "axios";
import { initiateApiCall } from "../../../api";
import {
  checkFieldEmpty,
  clearCacheAndCookies,
  isValidEmail,
  getAuthToken,
  uploadPersonaIcon,
  imageURLToFile,
  checkIconSize,
  fetchUserName,
  getUserEmail,
  isValueExistsInArray,
} from "../../../utils/Utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getLineOfBusinessWithCategoriesApiFailed,
  getLineOfBusinessWithCategoriesApiInitiated,
  getLineOfBusinessWithCategoriesApiSucceed,
  postCreatePersonaApiFailed,
  postCreatePersonaApiInitiated,
  postCreatePersonaApiSucceed,
} from "../../../stateManagement/actions/createPersonaActions";
import {
  getPersonaListByLBApiInitiated,
  getPersonaListByLBApiSucceed,
  getPersonaListByLBApiFailed,
} from "../../../stateManagement/actions/personaListActions";
import { useAuth } from "../../../routes/useAuth";
import ErrorPopup from "../../../components/popup/errorPopup/ErrorPopup";
import ProcessingDialog from "../../../components/popup/processingDialog/ProcessingDialog";
import SuccessPopup from "../../../components/popup/successPopup/SuccessPopup";
import { ErrorResponse } from "../../../interfaces/common/ErrorResponse";

interface Field {
  value: any;
  validationError: string;
  required: boolean;
}

type CreatePersonaData = {
  [key: string]: Field;
};

interface LineOfBusiness {
  "lb-id": number;
  "line-of-business": string;
  "business-categories": [];
}

const CreatePersona = () => {
  const navigate = useNavigate();
  const { setAuthToken }: any = useAuth();
  const {
    apiCallInitiated,
    apiResponse,
    apiCallFailed,
    postCreatePersonaApiCallInitiated,
    postCreatePersonaApiCallResponse,
    postCreatePersonaApiCallFailed,
  } = useSelector((state: any) => state.createPersonaReducers, shallowEqual);

  const dispatch = useDispatch();
  const [searchedBusinessLine, setSearchedBusinessLine] = useState<any>("");
  const [personaNamesList, setPersonaNamesList] = useState<any>([]);
  const [selectedLB, setSelectedLB] = useState<LineOfBusiness[]>([]);
  const [error, setError] = useState<any>("");
  const [businessCategory, setBusinessCategory] = useState<any>([]);
  const [customerType, setCustomerType] = useState<any>([
    { "customer-type-id": 1, "customer-type": "Customer" },
    { "customer-type-id": 2, "customer-type": "Employee" },
    { "customer-type-id": 3, "customer-type": "Ext. Employee" },
  ]);
  const [businessType, setBusinessType] = useState<any>([
    { "business-type-id": 1, "business-type": "B2B" },
    { "business-type-id": 2, "business-type": "B2C" },
    { "business-type-id": 3, "business-type": "B2E" },
  ]);

  const [newBusinessCategory, setNewBusinessCategory] = useState<any>([]);
  const [newCustomerType, setNewCustomerType] = useState<any>([]);
  const [selectedBusinessCategory, setSelectedBusinessCategory] =
    useState<any>("");
  const [selectedCustomerType, setSelectedCustomerType] = useState<any>([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>([]);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const initialCreatePersonaData: CreatePersonaData = {
    "persona-name": { value: "", validationError: "", required: true },
    "person-name": { value: "", validationError: "", required: true },
    "person-logo": { value: "", validationError: "", required: true },
    "contact-email": { value: "", validationError: "", required: true },
    location: { value: "", validationError: "", required: true },
    "about-persona": { value: "", validationError: "", required: true },
    goals: { value: "", validationError: "", required: true },
  };
  const [createPersonaData, setCreatePersonaData] = useState<any>(
    initialCreatePersonaData
  );

  const initialCreatePersonaSelectionData: CreatePersonaData = {
    "ct-id": {
      value: "",
      validationError: "",
      required: true,
    },
    "ct-name": { value: "", validationError: "", required: true },
    "bt-id": { value: "", validationError: "", required: true },
    "bc-id": { value: "", validationError: "", required: true },
    "bc-name": { value: "", validationError: "", required: true },
  };

  const [createPersonaSelectionData, setCreatePersonaSelectionData] =
    useState<any>(initialCreatePersonaSelectionData);

  const handleOnChange = (e: any) => {
    if (e.target.value.length > 1) {
      setSearchedBusinessLine(
        apiResponse.filter((item: any) =>
          item["line-of-business"]
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    } else if (e.target.value == "") {
      setSearchedBusinessLine(apiResponse);
    }
  };

  const [hideAddCategoryLink, setHideAddCategoryLink] = useState<any>(false);
  const [hideAddCategoryInput, setHideAddCategoryInput] = useState(false);
  const [hideOtherCapsule, setHideOtherCapsule] = useState(false);
  const [hideAddCustomerTypeInput, setHideCustomerTypeInput] = useState(false);

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
    navigate("/myApps", {
      state: {
        from: "persona",
      },
    });
  };

  const handleDiscard = () => {
    setCreatePersonaData({ ...initialCreatePersonaData });
    setCreatePersonaSelectionData({ ...initialCreatePersonaSelectionData });
    setSelectedLB([]);
    setSelectedBusinessCategory("");
    setNewBusinessCategory("");
    setSelectedCustomerType([]);
    setSelectedBusinessType([]);
  };

  const handleOnClick = (
    e: any,
    item: string | number | any,
    value: string
  ) => {
    if (value == "business line") {
      setInputs([]);
      setError("");
      setSelectedLB(
        apiResponse.filter((i: any) => i["line-of-business"] === item)
      );
      setNewBusinessCategory([]);
      setSelectedBusinessCategory("");
    } else if (value === "business category") {
      if (typeof item === "object") {
        setSelectedBusinessCategory(item);
        setCreatePersonaSelectionData({
          ...createPersonaSelectionData,
          "bc-id": {
            ...createPersonaSelectionData["bc-id"],
            value: item["business-category-id"],
            validationError: "",
          },
        });
      } else {
        setSelectedBusinessCategory(item);
        setCreatePersonaSelectionData({
          ...createPersonaSelectionData,
          "bc-name": {
            ...createPersonaSelectionData["bc-name"],
            value: item,
            validationError: "",
          },
        });
      }
    } else if (value === "business type") {
      setSelectedBusinessType(
        businessType.filter((i: any) => i["business-type-id"] === item)
      );
      setCreatePersonaSelectionData({
        ...createPersonaSelectionData,
        "bt-id": {
          ...createPersonaSelectionData["bt-id"],
          value: selectedBusinessType?.[0]?.["business-type-id"],
          validationError: "",
        },
      });
    } else if (value === "customer type") {
      setInputs([]);
      setError("");
      if (typeof item === "number") {
        setSelectedCustomerType(
          customerType.filter((i: any) => i["customer-type-id"] === item)
        );
        setCreatePersonaSelectionData({
          ...createPersonaSelectionData,
          "ct-id": {
            ...createPersonaSelectionData["ct-id"],
            value: selectedCustomerType?.[0]?.["customer-type-id"],
            validationError: "",
          },
        });
      } else {
        setSelectedCustomerType(
          newCustomerType.filter((i: any) => i["customer-type"] === item)
        );
        setCreatePersonaSelectionData({
          ...createPersonaSelectionData,
          "ct-name": {
            ...createPersonaSelectionData["ct-name"],
            value: selectedCustomerType?.[0]?.["customer-type"],
            validationError: "",
          },
        });
      }
    }
  };

  const handleOnAddClick = (e: any, item: any, value: string) => {
    if (value === "business category") {
      setError("");
      const businessCategoryObject = selectedLB?.[0]?.[
        "business-categories"
      ].find(
        (i: any) =>
          i["business-category-name"].toLowerCase() === item.toLowerCase()
      );
      const newBusinessCategoryObject = newBusinessCategory?.find(
        (i: any) => i["business-category"].toLowerCase() === item.toLowerCase()
      );
      if (
        businessCategoryObject !== undefined ||
        newBusinessCategoryObject !== undefined
      ) {
        setError("Duplicate values not allowed!");
      } else {
        setHideAddCategoryLink(true);
        setHideAddCategoryInput(true);
        setNewBusinessCategory([
          ...newBusinessCategory,
          { "business-category": item },
        ]);
        setInputs([{ BusinessCategory: "" }]);
      }
    } else if (value === "customer type") {
      setError("");
      const customerTypeObject = customerType.find(
        (i: any) => i["customer-type"].toLowerCase() === item.toLowerCase()
      );
      const newCustomerTypeObject = newCustomerType.find(
        (i: any) => i["customer-type"].toLowerCase() === item.toLowerCase()
      );
      if (
        customerTypeObject !== undefined ||
        newCustomerTypeObject !== undefined
      ) {
        setError("Duplicate values not allowed!");
      } else {
        setHideOtherCapsule(true);
        setHideCustomerTypeInput(true);
        setNewCustomerType([...newCustomerType, { "customer-type": item }]);
        setInputCustomerType([{ CustomerType: "" }]);
      }
    }
  };

  const handleRemoveCategoryClick = (value: string) => {
    setHideAddCategoryLink(false);
    setNewBusinessCategory(
      newBusinessCategory.filter(
        (item: any) => item["business-category"] !== value
      )
    );
    setSelectedBusinessCategory("");
  };

  const handleRemoveCustomerTypeClick = (value: string) => {
    setHideOtherCapsule(false);
    setNewCustomerType(
      newCustomerType.filter((item: any) => item["customer-type"] !== value)
    );
    setSelectedCustomerType("");
  };

  const [inputs, setInputs] = useState<any>([]);
  const [inputCustomerType, setInputCustomerType] = useState<any>([]);

  const handleAddInput = () => {
    setHideAddCategoryInput(false);
    setInputs([{ BusinessCategory: "" }]);
  };

  const handleAddCustomerTypeInput = (value: any) => {
    setSelectedCustomerType([]);
    setHideCustomerTypeInput(false);
    setHideOtherCapsule(false);
    setInputCustomerType([{ CustomerType: "" }]);
  };

  const handleChange = (e: string, name: string, index: number) => {
    const onChangeValue: any = [...inputs];
    onChangeValue[index][name] = e;
    setInputs(onChangeValue);
  };

  const handleCustomerTypeChange = (e: string, name: string, index: number) => {
    const onChangeValue: any = [...inputCustomerType];
    onChangeValue[index][name] = e;
    setInputCustomerType(onChangeValue);
  };

  const onFieldValueChange = (value: string, field: string) => {
    setCreatePersonaData((prevCreatePersonaData: any) => ({
      ...prevCreatePersonaData,
      [field]: {
        ...createPersonaData[field],
        value: value,
        validationError: "",
      },
    }));
  };

  const onFieldBlur = (e: { target: { value: string } }, field: string) => {
    let error = "";
    if (field === "persona-name") {
      if (isValueExistsInArray(personaNamesList, e.target.value.trim())) {
        error =
          "The Persona Name you entered is already in use. Please enter a unique Persona Name";
      }
    }

    setCreatePersonaData((prevCreatePersonaData: any) => ({
      ...prevCreatePersonaData,
      [field]: {
        ...createPersonaData[field],
        value: e.target.value.trim(),
        validationError: error ? error : "",
      },
    }));
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handlePersonaIconChange = async (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      setCreatePersonaData({
        ...createPersonaData,
        ["person-logo"]: {
          ...createPersonaData["person-logo"],
          value: URL.createObjectURL(event.target.files[0]),
          validationError: "",
        },
      });
    }
    event.target.value = null;
  };

  const inputRef: any = useRef(null);
  const personaNameRef: any = useRef(null);
  const nameRef: any = useRef(null);
  const businessTypeRef: any = useRef(null);
  const emailRef: any = useRef(null);
  const aboutPersonaRef: any = useRef(null);
  const needsRef: any = useRef(null);

  const getBusinessLines = async () => {
    dispatch(getLineOfBusinessWithCategoriesApiInitiated());
    try {
      const response: AxiosResponse<any> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/lbs/business-categories`,
        null,
        {
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );

      if (response.data.code === 200) {
        dispatch(
          getLineOfBusinessWithCategoriesApiSucceed(response.data.result)
        );
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            getLineOfBusinessWithCategoriesApiFailed(
              axiosError.response.data as ErrorResponse
            )
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            getLineOfBusinessWithCategoriesApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  const validateData = async () => {
    const updatedCreatePersonaData = { ...createPersonaData };
    const updatedCreatePersonaSelectionData = { ...createPersonaSelectionData };
    let isInvalidData = false;

    for (const fieldKey in updatedCreatePersonaData) {
      const field = updatedCreatePersonaData[fieldKey];

      if (field.required) {
        const validationError = checkFieldEmpty(field.value);

        if (!isInvalidData && validationError !== "") isInvalidData = true;
        updatedCreatePersonaData[fieldKey] = {
          ...updatedCreatePersonaData[fieldKey],
          validationError,
        };
      }

      if (fieldKey === "persona-name") {
        if (isValueExistsInArray(personaNamesList, field.value)) {
          updatedCreatePersonaData[fieldKey] = {
            ...updatedCreatePersonaData[fieldKey],
            validationError:
              "The Persona Name you entered is already in use. Please enter a unique Persona Name",
          };
          isInvalidData = true;
        }
      }

      if (fieldKey === "contact-email") {
        if (!checkFieldEmpty(field.value)) {
          const validationError = isValidEmail(field.value);
          if (!isInvalidData && validationError !== "") isInvalidData = true;
          updatedCreatePersonaData[fieldKey] = {
            ...updatedCreatePersonaData[fieldKey],
            validationError,
          };
        }
      }

      if (fieldKey === "person-logo" && field.value) {
        const isBlobSizeOk = await checkIconSize(field.value);
        if (!isBlobSizeOk) {
          updatedCreatePersonaData[fieldKey] = {
            ...updatedCreatePersonaData[fieldKey],
            validationError: "Maximum file size exceeded!",
          };
          isInvalidData = true;
        }
      }
    }

    if (Object.keys(selectedCustomerType).length === 0) {
      updatedCreatePersonaSelectionData["ct-id"].validationError =
        "Please select a value";
      updatedCreatePersonaSelectionData["ct-name"].validationError =
        "Please select a value";
      isInvalidData = true;
    }

    if (Object.keys(selectedBusinessType).length === 0) {
      updatedCreatePersonaSelectionData["bt-id"].validationError =
        "Please select a value";
      isInvalidData = true;
    }

    if (updatedCreatePersonaData["persona-name"]["validationError"] !== "") {
      personaNameRef.current?.scrollIntoView();
    } else if (
      updatedCreatePersonaData["person-name"]["validationError"] !== "" ||
      updatedCreatePersonaData["person-logo"]["validationError"] !== ""
    ) {
      nameRef.current?.scrollIntoView();
    } else if (
      updatedCreatePersonaSelectionData["bt-id"]["validationError"] !== "" ||
      Object.keys(selectedCustomerType).length === 0
    ) {
      businessTypeRef.current?.scrollIntoView();
    } else if (
      updatedCreatePersonaData["contact-email"]["validationError"] !== "" ||
      updatedCreatePersonaData["location"]["validationError"] !== ""
    ) {
      emailRef.current?.scrollIntoView();
    } else if (
      updatedCreatePersonaData["about-persona"]["validationError"] !== ""
    ) {
      aboutPersonaRef.current?.scrollIntoView();
    } else if (updatedCreatePersonaData["goals"]["validationError"] !== "") {
      needsRef.current?.scrollIntoView();
    }

    setCreatePersonaData(updatedCreatePersonaData);
    setCreatePersonaSelectionData(updatedCreatePersonaSelectionData);
    return isInvalidData;
  };

  const handleSubmit = async () => {
    const isInvalidData = await validateData();
    if (isInvalidData) return;

    const createPersonaDataForRESTCall: { [key: string]: any } = {};
    for (const fieldKey in createPersonaData) {
      createPersonaDataForRESTCall[fieldKey] =
        createPersonaData[fieldKey].value;
    }

    createPersonaDataForRESTCall["created-by-email"] = getUserEmail();

    const name = await fetchUserName();
    if (name) {
      createPersonaDataForRESTCall["creator-name"] = name;
    }

    createPersonaDataForRESTCall["lb-id"] = selectedLB?.[0]?.["lb-id"];

    if (selectedBusinessCategory["business-category-id"]) {
      createPersonaDataForRESTCall["bc-id"] =
        selectedBusinessCategory["business-category-id"];
      createPersonaDataForRESTCall["bc-name"] = null;
    } else {
      createPersonaDataForRESTCall["bc-name"] = selectedBusinessCategory;
      createPersonaDataForRESTCall["bc-id"] = null;
    }

    if (selectedCustomerType?.[0]?.["customer-type-id"]) {
      createPersonaDataForRESTCall["ct-id"] =
        selectedCustomerType?.[0]?.["customer-type-id"];
      createPersonaDataForRESTCall["ct-name"] = null;
    } else {
      createPersonaDataForRESTCall["ct-name"] =
        selectedCustomerType?.[0]?.["customer-type"];
      createPersonaDataForRESTCall["ct-id"] = null;
    }

    createPersonaDataForRESTCall["bt-id"] =
      selectedBusinessType?.[0]?.["business-type-id"];

    const file: any = await imageURLToFile(
      createPersonaData["person-logo"].value,
      "persona"
    ).catch((error) => {
      throw error;
    });

    createPersonaDataForRESTCall["person-logo"] = "";

    const formData = new FormData();

    formData.append(
      "persona-info",
      new Blob([JSON.stringify(createPersonaDataForRESTCall)], {
        type: "application/json",
      })
    );

    formData.append("multipart-file", file);
    try {
      dispatch(postCreatePersonaApiInitiated());
      const response = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/persona`,
        formData,
        {
          "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
          "Content-Type": "multipart/form-data",
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );
      if (response.status == 200) {
        await uploadPersonaIcon(file, response.data.result["persona-id"])
          .then((uploadResponse: any) => {
            if (uploadResponse.status == 200) {
              dispatch(postCreatePersonaApiSucceed(response.data));
              setIsSuccessPopupVisible(true);
            }
          })
          .catch((error) => {
            if (error instanceof AxiosError) {
              const axiosError = error as AxiosError;
              if (axiosError.response?.status === 401) {
                clearCacheAndCookies();
                setAuthToken("");
                navigate("/");
              } else if (axiosError.response) {
                dispatch(
                  postCreatePersonaApiFailed(
                    axiosError.response.data as ErrorResponse
                  )
                );
                setIsErrorPopupVisible(true);
              } else {
                dispatch(
                  postCreatePersonaApiFailed({
                    code: 500,
                    message: "Internal Server Error",
                    result: null,
                    error: "Internal Server Error",
                  })
                );
                setIsErrorPopupVisible(true);
              }
            }
          });
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            postCreatePersonaApiFailed(
              axiosError.response.data as ErrorResponse
            )
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            postCreatePersonaApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  const onChoosePersonaIconFile = () => {
    inputRef.current.click();
  };

  const handleImageError = (e: any) => {
    e.target.src = uploadIcon;
    inputRef.current.disabled = false;
    setCreatePersonaData((prevCreatePersonaData: any) => ({
      ...prevCreatePersonaData,
      ["person-logo"]: {
        ...createPersonaData["person-logo"],
        value: "",
        validationError: "",
      },
    }));
  };

  const handleCloseAPIError = () => {
    setIsErrorPopupVisible(false);
    getBusinessLines();
  };

  useEffect(() => {
    getBusinessLines();
  }, []);

  useEffect(() => {
    if (selectedLB && selectedLB?.length !== 0) {
      fetchPersonasbyLBList();
    }
  }, [selectedLB]);

  const fetchPersonasbyLBList = async () => {
    dispatch(getPersonaListByLBApiInitiated());
    try {
      const response: AxiosResponse<any> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/persona/by-lb`,
        null,
        {
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
        },
        selectedLB
          ? {
              "lb-id": selectedLB[0]["lb-id"],
            }
          : null
      );

      if (response?.data?.code === 200) {
        if (response.data.result === null) {
          dispatch(getPersonaListByLBApiSucceed([]));
        } else {
          dispatch(
            getPersonaListByLBApiSucceed(
              response.data.result["business-categories"]
            )
          );
        }
      } else if (response.data.code === 401) {
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        dispatch(
          getPersonaListByLBApiFailed({
            boldMessage: response.data.message,
            message: response.data.error.error,
          })
        );
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as any;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            getPersonaListByLBApiFailed({
              boldMessage: "Error",
              message: axiosError.response.data.error,
            })
          );
        } else {
          dispatch(
            getPersonaListByLBApiFailed({
              boldMessage: "Error",
              message: "Something Went Wrong",
            })
          );
        }
      }
    }
  };

  return (
    <div className="main-container">
      {apiCallInitiated || postCreatePersonaApiCallInitiated ? (
        <ProcessingDialog message="Loading..." />
      ) : (
        <>
          <div className="create-persona-heading heading heading-container bold flex-row">
            <img
              src={backIcon}
              alt="Back Icon"
              className="back-icon"
              onClick={handleBackButton}
            />
            CREATE PERSONA
          </div>
          <div className="create-persona-container">
            <div className="create-persona-sub-container">
              <div className="create-persona-container-padding">
                <div className="create-persona-sub-heading">Create Persona</div>
                <div className="stepper-wrapper">
                  <ul className="StepProgress">
                    <CreatePersonaStepOne
                      selectedLB={selectedLB}
                      handleOnChange={handleOnChange}
                      handleOnClick={handleOnClick}
                      searchedBusinessLine={searchedBusinessLine}
                      apiResponse={apiResponse}
                    />
                    <CreatePersonaStepTwo
                      selectedLB={selectedLB}
                      selectedBusinessCategory={selectedBusinessCategory}
                      businessCategory={businessCategory}
                      handleOnClick={handleOnClick}
                      newBusinessCategory={newBusinessCategory}
                      handleRemoveCategoryClick={handleRemoveCategoryClick}
                      hideAddCategoryLink={hideAddCategoryLink}
                      handleAddInput={handleAddInput}
                      hideAddCategoryInput={hideAddCategoryInput}
                      inputs={inputs}
                      handleChange={handleChange}
                      error={error}
                      handleOnAddClick={handleOnAddClick}
                    />
                    <CreatePersonaStepThree
                      personaNamesList={personaNamesList}
                      setPersonaNamesList={setPersonaNamesList}
                      selectedLB={selectedLB}
                      selectedBusinessCategory={selectedBusinessCategory}
                      onFieldValueChange={onFieldValueChange}
                      onFieldBlur={onFieldBlur}
                      createPersonaData={createPersonaData}
                      createPersonaSelectionData={createPersonaSelectionData}
                      customerType={customerType}
                      selectedCustomerType={selectedCustomerType}
                      handleOnClick={handleOnClick}
                      newCustomerType={newCustomerType}
                      handleRemoveCustomerTypeClick={
                        handleRemoveCustomerTypeClick
                      }
                      hideOtherCapsule={hideOtherCapsule}
                      handleAddCustomerTypeInput={handleAddCustomerTypeInput}
                      businessType={businessType}
                      selectedBusinessType={selectedBusinessType}
                      hideAddCustomerTypeInput={hideAddCustomerTypeInput}
                      error={error}
                      inputCustomerType={inputCustomerType}
                      handleCustomerTypeChange={handleCustomerTypeChange}
                      handleOnAddClick={handleOnAddClick}
                      handleSubmit={handleSubmit}
                      handlePersonaIconChange={handlePersonaIconChange}
                      onChoosePersonaIconFile={onChoosePersonaIconFile}
                      inputRef={inputRef}
                      personaNameRef={personaNameRef}
                      nameRef={nameRef}
                      businessTypeRef={businessTypeRef}
                      emailRef={emailRef}
                      aboutPersonaRef={aboutPersonaRef}
                      needsRef={needsRef}
                      handleImageError={handleImageError}
                      handleDiscard={handleDiscard}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {isSuccessPopupVisible && (
            <SuccessPopup
              boldMessage="Persona created!"
              message={postCreatePersonaApiCallResponse.message}
              onClose={handleCloseSuccessPopup}
            />
          )}
          {isErrorPopupVisible && (
            <ErrorPopup
              boldMessage={
                apiCallFailed?.message
                  ? apiCallFailed?.message
                  : postCreatePersonaApiCallFailed?.message
              }
              message={
                apiCallFailed?.error
                  ? apiCallFailed?.error
                  : postCreatePersonaApiCallFailed?.error
              }
              onClose={handleCloseAPIError}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CreatePersona;
