import React from "react";
import { Link } from "react-router-dom";

export const WhoWeAre = () => {
  return (
    <div className="static-pages-content-with-link">
      Our full company name is Manufacture Française des Pneumatiques Michelin
      (“Michelin”) and when you use our website and our services directly, we
      act as a Data Controller. If you need to contact us using our{" "}
      <span className="static-pages-keyword-with-link">
        <Link to="/contactUs">contact form</Link>
      </span>{" "}
      on our website.
    </div>
  );
};

export const AboutOurPrivacy = () => {
  return (
    <div className="static-pages-content-with-link">
      Everyone at Michelin is committed to protecting and respecting your
      privacy and we have a data protection Privacy Department who deals with
      all aspect of personal data at Michelin. You can contact them sending by
      email to{" "}
      <span className="static-pages-keyword-with-link">
        <a href="mailto:mobileplatform@michelin.com">
          {" "}
          mobileplatform@michelin.com.
        </a>
      </span>
    </div>
  );
};

export const OtherPolicies = () => {
  return (
    <div className="static-pages-content-with-link">
      Some of the information from cookies does not identify you directly or
      indirectly and does not constitute personal data. Others may allow you to
      identify yourself and constitute personal data and as such be subject to
      data protection regulation. For further information on how we use, store,
      keep your personal data secure and about your rights we encourage you to
      read our{" "}
      <span className="static-pages-keyword-with-link">
        <Link to="/privacyPolicy"> Privacy policy</Link>
      </span>{" "}
      and the Privacy policies of our partners available through the Cookies
      management solution.
    </div>
  );
};

export const TermsOfUseSummary = () => {
  return (
    <div className="static-pages-content">
      <div className="">
        Welcome to the{" "}
        <span className="static-pages-keyword-bold">
          Michelin Mobile Platform
        </span>
        , provided by Manufacture Française des Pneumatiques Française (MFPM)
        (hereafter referred to as &quot;Michelin&quot; or &quot;we&quot;). The
        Michelin Mobile Platform is a portal available at{" "}
        <span className="static-pages-keyword-with-link">
          <a href="https://mobile-platform.michelin.net">
            {" "}
            https://mobile-platform.michelin.net
          </a>
        </span>{" "}
        (the <span className="static-pages-keyword-bold">“Portal”</span>
        ).
      </div>
      <div className="static-pages-keyword-bold">
        By using Michelin Mobile Platform, you accept to be bound by these
        Michelin Mobile Platform Terms of Use (the “Terms”) and you represent
        and warrant that you have full legal authority to bind the entity that
        employs you (all references to you in these Terms refer to you and that
        entity).
      </div>
      <div className="static-pages-keyword-bold">
        Please take the time necessary to review that Terms. If you do not agree
        with these Terms or if you are not authorised to bind your entity,
        please do not use our Michelin Mobile Platform.
      </div>
      <div className="static-pages-keyword-bold">
        You may not use Michelin Mobile Platform features if you are under 18
        years.
      </div>
    </div>
  );
};

export const UserLogin = () => {
  return (
    <div className="static-pages-content-with-link">
      To use our platform and Michelin Content&apos;s, you must login using
      Michelin Login credentials (the “User Login)”. You agree not to share your
      login credentials with any other person, even with another employee of
      your company. You agree to notify us immediately of any unauthorized use
      of your login credentials, User Login or any other breach of security. For
      more details on the way we process Personal Data in relation to the User
      Login, please refer to our{" "}
      <span className="static-pages-keyword-with-link">
        <Link to="/privacyPolicy">Privacy Policy</Link>
      </span>
      .
    </div>
  );
};

export const YourRepresentations = () => {
  return (
    <div>
      <div className="static-pages-content">
        You represent and warrant that:
      </div>
      <ul>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          All information you have provided and will provide to us is true,
          correct and complete in all respects;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Your obligations under these Terms do not violate any law, to the
          extent applicable, or breach any other agreement to which you are
          bound;
        </li>
        <li className="static-pages-points-with-content static-pages-points-with-content-margin">
          Any materials you provide, create or develop that are in any way
          related to these Terms and your Application, or the use thereof, do
          not and will not infringe any Intellectual Property Rights of any
          third party; and
        </li>
        <li className="static-pages-points-with-content">
          You are not a person barred from using or receiving Michelin Mobile
          Platform under any applicable laws.
        </li>
      </ul>
      <div className="static-pages-content">
        <div>
          You will not use Mobile Platform to encourage or promote illegal
          activity or violation of any third-party rights.
        </div>
        <div>
          You will only access the Mobile Platform by the means that described
          in the Documentation of that Mobile Platform. You will not
          misrepresent or mask your identity or your company&apos;s identity
          when using the Mobile Platform.
        </div>
        <div>
          You must use the Mobile Platform and Content in a lawful manner, and
          must obey all applicable laws, rules, and regulations.
        </div>
      </div>
    </div>
  );
};

export const GeneralProvisions = () => {
  return (
    <div className="static-pages-content">
      <div>
        <span className="static-pages-keyword-bold">Commercial reference.</span>{" "}
        You shall not issue any press release or general marketing communication
        or make any other public statements concerning these Terms, or our
        relationship without our express prior written consent. We shall be
        entitled to refer to your use of our Michelin Mobile Platform in our
        commercial documentation, press releases and/or any other external
        documentation.
      </div>
      <div>
        <span className="static-pages-keyword-bold">
          Relationship of the Parties.
        </span>{" "}
        We are independent contractors and nothing in these Terms shall make us
        joint ventures, partners, employees, agents or other representatives of
        the other party. Neither party shall make any representation that
        suggests otherwise.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Entire Agreement.</span>{" "}
        These Terms, together with all documents referenced herein such as the
        Documentation and the Privacy Policy, set forth the entire understanding
        and agreement between you and us, and supersedes all prior or
        contemporaneous oral or written agreements or understandings between the
        parties, as to the subject matter of these Terms.
      </div>
      <div>
        <span className="static-pages-keyword-bold">
          Amendment; Modifications.
        </span>{" "}
        We reserve the right to modify the provisions of these Terms at any
        time. You can always find the most recent version of these Terms. We may
        modify these Terms by posting a new version at{" "}
        <span className="static-pages-keyword-with-link">
          <Link to="/termsOfUse">Terms of Use</Link>
        </span>
        , so please continue to check this{" "}
        <span className="static-pages-keyword-with-link">
          <Link to="/termsOfUse">Terms of Use</Link>
        </span>
        , from time to time. To the fullest extent permitted under applicable
        law, your continued use of your User Account, Michelin Mobile Platform
        and Content after any such modification constitute your acceptance of
        these Terms as modified. If you do not agree to any modification of
        these Terms, you must immediately stop accessing and using your User
        Account, Michelin Mobile Platform and Contents.
      </div>
      <div>
        <span className="static-pages-keyword-bold">
          Severability; Headings.
        </span>{" "}
        If any provision of these Terms is held to be invalid or unenforceable
        for any reason, the remaining provisions will continue in full force
        without being impaired or invalidated in any way. Any invalid provision
        will be replaced by a valid provision, which most closely approximates
        the intent and economic effect of the invalid provision. Headings are
        used for convenience of reference only and in no way define, limit,
        construe or describe the scope or extent of any section, or in any way
        affect these Terms.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Waiver.</span> The failure
        of any party to insist on or enforce strict performance of any provision
        of these Terms or to exercise any right or remedy under these Terms or
        applicable law will not be construed as a waiver or relinquishment to
        any extent of the right to assert or rely upon any such provision, right
        or remedy in that or any other instance; rather, the same will be and
        remain in full force and effect.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Assignment.</span> You may
        not assign any of your rights or delegate the performance of any of your
        obligations under these Terms without our prior written consent. We
        reserve the right to assign these Terms or any right or obligation under
        these Terms to an Affiliate without consent. We may assign any rights or
        obligations under these Terms, in whole or in part to any company
        belonging to the same group of companies or to which the assets
        including the concerned Mobile Platform would be transferred.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Language.</span> You accept
        these Terms and all related documents to be in the English language. If
        this Terms is translated into any language other than English, and there
        is a discrepancy between the English text and the translated text, the
        English text will govern unless expressly stated otherwise in the
        translation.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Subcontracting.</span> We
        may subcontract, in whole or in part, the services incumbent upon it
        hereunder, but shall remain solely liable for the performance of any
        subcontracted obligations.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Force Majeure.</span> We
        shall not be liable for any delays, non-performance of its obligations
        or damage caused by a force majeure event, such as those normally
        accepted by applicable law.
      </div>
      <div>
        <span className="static-pages-keyword-bold">Notices.</span> You agree
        that all notices to you may be given electronically, sent to the
        electronic mail address provided by you during the registration of your
        App. You agree that all notices to us shall be sent by certified or
        registered mail, return receipt requested, to the address mentioned in
        the appropriate Documentation.
      </div>
      <div>
        <span className="static-pages-keyword-bold">
          Governing Law and Competent Jurisdiction.
        </span>{" "}
        THESE TERMS SHALL BE GOVERNED BY THE LAW OF THE COUNTRY WHERE THE
        MICHELIN ENTITY MENTIONED IN THE DOCUMENTATION OF THE CONCERNED MICHELIN
        MOBILE PLATFORM IS LOCATED. ALL DISPUTES OF ANY KIND ARISING IN
        CONNECTION WITH THE INTERPRETATION, PERFORMANCE AND/OR TERMINATION OF
        THESE TERMS SHALL BE SUBJECT TO THE COURTS IN THE JURISDICTION OF WHICH
        THE ABOVE-MENTIONED MICHELIN ENTITY IS LOCATED, NOTWITHSTANDING THE
        PRESENCE OF MULTIPLE DEFENDANTS OR THE INTRODUCTION OF THIRD PARTIES.
      </div>
    </div>
  );
};

export const PrivacyWhoWeAre = () => {
  return (
    <>
      <div className="static-pages-content">
        <div>
          Our full company name is Manufacture Française des Pneumatiques
          Michelin (“<span className="static-pages-keyword-bold">Michelin</span>
          ”) and when you use our website and our services directly, we act as a
          Data Controller.
        </div>
        <div>
          <span className="static-pages-keyword-with-link">
            If you need to contact us you can do so through the{" "}
            <Link to="/contactUs">contact form on our website</Link>
          </span>
          .
        </div>
      </div>
    </>
  );
};

export const AboutPrivacyDepartment = () => {
  return (
    <div className="static-pages-content-with-link">
      Everyone at Michelin is committed to protecting and respecting your
      privacy and we have a data protection Data Protection Officer who deals
      with all aspects of personal data at Michelin. You can contact them
      sending by emailing at{" "}
      <span className="static-pages-keyword-with-link">
        <a href="mailto:mobileplatform@michelin.com">
          mobileplatform@michelin.com
        </a>
      </span>
      .
    </div>
  );
};

export const PrivacyCookiePolicy = () => {
  return (
    <div className="static-pages-content-with-link">
      When you use our website and other digital products and services provided
      by us, information can be collected through cookies to distinguish you
      from other users of our website and other digital products and services.
      We have a separate Cookie Policy which explains our use of cookies and the
      purposes for which we use them. We encourage you to read our{" "}
      <span className="static-pages-keyword-with-link">
        <Link to="/cookiesPolicy">Cookie Policy</Link>
      </span>
      .
    </div>
  );
};

export const WhatIfNotHappy = () => {
  return (
    <div className="static-pages-content">
      <div>
        We are committed to protecting and respecting your privacy. However, if
        you&apos;re not happy with how we collect or process your information or
        just want some further information on your rights, you can contact us by
        using our{" "}
        <span className="static-pages-keyword-with-link">
          <Link to="/contactUs">contact form</Link>
        </span>{" "}
        on our website.
      </div>
      <div>
        We will work with you to resolve any issues you may have and aim to
        answer any questions raised by you as soon as possible.
      </div>
      <div>You can also make a complaint to your supervisory authority.</div>
    </div>
  );
};
