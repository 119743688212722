import {
  GET_PERSONA_DETAILS_API_INITIATED,
  GET_PERSONA_DETAILS_API_SUCCEED,
  GET_PERSONA_DETAILS_API_FAILED,
} from "../constants/personaDetailsConstants";

const initialState = {
  getPersonaDetailsApiCallInitiated: false,
  getPersonaDetailsApiResponse: {},
  getPersonaDetailsApiCallFailed: {},
};

const personaDetailsReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PERSONA_DETAILS_API_INITIATED:
      return {
        getPersonaDetailsApiCallInitiated: true,
        getPersonaDetailsApiResponse: {},
        getPersonaDetailsApiCallFailed: {},
      };
    case GET_PERSONA_DETAILS_API_SUCCEED:
      return {
        getPersonaDetailsApiCallInitiated: false,
        getPersonaDetailsApiResponse: action.payload,
        getPersonaDetailsApiCallFailed: {},
      };
    case GET_PERSONA_DETAILS_API_FAILED:
      return {
        getPersonaDetailsApiCallInitiated: false,
        getPersonaDetailsApiResponse: {},
        getPersonaDetailsApiCallFailed: action.payload,
      };
    default:
      return state;
  }
};
export default personaDetailsReducers;
