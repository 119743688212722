import React, { useEffect, useState } from "react";
import "./AppsToPersonaPopup.scss";
import { appstoreIcon, close_icon, playstoreIcon } from "../../../assets";
import FallbackImage from "../../../components/appImage/FallBackImage";
import {
  EmptyMessageComponent,
  ErrorPopup,
  InputField,
  ProcessingDialog,
  SuccessPopup,
} from "../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AxiosError, AxiosResponse } from "axios";
import {
  clearCacheAndCookies,
  getAuthToken,
  getUserEmail,
} from "../../../utils/Utils";
import { ErrorResponse } from "../../../interfaces/common/ErrorResponse";
import {
  appsChecked,
  appsDeselectAll,
  appsUsage,
  appsUsageOnBlur,
  getAppsToPersonaApiFailed,
  getAppsToPersonaApiInitiated,
  getAppsToPersonaApiSucceed,
  postAppsToPersonaApiFailed,
  postAppsToPersonaApiInitiated,
  postAppsToPersonaApiSucceed,
} from "../../../stateManagement/actions/appsToPersonaActions";
import { useAuth } from "../../../routes/useAuth";
import { initiateApiCall } from "../../../api";
import { useNavigate } from "react-router-dom";

type AppToPersonaProps = {
  setEnablePopup: any;
  personaId: number;
  fetchPersonaList: any;
  enablePopup: boolean;
  personaName: string;
};

interface AppsToPersona {
  "package-name": string;
  "platform-name": string;
  "app-usage-by-persona": string;
  "is-selected": boolean;
}

const AppsToPersonaPopup = ({
  setEnablePopup,
  personaId,
  fetchPersonaList,
  enablePopup,
  personaName,
}: AppToPersonaProps) => {
  const { setAuthToken }: any = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    appsToPersonaApiCallInitiated,
    appsToPersonaApiCallResponse,
    appsToPersonaApiCallFailed,
    getAppsToPersonaApiCallInitiated,
    getAppsToPersonaApiCallResponse,
    getAppsToPersonaApiCallNoAppsResponse,
    getAppsToPersonaApiCallAppsAssignedResponse,
    getAppsToPersonaApiCallFailed,
  } = useSelector((state: any) => state.appsToPersonaReducers, shallowEqual);

  const { myAppsApiResponse, myAppsApiCallFailed } = useSelector(
    (state: any) => state.myAppsReducers,
    shallowEqual
  );
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const iconMap: { [key: string]: string } = {
    ios: appstoreIcon,
    android: playstoreIcon,
  };
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const handleCloseAPIError = () => {
    setIsErrorPopupVisible(false);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
    fetchPersonaList();
  };

  const onFieldValueChange = (
    value: string,
    packageName?: string,
    platformName?: string
  ) => {
    dispatch(
      appsUsage({
        getAppsToPersonaApiCallAppsAssignedResponse,
        packageName,
        platformName,
        value,
      })
    );
  };

  const onFieldBlur = (
    e: { target: { value: string } },
    packageName?: string,
    platformName?: string
  ) => {
    const value = e.target.value;
    dispatch(
      appsUsageOnBlur({
        getAppsToPersonaApiCallAppsAssignedResponse,
        packageName,
        platformName,
        value,
      })
    );
  };

  const handleChecked = (
    checked: boolean,
    index: number,
    packageName: string,
    platformName: string
  ) => {
    dispatch(
      appsChecked({
        checked,
        getAppsToPersonaApiCallAppsAssignedResponse,
        packageName,
        platformName,
      })
    );
  };

  const handleAssign = async () => {
    try {
      dispatch(postAppsToPersonaApiInitiated());
      const response: AxiosResponse<any> = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}` + `/persona/assign-apps`,
        {
          "persona-id": personaId,
          "mapped-by-email": getUserEmail(),
          apps: getAppsToPersonaApiCallAppsAssignedResponse,
        },
        {
          "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );
      if (response.data.code === 200) {
        dispatch(postAppsToPersonaApiSucceed(response.data));
        setIsSuccessPopupVisible(true);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            postAppsToPersonaApiFailed(
              axiosError.response.data as ErrorResponse
            )
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            postAppsToPersonaApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  const handleDeselectApps = () => {
    dispatch(appsDeselectAll({ getAppsToPersonaApiCallAppsAssignedResponse }));
  };

  const getAppsToPersona = async () => {
    dispatch(getAppsToPersonaApiInitiated());
    try {
      const response: AxiosResponse<any> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/persona/persona-apps`,
        null,
        {
          "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          User: getUserEmail(),
        },
        {
          "persona-id": personaId,
        }
      );

      if (response.data.code === 200) {
        dispatch(
          getAppsToPersonaApiSucceed([
            response.data.result["persona-apps"],
            myAppsApiResponse?.filter(
              (item: { [x: string]: string }) =>
                item["app-approval-status"] === "full_registered"
            ) || [],
          ])
        );
      } else if (response.data.code === 404) {
        dispatch(
          getAppsToPersonaApiSucceed([
            [],
            myAppsApiResponse?.filter(
              (item: { [x: string]: string }) =>
                item["app-approval-status"] === "full_registered"
            ) || [],
          ])
        );
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            getAppsToPersonaApiFailed(axiosError.response.data as ErrorResponse)
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            getAppsToPersonaApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  useEffect(() => {
    getAppsToPersona();
  }, []);

  return (
    <>
      {getAppsToPersonaApiCallInitiated || appsToPersonaApiCallInitiated ? (
        <ProcessingDialog message="Loading..." />
      ) : (
        <div className="assign-apps-popup-overlay">
          <div className="assign-apps-popup">
            <div className="assign-apps-popup-header">
              <div className="assign-apps-heading">
                Assign apps to {personaName}
              </div>
              <img
                src={close_icon}
                className="close-icon assign-apps-close-icon"
                onClick={() => setEnablePopup(false)}
              ></img>
            </div>
            <div className="assign-apps-popup-content">
              {getAppsToPersonaApiCallAppsAssignedResponse &&
                getAppsToPersonaApiCallAppsAssignedResponse.length > 0 && (
                  <div className="assign-apps-count-text">
                    <div>
                      {
                        getAppsToPersonaApiCallAppsAssignedResponse?.filter(
                          (item: any) => item["is-selected"] === true
                        ).length
                      }{" "}
                      Apps selected out of{" "}
                      {
                        myAppsApiResponse?.filter(
                          (item: any) =>
                            item["app-approval-status"] === "full_registered"
                        ).length
                      }
                    </div>
                    <div
                      className="assign-apps-unselect-text"
                      onClick={handleDeselectApps}
                    >
                      Unselect all
                    </div>
                  </div>
                )}
            </div>
            <div className="assign-apps-input-container">
              {getAppsToPersonaApiCallAppsAssignedResponse &&
              getAppsToPersonaApiCallAppsAssignedResponse.length > 0
                ? getAppsToPersonaApiCallAppsAssignedResponse.map(
                    (item: any, index: number) => (
                      <>
                        <div
                          key={index}
                          data-testid={`assign-apps-${index}`}
                          className="assign-apps-input-subcontainer"
                        >
                          <InputField
                            title=""
                            type="checkbox"
                            styleType=""
                            checked={
                              getAppsToPersonaApiCallAppsAssignedResponse[
                                index
                              ]?.["is-selected"]
                            }
                            onChange={(checked: any) =>
                              handleChecked(
                                checked,
                                index,
                                item["package-name"],
                                item["platform-name"]
                              )
                            }
                          />
                          <div className="assign-apps-icon-description">
                            <div className="assign-apps-icon-logo">
                              <FallbackImage
                                src={item["store-app-logo"] || ""}
                                fallbackSrc={item["app-logo"] || ""}
                                alt={`${item["app-name"]} logo`}
                              />
                            </div>
                            <div className="assign-apps-about-app">
                              <div className="assign-apps-app-name">
                                {item["app-name"]}
                              </div>
                              <div>
                                <img
                                  src={iconMap[item["platform-name"]]}
                                  alt="Store Icon"
                                  className="assign-apps-store-icon"
                                />
                              </div>
                              <div className="assign-apps-app-description">
                                {item["store-app-description"]}
                              </div>
                            </div>
                          </div>
                          <div className="assign-apps-input-field-container">
                            <InputField
                              title="Provide description for App usage"
                              placeholder="Description"
                              styleType=""
                              className={`assign-apps-app-usage ${
                                getAppsToPersonaApiCallAppsAssignedResponse?.[
                                  index
                                ]?.["is-selected"]
                                  ? ""
                                  : "fields-disabled"
                              }`}
                              type="textArea"
                              value={
                                getAppsToPersonaApiCallAppsAssignedResponse?.[
                                  index
                                ]["app-usage-by-persona"]
                              }
                              cols={35}
                              onChange={(value: string) => {
                                onFieldValueChange(
                                  value,
                                  item["package-name"],
                                  item["platform-name"]
                                );
                              }}
                              onBlur={(e: { target: { value: string } }) =>
                                onFieldBlur(
                                  e,
                                  item["package-name"],
                                  item["platform-name"]
                                )
                              }
                              disabled={
                                getAppsToPersonaApiCallAppsAssignedResponse?.[
                                  index
                                ]?.["is-selected"]
                                  ? false
                                  : true
                              }
                            />
                          </div>
                        </div>
                        <div className="assign-apps-input-section-border"></div>
                      </>
                    )
                  )
                : !isSuccessPopupVisible && (
                    <EmptyMessageComponent
                      message="Sorry, you have no apps registered.
      Please register your app"
                    />
                  )}
            </div>
            {getAppsToPersonaApiCallAppsAssignedResponse &&
              getAppsToPersonaApiCallAppsAssignedResponse.length > 0 && (
                <div className="assign-apps-popup-footer">
                  <button
                    className="cancel"
                    onClick={() => setEnablePopup(false)}
                  >
                    Cancel
                  </button>
                  <button onClick={handleAssign} className="save">
                    Assign
                  </button>
                </div>
              )}
          </div>
          {isSuccessPopupVisible && (
            <SuccessPopup
              boldMessage=""
              message={appsToPersonaApiCallResponse.message}
              onClose={handleCloseSuccessPopup}
            />
          )}
          {isErrorPopupVisible && (
            <ErrorPopup
              boldMessage={appsToPersonaApiCallFailed?.message}
              message={
                appsToPersonaApiCallFailed?.error
                  ? appsToPersonaApiCallFailed?.error
                  : getAppsToPersonaApiCallFailed?.error
              }
              onClose={handleCloseAPIError}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AppsToPersonaPopup;
