import React, { useState } from "react";
import "./AppCardComponentNew.scss";
import { appleLogo, playstoreLogo, fullStar, editIcon, moreButtonDots, remove_icon_blue } from "../../assets";
import { AppData } from "../../screens/dashboardScreen/DashboardScreen";
import { useNavigate } from "react-router-dom";
import FallbackImage from "../appImage/FallBackImage";

type Props = {
  index: number;
  appData: any;
  sourceScreen: string;
  deleteMyApps?: (appId: string, appSecret: string, packageName: string, platformName: string) => void;
};

const AppCardComponent = ({ appData, index, sourceScreen, deleteMyApps }: Props) => {
  const iconMap: { [key: string]: string } = {
    ios: appleLogo,
    android: playstoreLogo,
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/registerApp", {
      state: {
        packageName: appData["package-name"],
        platformName: appData["platform-name"],
        from: "dashboard",
        action: "register",
      },
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleEditClick = () => {
    editApp();
    toggleDropdown();
  };

  const handleDeleteClick = () => {
    toggleDropdown();
    deleteMyApps && deleteMyApps(appData["APP-ID"], appData["APP-SECRET"], appData["package-name"], appData["platform-name"]);
  };

  const editApp = () => {
    navigate("/registerApp", {
      state: {
        packageName: appData["package-name"],
        platformName: appData["platform-name"],
        from: "myApps",
        action: "update",
      },
    });
  };

  const getAccountInfo = () => {
    const accountHolder = appData["app-account-holder"];
    const accountProvider = appData["account-provider"];

    if (sourceScreen === "dashboard") {
      return accountHolder || <span>&nbsp;</span>;
    } else if (sourceScreen === "analytics") {
      return accountProvider;
    } else {
      return accountHolder || <span>&nbsp;</span>;
    }
  };

  return (
    <div className="app-card">
      <div className="app-card-content" onClick={() =>
        navigate("/appDetails", {
          state: {
            packageName: appData["package-name"],
            platformName: appData["platform-name"],
          },
        })
      }>
        <div className="app-card-icon" key={index}>
          <FallbackImage
            src={appData["store-app-logo"]}
            fallbackSrc={appData["app-logo"]}
            alt={`${appData["store-app-name"]} logo`}
          />
        </div>
        <div className="app-card-details">
          <div>
            <div className="app-card-header">
              <span className="dashboard-app-name">
                {appData["store-app-name"]}
              </span>
              <img
                src={iconMap[appData["platform-name"]]}
                alt="Store Icon"
                className="store-icon"
              />
              {sourceScreen === "myapps-registered" ? <img
                src={editIcon}
                alt="Store Icon"
                className="store-icon"
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditClick();
                }}
              /> : sourceScreen === "myapps-rejected" && <div className="dropdown-container">
                <img
                  src={moreButtonDots}
                  alt="Options Icon"
                  className="store-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleDropdown();
                  }}
                />
                {isDropdownOpen && (
                  <div className="dropdown-menu">
                    <div className="dropdown-item" onClick={(event) => {
                      event.stopPropagation();
                      handleEditClick();
                    }}>
                      <img src={editIcon} alt="Edit Icon" className="dropdown-icon" /> Edit
                    </div>
                    <div className="dropdown-item" onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteClick();
                    }}>
                      <img
                        src={remove_icon_blue}
                        alt="Delete Icon"
                        className="dropdown-icon"
                      />{" "}
                      Delete
                    </div>
                  </div>
                )}
              </div>
              }
            </div>
            <div className="app-card-text">
              {getAccountInfo()}
            </div>
          </div>
          <div className="app-card-footer">
            <div className="app-card-footer-details">
              {appData["avg-rating"] > 0 ? (
                <div className="app-rating">
                  <span>{appData["avg-rating"]}</span>
                  <img src={fullStar} alt="Star Icon" className="star-icon" />
                </div>
              ) : (
                <div className="app-rating" style={{ visibility: "hidden" }}>
                  <span>{appData["avg-rating"]}</span>
                  <img src={fullStar} alt="Star Icon" className="star-icon" />
                </div>
              )}

              {sourceScreen === "dashboard"
                ? appData["app-status"] && (
                  <div
                    className={`app-status-text ${appData["app-status"]["status"].toLocaleLowerCase() ===
                      "in production" ||
                      appData["app-status"]["status"].toLocaleLowerCase() ===
                      "ready for distribution"
                      ? "text-dark"
                      : ""
                      }`}
                  >
                    {appData["app-status"]["status"]}
                  </div>
                )
                : appData["app-status"] && (
                  <div className="app-status-text text-dark">
                    {typeof appData["app-status"] === "string"
                      ? appData["app-status"]
                      : appData["app-status"]["status"]}
                  </div>
                )}
            </div>
            {!appData["platform-registered"] &&
              sourceScreen === "dashboard" &&
              (appData["app-status"]["status"].toLocaleLowerCase() ===
                "in production" ||
                appData["app-status"]["status"].toLocaleLowerCase() ===
                "ready for distribution") ? (
              <button
                className="app-register-button"
                onClick={(event) => {
                  event.stopPropagation();
                  handleButtonClick();
                }}
              >
                Register
              </button>
            ) : (
              <button
                className="app-register-button"
                onClick={handleButtonClick}
                style={{ visibility: "hidden" }}
              >
                Register
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCardComponent;
