import React, { useState } from "react";
import { InputField } from "../../../../components";
import { close_icon, remove_app_icon } from "../../../../assets";
import "./CreateTeamPopup.scss";
import { TeamMemberData } from "../MyTeam";

type CreateTeamPopupProps = {
  setEnablePopup: any;
  onSaveTeam: (members: { name: string, email: string, errMsgForName: string, errMsgForEmail: string, memberId: number, memberRoleId: number }[]) => void;
  initialValues?: { member: TeamMemberData; isEdit: boolean; isDelete: boolean } | null;
  onDeleteTeam: (member: TeamMemberData) => void;
};

interface ApiResponse {
  code: number;
  message: string;
  result: string;
  error: string;
}

const validate = (members: any, hasChanged: boolean, isEdit: boolean) => {
  const membersWithValidation = members.map((member: any) => {
    if (!member.name) {
      member.errMsgForName = "Name is required";
    } else if (isEdit && !hasChanged) {
      member.errMsgForName = "Edit name to save";
    } else {
      member.errMsgForName = "";
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!member.email) {
      member.errMsgForEmail = "Email is required";
    } else if (!emailRegex.test(member.email)) {
      member.errMsgForEmail = "Email is invalid";
    } else {
      member.errMsgForEmail = "";
    }

    return member;
  });

  return membersWithValidation;
};

const CreateTeamPopup = ({ setEnablePopup, onSaveTeam, initialValues, onDeleteTeam }: CreateTeamPopupProps) => {
  const [teamMembers, setTeamMembers] = useState([
    { name: initialValues?.member["member-name"] || "", email: initialValues?.member["member-email-id"] || "", errMsgForName: "", errMsgForEmail: "", memberId: initialValues?.member["member-id"] || -1, memberRoleId: initialValues?.member["member-role"]["member-role-id"] || -1 },
  ]);
  const [hasChanged, setHasChanged] = useState(false);

  const onMemberNameChange = (name: string, index: number) => {
    if (name !== initialValues?.member["member-name"]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
    const members = [...teamMembers];
    members[index].name = name;
    setTeamMembers(members);
  };
  const onMemberEmailChange = (email: string, index: number) => {
    const members = [...teamMembers];
    members[index].email = email;
    setTeamMembers(members);
  };

  const onRemoveMember = (index: number) => {
    const members = [...teamMembers];
    members.splice(index, 1);
    setTeamMembers(members);
  };

  const onSave = async (teamMembers: any) => {
    const members = validate(teamMembers, hasChanged, initialValues?.isEdit || false);
    setTeamMembers(members);

    const isValid = members.every(
      (member: any) => member.errMsgForName === "" && member.errMsgForEmail === ""
    );

    if (isValid) {
      onSaveTeam(members)
    }
  };

  return (
    <div className="create-team-popup-overlay">
      <div className="create-team-popup">
        <div className="create-team-popup-header">
          <div className="create-team-heading">{initialValues?.isEdit
            ? "Edit Member"
            : initialValues?.isDelete
              ? "Delete Member"
              : "Add Members"}</div>
          <img
            src={close_icon}
            className="close-icon"
            onClick={() => setEnablePopup(false)}
          ></img>
        </div>
        <div className="create-team-popup-content">
          {teamMembers.map((member, index) => (
            <div key={index} className="create-team-input-container">
              <div className="input-field-container">
                <InputField
                  title="Member Name"
                  placeholder="Enter Name"
                  styleType="input-field"
                  value={member.name}
                  disabled={initialValues?.isDelete}
                  onChange={(value: string) => {
                    onMemberNameChange(value, index);
                  }}
                />
                <div
                  style={{
                    visibility: member.errMsgForName ? "visible" : "hidden",
                  }}
                  className="err-msg"
                >
                  {member.errMsgForName}
                </div>
              </div>

              <div className="input-field-container">
                <InputField
                  title="Michelin Email"
                  placeholder="Enter Email"
                  styleType="input-field"
                  value={member.email}
                  disabled={initialValues?.isEdit || initialValues?.isDelete ? true : false}
                  onChange={(value: string) => {
                    onMemberEmailChange(value, index);
                  }}
                />
                <div
                  style={{
                    visibility: member.errMsgForEmail ? "visible" : "hidden",
                  }}
                  className="err-msg"
                >
                  {member.errMsgForEmail}
                </div>
              </div>
              {!initialValues && <img
                src={remove_app_icon}
                className="create-team-delete-icon"
                onClick={() => {
                  onRemoveMember(index);
                }}
              ></img>}
            </div>
          ))}
          <div className="create-team-add-member">
            {!initialValues && <button
              className="add-member"
              onClick={() => {
                setTeamMembers([
                  ...teamMembers,
                  {
                    name: "",
                    email: "",
                    errMsgForName: "",
                    errMsgForEmail: "",
                    memberId: -1,
                    memberRoleId: -1,
                  },
                ]);
              }}
            >
              + Add more member
            </button>}
          </div>
        </div>
        <div className="create-team-popup-footer">
          <button className="cancel" onClick={() => setEnablePopup(false)}>
            Cancel
          </button>
          {initialValues?.isDelete ? (<button className="save" onClick={() => onDeleteTeam(initialValues.member)}>
            Delete
          </button>) : (<button className="save" onClick={() => onSave(teamMembers)}>
            Save
          </button>)}
        </div>
      </div>
    </div>
  );
};

export default CreateTeamPopup;
