import {
  GET_PERSONA_LIST_API_INITIATED,
  GET_PERSONA_LIST_API_SUCCEED,
  GET_PERSONA_LIST_API_FAILED,
  GET_PERSONA_LIST_BY_LB_API_INITIATED,
  GET_PERSONA_LIST_BY_LB_API_SUCCEED,
  GET_PERSONA_LIST_BY_LB_API_FAILED,
} from "../constants/personaListConstants";

export const getPersonaListApiInitiated = () => ({
  type: GET_PERSONA_LIST_API_INITIATED,
});

export const getPersonaListApiSucceed = (payload: any) => (
  console.log("action", GET_PERSONA_LIST_API_SUCCEED),
  {
    type: GET_PERSONA_LIST_API_SUCCEED,
    payload: payload,
  }
);

export const getPersonaListApiFailed = (payload: any) => ({
  type: GET_PERSONA_LIST_API_FAILED,
  payload: payload,
});

export const getPersonaListByLBApiInitiated = () => ({
  type: GET_PERSONA_LIST_BY_LB_API_INITIATED,
});

export const getPersonaListByLBApiSucceed = (payload: any) => ({
  type: GET_PERSONA_LIST_BY_LB_API_SUCCEED,
  payload: payload,
});

export const getPersonaListByLBApiFailed = (payload: any) => ({
  type: GET_PERSONA_LIST_BY_LB_API_FAILED,
  payload: payload,
});
