import React from "react";

export const SummaryParagraph = () => {
  return (
    <div className="static-pages-content">
      <div>
        Michelin is committed to protecting your personal information and being
        clear and transparent about what information we collect and how we use
        it. When you use our website or applications, provided by Michelin,
        information can be collected through cookies to distinguish you from
        other users of our website or applications.
      </div>
      <div>
        This Cookies policy describe the purposes for which we and our partners
        use cookies and similar technologies and how you can manage your
        preferences regarding cookies.
      </div>
    </div>
  );
};

export const WhatIsACookie = () => {
  return (
    <div className="static-pages-content">
      <div>
        A cookie is a text file or a software element which records information
        relating to the navigation of your terminal on the Internet. It is under
        the control of your browser, and sometimes carries a unique and random
        number.
      </div>
      <div>
        When Michelin mentions &quot;cookies&quot;, these may be cookies or
        other similar technologies which are based on access to the device that
        you use (computer, tablet, smartphone) when visiting our websites. Third
        parties such as our partners and service providers may use cookies and
        similar technologies in connection with our Services.
      </div>
      <div>
        A cookie allows Michelin or its partners, as issuers of cookies and
        during its period of validity, to recognise your device each time it
        accesses digital content containing cookies from the same issuer.
      </div>
    </div>
  );
};

export const WhyUsingCookies = () => {
  return (
    <div className="static-pages-content">
      <div>
        Michelin is always seeking new ways to better its Internet website(s)
        and to offer you, the internet user, a better service and experience.
        Thus, Michelin and its partners uses different types of cookies as
        indicated hereafter. For some categories of cookies (for example,
        advertising cookies) your prior consent is required by regulation before
        the cookie is installed or read on your device.
      </div>
      <div>
        In any case, you have the control of these cookies. Therefore, you have
        the possibility to refuse, set up or destroy them.
      </div>
    </div>
  );
};

export const HowWeInform = () => {
  return (
    <div className="static-pages-content">
      When you first visit Michelin website or application, the Cookie
      management solution directs you to this page that provides you with all
      necessary information about the cookies used. In any case, you have
      control over these cookies. You have the possibility to refuse them, to
      configure them or to delete them.
    </div>
  );
};

export const Support = () => {
  return (
    <div className="static-pages-content">
      We have no obligation to provide support, maintenance, updates, upgrades,
      modifications or new releases of any features of Mobile Platform, except
      if specifically agreed by writing in a separate agreement. We reserve the
      right to limit, modify, or terminate any capabilities or Mobile Platform
      offered hereunder at any time.
    </div>
  );
};

export const DataProtection = () => {
  return (
    <div className="static-pages-content">
      <div className="static-pages-keyword-bold">
        We confirm that you will comply with applicable data protection laws.
        With respect to the use of your Personal Data, please read carefully our
        Privacy Policy. If when you access the Content through Mobile Platform,
        limited Logged in User \ web user Personal Data are provided to us, you
        will be the data controller and we will be the data processor. We will,
        to the extent that we are a data processor, process such Personal Data
        in accordance with these Terms, the concerned Documentation and your
        lawful instructions reasonably given by you to us from time to time. We
        will implement appropriate technical and organisational measures to
        protect such Personal Data. We will not be liable for any claim brought
        by a data subject arising from any action or omission by us, to the
        extent that such action or omission resulted from your instructions.
        Details will be provided for in the Documentation.
      </div>
      <div>
        YOU AGREE THAT WE MAY MONITOR USE OF MOBILE PLATFORM TO ENSURE QUALITY,
        IMPROVE OUR PRODUCTS AND SERVICES AND VERIFY YOUR COMPLIANCE WITH THE
        TERMS.
      </div>
    </div>
  );
};

export const Fees = () => {
  return (
    <div className="static-pages-content">
      <div>
        Our Michelin Mobile Platform and associated Content can be made
        available for free or for a consideration. If a fee is required, the
        rate, the metric, the currency and the invoicing method are set out in
        the corresponding Documentation. We may revise the fees at any time.
        However, we will provide you with at least 30 days&apos; prior notice
        before revisions become applicable to you (or a longer period of notice
        if this is required by applicable law).
      </div>
      <div>
        All fees and other charges described in an invoice shall be deemed to be
        exclusive of all sales, use, value-added taxes, income, gross-receipts
        and other taxes, as well as all duties, excises, levies, assessments and
        the like in connection with the use of Michelin Mobile Platform and
        Content, and you shall be responsible for and pay all taxes which are
        levied or based on these Terms (excluding any taxes that are assessed on
        our net income). Therefore, payment of fees under these Terms shall be
        made in full, free and clear of any deductions and set-offs and without
        deduction of any taxes now or after withheld or assessed by any
        governmental authority.
      </div>
    </div>
  );
};

export const Term = () => {
  return (
    <div className="static-pages-content">
      <div>
        You are bound by these Terms upon the date you create a User Account and
        until these Terms are terminated by you or us. You may terminate these
        Terms by stop using Michelin Mobile Platform at any time with or without
        notice. Further, if you want to terminate the Terms, you must provide us
        with prior written notice and upon termination, cease your use of the
        concerned Michelin Mobile Platform. Fees, if any, are due until the
        termination date and fees already paid will not be reimbursed.
      </div>
      <div>
        We can decide at any time to terminate these Terms and stop making any
        of our Michelin Mobile Platform available for you for any reason and
        without liability or obligation to you. In such a case, we will give you
        a prior notice of thirty (30) days. We may suspend your service access
        and your ability to use Michelin Mobile Platform if you use Michelin
        Mobile Platform, in a prohibited manner or otherwise if you do not
        comply with any provision of these Terms.
      </div>
      <div>
        Upon any termination of the Terms or discontinuation of your access to
        Michelin Mobile Platform, you will immediately stop using the Michelin
        Mobile Platform, cease all use of the Michelin Brand Features and delete
        any cached if any.
      </div>
    </div>
  );
};

export const Indemnification = () => {
  return (
    <div className="static-pages-content">
      <div>
        Unless prohibited by applicable laws, you agree to defend and indemnify
        us and our respective agents, licensors and service providers against
        any claim, suit, demand, loss, liability, damage, action, or proceeding
        relating to any allegation from a third party legal proceeding that
        result from (i) your breach of any provision of these Terms; (ii) any
        content or data routed into or used with Michelin Mobile Platform by
        you, those acting on your behalf or your end-users; (iii) negligent or
        willful misconduct of your employees, contractors, or agents; or (iv)
        any infringement of third party intellectual property rights by you.
      </div>
      <div>
        We agree to defend and indemnify you against any claim, suit, demand,
        loss, liability, damage, action or proceeding relating to any allegation
        from a third party on the grounds of infringement by the Michelin Mobile
        Platform, Michelin Trademarks or Content of any intellectual property
        right belonging to the said third party, provided that (i) you notify us
        of the allegation or action by the third party within 15 days after this
        comes to its notice; (ii) you allow us to be in sole charge of managing
        the defense and any negotiations conducted with a view to reaching a
        possible settlement with the third party, and (iii) the infringement of
        intellectual property rights is not due to your failure to comply with
        the obligations arising from these Terms. We shall compensate you for
        any damages awarded against you by a final court decision or by a final
        settlement agreement approved by us and based solely on the
        demonstration of such an infringement.
      </div>
    </div>
  );
};

export const LimitationLiability = () => {
  return (
    <div className="static-pages-content">
      <div>
        Except as expressly set out in these terms, neither us or our licensors
        or suppliers make any specific promises about Michelin Mobile Platform.
        We provide michelin Mobile Platform “as is”, without any express,
        implied, or statutory warranties of title, merchantability, fitness for
        a particular purpose, non-infringement, or any other type of warranty or
        guarantee. No data, documentation or any other information provided by
        us or obtained by you from or through Michelin Mobile Platform creates
        or implies any warranty from us to you, to the extent permitted by the
        law. In particular, we do not guarantee that the Michelin Mobile
        Platform will be available at any particular time or location, or will
        function in an uninterrupted manner or be secure; that we will correct
        any defects or errors in the Michelin Mobile Platform ; or that the
        Michelin Mobile Platform are free of viruses or other harmful code. Use
        of data and content you access or download through the Michelin Mobile
        Platform is done at your own risk.
      </div>
    </div>
  );
};

export const Confidentiality = () => {
  return (
    <div className="static-pages-content">
      <div>
        You agree to hold all Confidential Information in strict confidence, not
        to disclose, distribute or disseminate the Confidential Information or
        information derived therefrom in any way to any third party and not to
        use the Confidential Information for your own benefit or the benefit of
        others, or for any purpose except in connection with your rights and
        obligations under these Terms. You agree to ensure the protection of all
        Confidential Information from unauthorized disclosure and in any event,
        to take precautions at least as great as those taken to protect your own
        information of a similar nature, but in no event less than the
        equivalent of a reasonable degree of care. Upon our request, you will
        return or destroy (and certify such destruction) all materials, in any
        medium, that contain, embody, reflect or reference all or any part of
        any Confidential Information. You acknowledge that breach of this
        section may result in irreparable harm to us, for which money damages
        may be an insufficient remedy, and therefore we will be entitled to seek
        injunctive relief to enforce the provisions of this section without
        requirement of posting a bond or providing special evidence.
      </div>
      <div>
        These confidentiality obligations will not apply to Confidential
        Information to the extent that you can prove that such Confidential
        Information: (a) is or has become publicly known (other than through
        unauthorized disclosure); (b) is disclosed to you without obligation of
        confidentiality from a third party who has the right to disclose such
        information without restriction and not indirectly from us; or (c) is
        independently developed by you without any use of or reference to our
        Confidential Information and without violating our proprietary rights.
        In addition, you may disclose our Confidential Information if required
        by court order, governmental demand, or other compulsory legal process,
        provided that, if legally permitted to do so, you first notify us in
        writing in advance to give us an opportunity to seek a protective order
        or other relief. We have the right to disclose portions of these Terms
        to our regulators in the exercise of their statutory authority or to our
        independent auditors or to enforce our rights under these Terms.
      </div>
    </div>
  );
};

export const Audit = () => {
  return (
    <div className="static-pages-content">
      <div>
        With prior, written notice of thirty (30) days sent to you, we will be
        able to check that you are complying with all their contractual
        obligations stipulated in these Terms. These audits will not take place
        more than once a year and will be carried out by an independent auditing
        company appointed by us. Any audit will be carried out during working
        hours. As soon as possible, you will provide the auditors with
        information and support required for carrying out each audit, by giving
        them access to your Applications, systems, databases, products, books,
        registers and files. Auditors will be bound by a confidentiality
        obligation that will cover all information received during the audit,
        provided that such information and any report written by the auditors
        can be used in court for us to enforce our rights.
      </div>
    </div>
  );
};

export const PrivacyPolicySummary = () => {
  return (
    <div className="static-pages-content">
      <div>
        Michelin is committed to protecting your personal information and being
        clear and transparent about what information we collect and how we will
        use it. This policy explains how any personal information we collect
        when you use our website or digital services, or that you provide to us,
        will be processed by us.
      </div>
      <div>
        Please read the following policy carefully to understand our views and
        practices regarding your personal information and how we will treat it.
      </div>
    </div>
  );
};

export const PrivacyOtherPolicies = () => {
  return (
    <div className="static-pages-content">
      While this Privacy Policy contains all the information you need on how we
      collect and use your personal information on our websites, there is
      information in some other places which we encourage you to read.
    </div>
  );
};

export const PrivacyWhereWeStore = () => {
  return (
    <div className="static-pages-content">
      <div>
        All information you provide to us is stored on secure servers within
        West Europe.
      </div>
      <div>
        While we are committed to keeping your personal information secure, the
        transmission of information online via the internet is not completely
        secure. We, together with our service providers will do our best to
        protect your personal information but information provided is at your
        sole risk.
      </div>
    </div>
  );
};

export const InternationalTransfers = () => {
  return (
    <div className="static-pages-content">
      <div>
        Michelin is an international group with databases in the various
        countries in which it operates. Michelin may transfer your personal data
        within the group to one of its databases or to its external partners
        located outside of your home country.
      </div>
      <div>
        As levels of data protection vary worldwide, we do not transmit your
        personal data to Michelin group companies or to third-party companies
        outside of the European Union unless these companies offer the same or
        similar level of personal data protection as Michelin.
      </div>
      <div>
        For transfers within Michelin, the company has adopted internal rules
        governing the transfer of personal data from the European Union or
        European Economic Area.
      </div>
      <div>
        Transfers of data outside the group are governed either by the BCR of
        the subcontractor or by contracts containing the clauses of the European
        Commission for suppliers established outside the EU to guarantee a
        similar level of protection as that of your home country.
      </div>
    </div>
  );
};

export const HowLongKept = () => {
  return (
    <div className="static-pages-content">
      <div>
        As a rule, we will not hold your personal information for any purpose or
        purposes for longer than is necessary to fulfil the purposes we collect
        it for, including for the purposes of satisfying any legal, accounting,
        or reporting requirements. For more specific retention periods, please
        see section 4 above.
      </div>
      <div>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </div>
      <div>
        In some circumstances you can ask us to delete your data: see your right
        to be deleted below for further information.
      </div>
      <div>
        In some circumstances, we may anonymize your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes in which case we may use this information indefinitely without
        further notice to you.
      </div>
    </div>
  );
};

export const YourRightsUnder = () => {
  return (
    <div className="static-pages-content">
      <div>
        Data protection legislation provides you with certain rights when it
        comes to the processing of your information. These are the right to:
      </div>
      <div className="static-pages-keyword-bold">The right to be informed</div>
      <div>
        You have the right to be informed about how we collect and process your
        personal information, including who we are, how we use your personal
        information and your rights in relation to your personal information. We
        have gathered all the information you need in this privacy notice.
      </div>
      <div className="static-pages-keyword-bold">
        The right of access your information
      </div>
      <div>
        You have the right to access the personal information we have on you. We
        can confirm whether or not your information is being processed and
        provide you with access to your personal information.
      </div>
      <div className="static-pages-keyword-bold">
        The right to correct your information
      </div>
      <div>
        If your personal information is inaccurate or incomplete, you are
        entitled to have that information corrected and updated. If we have
        disclosed your information to any third parties, where possible, we will
        inform them of any updates you provide to us. You can update the
        personal information we hold about you by contacting us using any of the
        contact methods details in this Privacy Policy.
      </div>
      <div className="static-pages-keyword-bold">
        The right to delete your information
      </div>
      <div>
        You have the right to request the deletion or removal of your personal
        information where there is no reason for us to continue to process it.
        The circumstances in which you can request the right to be forgotten
        includes, where the information is no longer necessary in relation to
        the purpose for which it was originally collected or processed and where
        you have withdrawn your consent to processing.
      </div>
      <div>
        There are some limited circumstances where the right to be forgotten
        does not apply - if you want to know, just ask.
      </div>
      <div className="static-pages-keyword-bold">
        The right to restrict processing
      </div>
      <div>
        You also have the right to &apos;block&apos; or suppress the processing
        of your personal information. If you request us to suppress your
        personal information, we will stop any further processing of it.
      </div>
      <div className="static-pages-keyword-bold">
        The right to transfer information
      </div>
      <div>
        If you want to move or transfer your personal information to a different
        service provider or copy your information for your own purposes, you
        have the right to have your information transferred to another person.
      </div>
      <div className="static-pages-keyword-bold">The right to object</div>
      <div>
        You have the right to ask us not to process your personal information
        for marketing purposes. We will usually inform you (before collecting
        your data) if we intend to use your information for such purposes or if
        we intend to disclose your information to any third party for such
        purposes. You can exercise your right to prevent such processing by
        checking certain boxes on the forms we use to collect your information.
      </div>
      <div className="static-pages-keyword-bold">
        The right to define the outcome of your personal information after your
        death
      </div>
      <div>
        You have the right to give us instructions on what we wish that do with
        your personal information after your death. We will register it and if
        we heard that such an event occurred, we will comply with your
        instructions.
      </div>
      <div className="static-pages-keyword-bold">
        For further information on your rights
      </div>
      <div>
        You can find further information on your data protection rights from the
        data protection authorities of the country to which you depend.
      </div>
    </div>
  );
};

export const PrivacyDataSecurity = () => {
  return (
    <div className="static-pages-content">
      <div>
        Michelin has implemented measures to protect the privacy, security and
        integrity of your personal data. Access to personal data is restricted
        to those employees and service providers who need to know this
        information and who have been trained to comply with rules on
        confidentiality.
      </div>
      <div>
        Michelin ensures that your personal data are not altered, damaged or
        accessed by unauthorized third parties.
      </div>
    </div>
  );
};
