import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  alertIcon,
  appIconUploadErrorImage,
  checkMarkLarge,
  crossIcon,
  uploadIcon,
} from "../../../../assets";
import "../CreatePersona.scss";
import InputField from "../../../../components/inputField/InputField";

const CreatePersonaStepThree = ({
  selectedLB,
  selectedBusinessCategory,
  onFieldValueChange,
  onFieldBlur,
  createPersonaData,
  createPersonaSelectionData,
  customerType,
  selectedCustomerType,
  handleOnClick,
  newCustomerType,
  handleRemoveCustomerTypeClick,
  hideOtherCapsule,
  handleAddCustomerTypeInput,
  businessType,
  selectedBusinessType,
  hideAddCustomerTypeInput,
  error,
  inputCustomerType,
  handleCustomerTypeChange,
  handleOnAddClick,
  handleSubmit,
  handlePersonaIconChange,
  onChoosePersonaIconFile,
  inputRef,
  personaNameRef,
  nameRef,
  businessTypeRef,
  emailRef,
  aboutPersonaRef,
  needsRef,
  handleImageError,
  handleDiscard,
  personaNamesList,
  setPersonaNamesList,
}: any) => {
  const { personaListByLBApiResponse } = useSelector(
    (state: any) => state.personaListReducers,
    shallowEqual
  );

  useEffect(() => {
    if (!selectedBusinessCategory) return;

    const personaListForSelectedCategory = personaListByLBApiResponse?.filter(
      (businessCat: any) => {
        return (
          businessCat["business-category-id"] ===
          selectedBusinessCategory["business-category-id"]
        );
      }
    );

    const personaNames = personaListForSelectedCategory[0]["persona-list"]?.map(
      (persona: any) => persona["persona-name"]
    );

    setPersonaNamesList(personaNames && [...new Set(personaNames)]);
  }, [selectedBusinessCategory]);

  return (
    <li
      className={`StepProgress-item ${
        selectedLB !== "" && selectedBusinessCategory !== "" && "current"
      }`}
    >
      <div
        className={`create-persona-select-business-category ${
          selectedBusinessCategory === "" && "create-persona-label-disable"
        }`}
      >
        Enter Persona Details
      </div>
      <div
        className={`${
          selectedBusinessCategory === "" && "create-persona-section-hide"
        }`}
      >
        <div
          ref={personaNameRef}
          className="create-persona-input-margin-bottom"
        >
          <InputField
            title="Persona Name"
            placeholder="Enter persona name (E.g. Tyre Technician)"
            onChange={(value: string) => {
              onFieldValueChange(value, "persona-name");
            }}
            onBlur={(e: { target: { value: string } }) =>
              onFieldBlur(e, "persona-name")
            }
            className=""
            value={createPersonaData["persona-name"]["value"] ?? ""}
            styleType={`full-width create-persona-input-width ${
              createPersonaData["persona-name"]["validationError"] &&
              "highlight-input-validation-error"
            }`}
            validationError={
              createPersonaData["persona-name"]["validationError"]
            }
            required
          />
        </div>
        {personaNamesList && personaNamesList?.length !== 0 && (
          <div className="persona-names">
            <span className="persona-names-label">Existing Persona Names:</span>
            <span>
              {personaNamesList?.map((personaName: string, index: number) => {
                return (
                  <span key={`${personaName} ${index}`}>
                    {personaName}
                    {index < personaNamesList?.length - 1 && <span> | </span>}
                  </span>
                );
              })}
            </span>
          </div>
        )}
        <div className="create-persona-seperator-line"></div>
        <div ref={nameRef} className="grid-container">
          <div>
            <InputField
              title="Name"
              placeholder="E.g. Arunkumar"
              onChange={(value: string) => {
                onFieldValueChange(value, "person-name");
              }}
              onBlur={(e: { target: { value: string } }) =>
                onFieldBlur(e, "person-name")
              }
              className=""
              value={createPersonaData["person-name"]["value"] ?? ""}
              styleType={`full-width ${
                createPersonaData["person-name"]["validationError"] &&
                "highlight-input-validation-error"
              }`}
              validationError={
                createPersonaData["person-name"]["validationError"] ?? ""
              }
              required
            />
          </div>
          <div>
            <InputField
              title="Profile Picture"
              type="noText"
              styleType=""
              onChange={() => {
                return;
              }}
              required
            />
            <div className="upload-container flex-row">
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                accept="image/png, image/jpeg, image/jpg"
                onChange={handlePersonaIconChange}
              />
              <div className="app-icon-wrapper">
                <img
                  src={
                    createPersonaData["person-logo"].validationError
                      ? appIconUploadErrorImage
                      : createPersonaData["person-logo"].value || uploadIcon
                  }
                  alt="Upload Icon"
                  className="upload-icon"
                  onClick={onChoosePersonaIconFile}
                  onError={handleImageError}
                />
              </div>

              <p className="upload-text">
                <span>Upload Profile Picture</span>
                <span>(Profile Picture should be in .jpg or .png)</span>
                {createPersonaData["person-logo"].validationError && (
                  <span className="validation-msg-container">
                    <img
                      src={alertIcon}
                      alt="alert logo"
                      className="validation-icon"
                    />
                    <span className="validation-error">
                      {createPersonaData["person-logo"].validationError}
                    </span>
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="create-persona-input-field-container">
          <InputField
            title="Business Line"
            placeholder=""
            className="fields-disabled"
            disabled={true}
            onChange={(value: string) => {
              onFieldValueChange(value, "business-line");
            }}
            onBlur={(e: { target: { value: string } }) =>
              onFieldBlur(e, "business-line")
            }
            value={selectedLB?.[0]?.["line-of-business"] ?? ""}
            styleType="input-field"
            validationError=""
          />
        </div>
        <div className="create-persona-input-field-container">
          <InputField
            title="Business Category"
            placeholder=""
            onChange={(value: string) => {
              onFieldValueChange(value, "business-category");
            }}
            onBlur={(e: { target: { value: string } }) =>
              onFieldBlur(e, "business-category")
            }
            className="fields-disabled"
            disabled={true}
            value={
              selectedBusinessCategory["business-category-name"]
                ? selectedBusinessCategory["business-category-name"]
                : selectedBusinessCategory
            }
            styleType="input-field"
            validationError=""
          />
        </div>
        <div ref={businessTypeRef} className="grid-container">
          <div>
            <div className="create-persona-customer-business-type-label">
              Customer Type
              <sup className="red-text asterisk">*</sup>
            </div>
            <div className="create-persona-business-lines-capsule-container">
              {customerType.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={`persona-business-lines-capsule ${
                      selectedCustomerType?.[0]?.["customer-type-id"] ===
                      item["customer-type-id"]
                        ? "persona-business-line-capsule-color"
                        : ""
                    }`}
                    onClick={(e) =>
                      handleOnClick(
                        e,
                        item["customer-type-id"],
                        "customer type"
                      )
                    }
                  >
                    {item["customer-type"]}
                  </div>
                );
              })}
              {newCustomerType.length > 0 &&
                newCustomerType.map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`persona-business-category-capsule ${
                        selectedCustomerType?.[0]?.["customer-type"] ===
                        item["customer-type"]
                          ? "persona-business-line-capsule-color"
                          : ""
                      }`}
                    >
                      <span
                        onClick={(e) =>
                          handleOnClick(
                            e,
                            item["customer-type"],
                            "customer type"
                          )
                        }
                      >
                        {item["customer-type"]}
                      </span>
                      {selectedCustomerType?.[0]?.["customer-type"] ===
                        item["customer-type"] &&
                        "persona-business-line-capsule-color" && (
                          <img
                            src={crossIcon}
                            alt="close"
                            className="create-persona-new-category-remove"
                            onClick={() =>
                              handleRemoveCustomerTypeClick(
                                item["customer-type"]
                              )
                            }
                          />
                        )}
                    </div>
                  );
                })}

              <div
                className="persona-business-lines-capsule"
                onClick={() => handleAddCustomerTypeInput("Other")}
              >
                Other
              </div>
            </div>
            {createPersonaSelectionData["ct-id"].validationError !== "" &&
              createPersonaSelectionData["ct-name"].validationError !== "" && (
                <div className="validation-msg-container">
                  <img
                    src={alertIcon}
                    alt="alert logo"
                    className="validation-icon"
                  />
                  <div className="validation-error">
                    {createPersonaSelectionData["ct-id"].validationError}
                  </div>
                </div>
              )}
          </div>
          <div>
            <div className="create-persona-customer-business-type-label">
              Business Type
              <sup className="red-text asterisk">*</sup>
            </div>
            <div className="create-persona-business-lines-capsule-container">
              {businessType.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={`persona-business-lines-capsule ${
                      selectedBusinessType?.[0]?.["business-type-id"] ===
                      item["business-type-id"]
                        ? "persona-business-line-capsule-color"
                        : ""
                    }`}
                    onClick={(e) =>
                      handleOnClick(
                        e,
                        item["business-type-id"],
                        "business type"
                      )
                    }
                  >
                    {item["business-type"]}
                  </div>
                );
              })}
            </div>
            {createPersonaSelectionData["bt-id"].validationError !== "" && (
              <div className="validation-msg-container">
                <img
                  src={alertIcon}
                  alt="alert logo"
                  className="validation-icon"
                />
                <div className="validation-error">
                  {createPersonaSelectionData["bt-id"].validationError}
                </div>
              </div>
            )}
          </div>
        </div>
        {!hideAddCustomerTypeInput && (
          <div className="create-persona-add-new-category-heading">
            {selectedCustomerType.length === 0 &&
              inputCustomerType?.map((item: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <div className="input_container">
                      <div className="create-persona-input-category-div">
                        <InputField
                          title="New Customer Type"
                          placeholder="New Customer Type"
                          styleType="input-field input-new-category"
                          value={item.BusinessCategory}
                          onChange={(value: string) => {
                            handleCustomerTypeChange(
                              value,
                              "CustomerType",
                              index
                            );
                          }}
                          validationError={error}
                        />
                      </div>
                      <div>
                        {item.CustomerType && (
                          <div
                            className="create-persona-save-category"
                            onClick={(e) =>
                              handleOnAddClick(
                                e,
                                item.CustomerType,
                                "customer type"
                              )
                            }
                          >
                            <img
                              className="create-persona-check-mark-large"
                              src={checkMarkLarge}
                              alt="Close"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        )}
        <div
          ref={emailRef}
          className="grid-container create-persona-input-margin-top"
        >
          <div>
            <InputField
              title="Email Address"
              placeholder="Email Address"
              onChange={(value: string) => {
                onFieldValueChange(value, "contact-email");
              }}
              onBlur={(e: { target: { value: string } }) =>
                onFieldBlur(e, "contact-email")
              }
              className=""
              value={createPersonaData["contact-email"]["value"] ?? ""}
              styleType={`full-width ${
                createPersonaData["contact-email"]["validationError"] &&
                "highlight-input-validation-error"
              }`}
              validationError={
                createPersonaData["contact-email"]["validationError"]
              }
              required
            />
          </div>
          <div>
            <InputField
              title="Location"
              placeholder="Location"
              onChange={(value: string) => {
                onFieldValueChange(value, "location");
              }}
              onBlur={(e: { target: { value: string } }) =>
                onFieldBlur(e, "location")
              }
              className=""
              value={createPersonaData["location"]["value"] ?? ""}
              styleType={`full-width ${
                createPersonaData["location"]["validationError"] &&
                "highlight-input-validation-error"
              }`}
              validationError={createPersonaData["location"]["validationError"]}
              required
            />
          </div>
        </div>
        <div ref={aboutPersonaRef}>
          <InputField
            title="About Persona"
            placeholder="About Persona"
            styleType=""
            className={`create-persona-textarea ${
              createPersonaData["about-persona"]["validationError"] &&
              "highlight-input-validation-error"
            }`}
            value={createPersonaData["about-persona"]["value"] ?? ""}
            type="textArea"
            onChange={(value: string) => {
              onFieldValueChange(value, "about-persona");
            }}
            onBlur={(e: { target: { value: string } }) =>
              onFieldBlur(e, "about-persona")
            }
            validationError={
              createPersonaData["about-persona"]["validationError"]
            }
            required
          />
        </div>
        <div ref={needsRef}>
          <InputField
            title="Needs and Goals"
            placeholder="Needs and Goals"
            styleType=""
            className={`create-persona-textarea ${
              createPersonaData["goals"]["validationError"] &&
              "highlight-input-validation-error"
            }`}
            value={createPersonaData["goals"]["value"] ?? ""}
            type="textArea"
            onChange={(value: string) => {
              onFieldValueChange(value, "goals");
            }}
            onBlur={(e: { target: { value: string } }) =>
              onFieldBlur(e, "goals")
            }
            validationError={createPersonaData["goals"]["validationError"]}
            required
          />
        </div>
        <div className="create-persona-buttons">
          <button
            className="create-persona-discard-button"
            onClick={handleDiscard}
          >
            Discard
          </button>
          <button
            className="create-persona-submit-button"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </li>
  );
};

export default CreatePersonaStepThree;
