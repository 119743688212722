import React, { useEffect } from "react";
import "./StaticPages.scss";
import "./Footer.scss";
import { useLocation } from "react-router-dom";
import {
  GeneralProvisions,
  TermsOfUseSummary,
  UserLogin,
  YourRepresentations,
} from "./StaticPagesParagraphLinkContent";
import {
  Audit,
  Confidentiality,
  DataProtection,
  Fees,
  Indemnification,
  LimitationLiability,
  Support,
  Term,
} from "./StaticPagesParagraphContent";
import {
  Definitions,
  IntellectualProperty,
} from "./StaticPagesParagraphBulletsContent";

const TermsAndConditions = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const paragraphContent = [
    {
      title: "1. USER LOGIN",
      component: <UserLogin />,
    },
    {
      title: "2. Your representations and warranties",
      component: <YourRepresentations />,
    },
    {
      title: "3. SUPPORT",
      component: <Support />,
    },
    {
      title: "4. DATA PROTECTION LAWS",
      component: <DataProtection />,
    },
    {
      title: "5. INTELLECTUAL PROPERTY AND LICENSE",
      component: <IntellectualProperty />,
    },
    {
      title: "6. FEES",
      component: <Fees />,
    },
    {
      title: "7. TERM & TERMINATION",
      component: <Term />,
    },
    {
      title: "8. INDEMNIFICATION",
      component: <Indemnification />,
    },
    {
      title: "9. LIMITATION OF LIABILITY",
      component: <LimitationLiability />,
    },
    {
      title: "10. CONFIDENTIALITY",
      component: <Confidentiality />,
    },
    {
      title: "11. AUDIT",
      component: <Audit />,
    },
    {
      title: "12. GENERAL PROVISIONS",
      component: <GeneralProvisions />,
    },
    {
      title: "13. DEFINITIONS",
      component: <Definitions />,
    },
  ];

  return (
    <div className="main-container">
      <div className="static-pages-heading">Terms and Conditions of Use</div>
      <div className="static-pages-container">
        <div className="static-pages-sub-container">
          <div className="static-pages-container-padding">
            <div className="static-pages-sub-heading">
              Michelin Mobile Platform - Terms of Use
            </div>
            <TermsOfUseSummary />
            {paragraphContent.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className="static-pages-points">{item.title}</div>
                  <>{item.component}</>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
