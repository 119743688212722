import React from "react";
import "./InputField.scss";
import { Tooltip } from "react-tooltip";
import { alertIcon, tooltipIcon } from "../../assets";

type Props = {
  title: string;
  placeholder?: string;
  datatTooltipContent?: string;
  dataTooltipId?: string;
  className?: string;
  styleType: string;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  onChange: ((value: string) => void) | ((isChecked: boolean) => void) | any;
  onBlur?: any;
  value?: string;
  rows?: number;
  cols?: number;
  checked?: boolean;
  validationError?: string;
  children?: React.ReactNode;
  textAreaBackground?: string;
};

const textAreaBackgrounds: any = {
  default: "text-area-background",
  white: "text-area-background-white",
};

const InputField = ({
  title,
  placeholder,
  datatTooltipContent,
  dataTooltipId,
  className,
  styleType = "",
  disabled,
  required = false,
  type = "input",
  onChange,
  onBlur,
  value,
  rows,
  cols,
  checked,
  validationError,
  children,
  textAreaBackground = "default",
}: Props) => {
  return (
    <>
      {type !== "checkbox" && (
        <div className="title-field">
          <div>
            <span className={`${disabled ? "title-field-disabled-field" : ""}`}>
              {title}
            </span>
            {required && <sup className="red-text asterisk">*</sup>}
          </div>
          {required && dataTooltipId ? (
            <div className="div-tooltip-icon">
              <img
                src={tooltipIcon}
                data-tooltip-content={datatTooltipContent}
                data-tooltip-id={dataTooltipId}
              />
              <Tooltip id={dataTooltipId} />
            </div>
          ) : (
            <></>
          )}
          {!required && type !== "noText" && dataTooltipId ? (
            <div className="div-tooltip-icon-not-mandatory-fields">
              <img
                src={tooltipIcon}
                data-tooltip-content={datatTooltipContent}
                data-tooltip-id={dataTooltipId}
              />
              <Tooltip id={dataTooltipId} />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {type === "textArea" ? (
        <textarea
          placeholder={placeholder}
          className={`textarea-size ${className} ${textAreaBackgrounds[textAreaBackground]}`}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          rows={rows}
          cols={cols}
          disabled={disabled}
          maxLength={500}
        />
      ) : type === "checkbox" ? (
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      ) : type === "noText" ? (
        <> {children}</>
      ) : (
        <input
          type="text"
          className={`${className} ${styleType}`}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
      )}
      {validationError && (
        <div className="validation-msg-container">
          <img src={alertIcon} alt="alert logo" className="validation-icon" />
          <div className="validation-error">{validationError}</div>
        </div>
      )}
    </>
  );
};

export default InputField;
