import React, { useEffect, useState } from "react";
import { initiateApiCall } from "../../api";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  deleteMyAppsApiSucceed,
  deleteMyAppsApiInitiated,
  getMyAppsApiFailed,
  deleteMyAppsApiFailed,
  getMyAppsApiInitiated,
  getMyAppsApiSucceed,
} from "../../stateManagement/actions/myAppsActions";
import { AxiosError, AxiosResponse } from "axios";
import "./MyAppScreen.scss";
import {
  Card,
  ErrorPopup,
  ProcessingDialog,
  AppDetailsCard,
  SuccessPopup,
  AppCardComponent,
} from "../../components";
import {
  clearCacheAndCookies,
  getAuthToken,
  getUserEmail,
  updateAppCache,
} from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import EmptyMessageComponent from "../../components/emptyMessageComponent/EmptyMessageComponent";
import { useAuth } from "../../routes/useAuth";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";
interface ApiResponse {
  code: number;
  message: string;
  result: ResultData;
  error: string;
}

interface ResultData {
  "my-apps": AppData[];
  page: number;
  limit: number;
  "total-pages": number;
  "total-results": number;
}

interface AppData {
  "package-name": string;
  "platform-name": string;
  "app-name": string;
  "APP-ID": string;
  "APP-SECRET": string;
  "app-description": string;
  "app-logo": string;
  "app-account-holder": string | null;
  "platform-registered": boolean;
  "app-last-published-date": string | null;
  "avg-rating": number;
  "no-of-reviews": number;
  "no-of-downloads": number;
  "store-app-logo": string;
  "store-app-description": string;
  "app-status": {
    "version-string": string;
    status: string;
    "app-version-state": string;
    reason: string | null;
  };
  "app-approval-status": string;
}

const MyAppsView = () => {
  const { setAuthToken }: any = useAuth();
  const {
    myAppsApiCallInitiated,
    myAppsApiResponse,
    myAppsApiCallFailed,
    deleteMyAppsApiCallInitiated,
    deleteMyAppsApiCallFailed,
    deleteMyAppsSuccessMessage,
  } = useSelector((state: any) => state.myAppsReducers, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [showEmptyComponent, setShowEmptyComponent] = useState(false);

  const handleCloseError = (value: string) => {
    setIsErrorPopupVisible(false);
    if (value == "my apps") {
      setShowEmptyComponent(true);
    } else {
      setShowEmptyComponent(false);
      fetchMyApps();
    }
  };

  //TODO: This API is not final. Header parameter USER is temporary for now this will be removed once we have the authentication in place.
  const fetchMyApps = async () => {
    dispatch(getMyAppsApiInitiated());
    try {
      const response: AxiosResponse<ApiResponse> = await initiateApiCall(
        "get",
        `${process.env.REACT_APP_BASE_URL}/app/find/my-apps`,
        null,
        {
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          User: getUserEmail(),
        }
      );

      if (response.data.code === 200) {
        dispatch(getMyAppsApiSucceed(response.data));
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            getMyAppsApiFailed(axiosError.response.data as ErrorResponse)
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            getMyAppsApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  const deleteMyApps = async (
    appId: string,
    appSecret: string,
    packageName: string,
    platformName: string
  ) => {
    try {
      dispatch(deleteMyAppsApiInitiated());
      const response: any = await initiateApiCall(
        "delete",
        `${process.env.REACT_APP_BASE_URL}` + `/app/remove-rejected-app`,
        null,
        {
          "App-Token": process.env.REACT_APP_REGISTRATION_TOKEN,
          User: getUserEmail(),
          Authorization: getAuthToken(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
          "MBL-APP-ID": appId,
          "MBL-APP-SECRET": appSecret,
        }
      );
      if (response.data.code === 204) {
        dispatch(deleteMyAppsApiSucceed([myAppsApiResponse, response.data]));
        setIsSuccessPopupVisible(true);
        updateAppCache(packageName, platformName, false);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          dispatch(
            deleteMyAppsApiFailed(axiosError.response.data as ErrorResponse)
          );
          setIsErrorPopupVisible(true);
        } else {
          dispatch(
            deleteMyAppsApiFailed({
              code: 500,
              message: "Internal Server Error",
              result: null,
              error: "Internal Server Error",
            })
          );
          setIsErrorPopupVisible(true);
        }
      }
    }
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
  };

  const getRegisteredApps = () => {
    const appList = myAppsApiResponse;
    return appList?.filter(
      (app: AppData) => app["app-approval-status"] === "full_registered"
    );
  };

  const getSubmittedApps = () => {
    const appList = myAppsApiResponse;
    return appList?.filter(
      (app: AppData) => app["app-approval-status"] === "soft_registered"
    );
  };

  const getRejectedApps = () => {
    const appList = myAppsApiResponse;
    return appList?.filter(
      (app: AppData) => app["app-approval-status"] === "rejected"
    );
  };

  useEffect(() => {
    fetchMyApps();
  }, []);

  return (
    <>
      {myAppsApiCallInitiated || deleteMyAppsApiCallInitiated ? (
        <ProcessingDialog message="Loading..." />
      ) : myAppsApiCallInitiated === false && myAppsApiResponse.length > 0 ? (
        <>
          {getRegisteredApps()?.length > 0 && (
            <div className="myApps-heading">Registered Apps</div>
          )}
          <div className="myApps-card-container" data-testid="registered-apps">
            {getRegisteredApps()?.map((data: AppData, index: any) => {
              return (
                <AppCardComponent
                  key={index}
                  index={index}
                  appData={data}
                  sourceScreen="myapps-registered" />
              );
            })}
          </div>
          {getSubmittedApps()?.length > 0 && (
            <div className="myApps-heading">Submitted Apps</div>
          )}
          <div className="myApps-card-container" data-testid="submitted-apps">
            {getSubmittedApps()?.map((data: AppData, index: any) => {
              return (
                <AppCardComponent
                  key={index}
                  index={index}
                  appData={data}
                  sourceScreen="myapps-submitted" />
              );
            })}
          </div>
          {getRejectedApps()?.length > 0 && (
            <div className="myApps-heading">Rejected Apps</div>
          )}
          <div className="myApps-card-container" data-testid="rejected-apps">
            {getRejectedApps()?.map((data: AppData, index: any) => {
              return (
                <AppCardComponent
                  key={index}
                  index={index}
                  appData={data}
                  sourceScreen="myapps-rejected"
                  deleteMyApps={deleteMyApps} />
              );
            })}
          </div>
        </>
      ) : myAppsApiCallInitiated === false &&
        myAppsApiResponse.length === 0 &&
        Object.keys(myAppsApiCallFailed).length === 0 ? (
        <EmptyMessageComponent
          message="Sorry, you have no apps registered.
Please register your app"
        />
      ) : (
        isErrorPopupVisible && (
          <ErrorPopup
            boldMessage="Error!"
            message={
              myAppsApiCallFailed?.error
                ? myAppsApiCallFailed?.error
                : deleteMyAppsApiCallFailed?.error
            }
            onClose={
              myAppsApiCallFailed?.error
                ? () => handleCloseError("my apps")
                : () => handleCloseError("delete")
            }
          />
        )
      )}
      {isSuccessPopupVisible && (
        <SuccessPopup
          boldMessage="Deleted successfully!"
          message={deleteMyAppsSuccessMessage}
          onClose={handleCloseSuccessPopup}
        />
      )}
      {showEmptyComponent && (
        <EmptyMessageComponent
          message="Sorry, you have no apps registered.
  Please register your app"
        />
      )}
    </>
  );
};

export default MyAppsView;
