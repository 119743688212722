export const GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_INITIATED =
  "GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_INITIATED";
export const GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_SUCCEED =
  "GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_SUCCEED";
export const GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_FAILED =
  "GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_FAILED";
export const POST_CREATE_PERSONA_API_INITIATED =
  "POST_CREATE_PERSONA_API_INITIATED";
export const POST_CREATE_PERSONA_API_SUCCEED =
  "POST_CREATE_PERSONA_API_SUCCEED";
export const POST_CREATE_PERSONA_API_FAILED = "POST_CREATE_PERSONA_API_FAILED";
