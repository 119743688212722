import {
  POST_APPS_TO_PERSONA_API_INITIATED,
  POST_APPS_TO_PERSONA_API_SUCCEED,
  POST_APPS_TO_PERSONA_API_FAILED,
  GET_APPS_TO_PERSONA_API_INITIATED,
  GET_APPS_TO_PERSONA_API_SUCCEED,
  GET_APPS_TO_PERSONA_API_FAILED,
  APPS_CHECKED,
  APPS_USAGE,
  APPS_USAGE_ON_BLUR,
  APPS_DESELECT_ALL,
} from "../constants/appsToPersonaConstants";

export const postAppsToPersonaApiInitiated = () => ({
  type: POST_APPS_TO_PERSONA_API_INITIATED,
});

export const postAppsToPersonaApiSucceed = (payload: any) => ({
  type: POST_APPS_TO_PERSONA_API_SUCCEED,
  payload: payload,
});

export const postAppsToPersonaApiFailed = (payload: any) => ({
  type: POST_APPS_TO_PERSONA_API_FAILED,
  payload: payload,
});

export const getAppsToPersonaApiInitiated = () => ({
  type: GET_APPS_TO_PERSONA_API_INITIATED,
});

export const getAppsToPersonaApiSucceed = (payload: any) => ({
  type: GET_APPS_TO_PERSONA_API_SUCCEED,
  payload: payload,
});

export const getAppsToPersonaApiFailed = (payload: any) => ({
  type: GET_APPS_TO_PERSONA_API_FAILED,
  payload: payload,
});

export const appsChecked = (payload: any) => ({
  type: APPS_CHECKED,
  payload: payload,
});

export const appsUsage = (payload: any) => ({
  type: APPS_USAGE,
  payload: payload,
});

export const appsUsageOnBlur = (payload: any) => ({
  type: APPS_USAGE_ON_BLUR,
  payload: payload,
});

export const appsDeselectAll = (payload: any) => ({
  type: APPS_DESELECT_ALL,
  payload: payload,
});
