export const POST_APPS_TO_PERSONA_API_INITIATED =
  "POST_APPS_TO_PERSONA_API_INITIATED";
export const POST_APPS_TO_PERSONA_API_SUCCEED =
  "POST_APPS_TO_PERSONA_API_SUCCEED";
export const POST_APPS_TO_PERSONA_API_FAILED =
  "POST_APPS_TO_PERSONA_API_FAILED";
export const GET_APPS_TO_PERSONA_API_INITIATED =
  "GET_APPS_TO_PERSONA_API_INITIATED";
export const GET_APPS_TO_PERSONA_API_SUCCEED =
  "GET_APPS_TO_PERSONA_API_SUCCEED";
export const GET_APPS_TO_PERSONA_API_FAILED = "GET_APPS_TO_PERSONA_API_FAILED";
export const APPS_CHECKED = "APPS_CHECKED";
export const APPS_USAGE = "APPS_USAGE";
export const APPS_USAGE_ON_BLUR = "APPS_USAGE_ON_BLUR";
export const APPS_DESELECT_ALL = "APPS_DESELECT_ALL";
