import React, { useEffect } from "react";
import "./StaticPages.scss";
import "./Footer.scss";
import { useLocation } from "react-router-dom";
import {
  AboutPrivacyDepartment,
  PrivacyCookiePolicy,
  PrivacyWhoWeAre,
  WhatIfNotHappy,
} from "./StaticPagesParagraphLinkContent";
import {
  HowLongKept,
  InternationalTransfers,
  PrivacyDataSecurity,
  PrivacyOtherPolicies,
  PrivacyPolicySummary,
  PrivacyWhereWeStore,
  YourRightsUnder,
} from "./StaticPagesParagraphContent";
import {
  InfoAboutYou,
  PrivacyInfoWeCollect,
  SharingInfo,
} from "./StaticPagesParagraphBulletsContent";

const PrivacyPolicy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const paragraphContent = [
    {
      title: "1. WHO WE ARE AND HOW WE CAN BE CONTACTED?",
      component: <PrivacyWhoWeAre />,
    },
    {
      title: "2. ABOUT OUR PRIVACY DEPARTMENT",
      component: <AboutPrivacyDepartment />,
    },
    {
      title: "3. OTHER POLICIES WE ENCOURAGE YOU TO READ",
      component: <PrivacyOtherPolicies />,
    },
    {
      title: "4. OUR COOKIE POLICY",
      component: <PrivacyCookiePolicy />,
    },
    {
      title: "5. INFORMATION WE MAY COLLECT FROM YOU",
      component: <PrivacyInfoWeCollect />,
    },
    {
      title: "6. INFORMATION WE COLLECT ABOUT YOU FROM OTHER PLACES",
      component: <InfoAboutYou />,
    },
    {
      title: "7. WHERE WE STORE YOUR PERSONAL INFORMATION",
      component: <PrivacyWhereWeStore />,
    },
    {
      title: "8. SHARING YOUR PERSONAL INFORMATION",
      component: <SharingInfo />,
    },
    {
      title: "9. INTERNATIONAL TRANSFERS OF PERSONAL DATA",
      component: <InternationalTransfers />,
    },
    {
      title: "10. HOW LONG WILL PERSONAL INFORMATION BE KEPT BY US?",
      component: <HowLongKept />,
    },
    {
      title: "11. YOUR RIGHTS UNDER DATA PROTECTION LEGISLATION",
      component: <YourRightsUnder />,
    },
    {
      title: "12. WHAT CAN YOU DO IF YOU'RE NOT HAPPY?",
      component: <WhatIfNotHappy />,
    },
    {
      title: "13. ABOUT DATA SECURITY",
      component: <PrivacyDataSecurity />,
    },
  ];

  return (
    <div className="main-container">
      <div className="static-pages-heading">Privacy Policy</div>
      <div className="static-pages-container">
        <div className="static-pages-sub-container">
          <div className="static-pages-container-padding">
            <div className="static-pages-sub-heading">Privacy Policy</div>
            <PrivacyPolicySummary />
            {paragraphContent.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className="static-pages-points">{item.title}</div>
                  <>{item.component}</>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
