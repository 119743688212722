import { AxiosError } from "axios";

const processSuccessResponse = (response: {
    code: number;
    message: string;
    result?: { errorDesc?: string;[key: string]: any };
    error?: string;
},
    onError: (errorData: { boldMessage: string; message: string }) => void,
    onSuccess?: (message: string) => void
) => {
    const { code, message, result, error } = response;

    if (code === 201 || code === 200) {
        onSuccess?.(message);
    } else if (code == 204) {
        if (typeof result === "string") {
            const message = result;
            onSuccess?.(message)
        }
    } else if (code === 400) {
        onError?.({ boldMessage: message, message: error || "Something went wrong!" });
    } else if (code === 401) {
        onError?.({ boldMessage: "Unauthorized", message: "Unauthorized" });
    } else {
        onError?.({ boldMessage: message, message: error || "Something went wrong!" });
    }
};

const processErrorResponse = (error: any, onError: (errorData: { boldMessage: string; message: string }) => void) => {
    if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
            onError?.({ boldMessage: "Unauthorized", message: "Unauthorized" });
        } else {
            onError?.({ boldMessage: "Error", message: "Something went wrong!" });
        }
    }
};

export {
    processSuccessResponse,
    processErrorResponse
};