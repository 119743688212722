import { initiateApiCall } from "../api";
import { AxiosError } from "axios";

const isTesting = false;

const formatNumberToK = (noOfDownloads: number) => {
  if (noOfDownloads < 1000) {
    return noOfDownloads;
  }
  //const scaled = Math.round((noOfDownloads / 1000) * 10) / 10;
  const scaled = noOfDownloads / 1000;

  return `${scaled}K`;
};

const formatNumberToSign = (noOfDownloads: number) => {
  if (noOfDownloads < 1000) {
    return noOfDownloads;
  }
  if (noOfDownloads >= 10000000)
    return `${Math.round(noOfDownloads / 10000000)}M`;
  if (noOfDownloads >= 100000) return `${Math.round(noOfDownloads / 100000)}L`;
  if (noOfDownloads >= 1000) return `${Math.round(noOfDownloads / 1000)}K`;
};

const formatDate = (date: any) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()]; // Get month name from array
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

const checkFieldEmpty = (value: string) =>
  value && value.trim().length ? "" : "Field value is empty";

const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ? ""
    : "Email is not valid";
};

const isValidURL = (url: string) => {
  try {
    new URL(url.toLowerCase());
    return "";
  } catch (e) {
    return "URL is not valid";
  }
};

const clearCacheAndCookies = () => {
  localStorage.removeItem("allApps");
  localStorage.removeItem("appStatistics");
  localStorage.clear();
  sessionStorage.clear();

  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

const getSliceValueBasedOnScreenSize = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1920) {
    return 7;
  } else if (screenWidth >= 1280) {
    return 5;
  } else if (screenWidth < 1280) {
    return 3;
  }
};

const uploadAppIcon = async (
  appIconUrl: any,
  package_name: string,
  platform_name: string
) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const iconName = package_name.split(".").pop() + "-" + platform_name;
      const file: any = await imageURLToFile(appIconUrl, iconName).catch(
        (error) => {
          throw error;
        }
      );
      const formData = new FormData();
      formData.append("multipartFile", file);
      const response = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/app/upload-icon`,
        formData,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          apikey: `${process.env.REACT_APP_API_KEY}`,
        },
        { "package-name": package_name, "platform-name": platform_name }
      );

      if (response) {
        resolve(response);
      }
    } catch (error: any) {
      if (error.status === 401) {
        reject("Error: Request failed with status code 401");
      } else if (error.status >= 400 && error.status <= 599) {
        reject(error);
      } else {
        reject(error);
      }
    }
  });

const uploadPersonaIcon = async (file: any, personaId: any) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append(
        "persona-id",
        new Blob([JSON.stringify(personaId)], {
          type: "application/json",
        })
      );
      formData.append("multipart-file", file);
      const response = await initiateApiCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/persona/upload-icon`,
        formData,
        {
          "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
          "content-type": "multipart/form-data",
          Authorization: getAuthToken(),
          User: getUserEmail(),
          apikey: `${process.env.REACT_APP_API_KEY}`,
        }
      );

      if (response) {
        resolve(response);
      }
    } catch (error: any) {
      if (error.status === 401) {
        reject("Error: Request failed with status code 401");
      } else if (error.status >= 400 && error.status <= 599) {
        reject(error);
      } else {
        reject(error);
      }
    }
  });

const checkIconSize = async (imgUrl: any) => {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  if (blob.size > 2000000) {
    return false;
  }
  return true;
};

const imageURLToFile = async (imgUrl: any, imgName: string) => {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  const mimeType = blob.type.split("/")[1];
  const file = new File([blob], `${imgName}.${mimeType}`, {
    type: blob.type,
  });

  return file;
};

const convertToBase64 = (clientId: string, clientSecret: string) => {
  return btoa(`${clientId}:${clientSecret}`);
};

const formatReviewDate = (dateString: string | null, locale: string) => {
  if (!dateString || dateString.trim() === "") return "";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(locale, options);
};

const validateTokenExpiry = async () => {
  try {
    const response: any = await initiateApiCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/platform/authorize`,
      null,
      {
        Authorization: getAuthToken(),
        apikey: `${process.env.REACT_APP_API_KEY}`,
      }
    );

    if (response.data.code === 200) {
      return true;
    } else if (response.data.code === 401) {
      return false;
    } else {
      return false;
    }
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        return false;
      } else {
        return false;
      }
    }
  }
};

const fetchUserName = async (): Promise<string> => {
  try {
    const response = await initiateApiCall(
      "get",
      `${process.env.REACT_APP_FEDERATION_USER_INFO_URI}`,
      null,
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    );
    return response.data.fullname;
  } catch (error: any) {
    console.error("Error fetching user name:", error);
    throw error;
  }
};

const getUserEmail = (): string => {
  if (isTesting) {
    return "ankita.thakare_ext@michelin.com";
  }
  const userEmail = localStorage.getItem("user_email") || "";
  if (!userEmail) {
    console.warn("User email is missing from localStorage.");
  }
  return userEmail;
};

const getAuthToken = (): string => {
  if (isTesting) {
    return "Bearer eyJ0eXAiOiJKV1QiLCJraWQiOiJXL1hDV3pvY1RDTjVrZG8rZjBncXJqMmc2UEk9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIodXNyIUc3MDMwMzkpIiwiY3RzIjoiT0FVVEgyX1NUQVRFTEVTU19HUkFOVCIsImF1dGhfbGV2ZWwiOjAsImF1ZGl0VHJhY2tpbmdJZCI6IjQ2ZGMyZWRkLTdjZjktNGU2OC1iNzMwLTk5OWI4M2UwNTc0Ny0xMjM5MDMxIiwic3VibmFtZSI6Ikc3MDMwMzkiLCJpc3MiOiJodHRwczovL2xvZ2luLWluZHVzLWZlZC5taWNoZWxpbi5jb206NDQzL2FtL29hdXRoMiIsInRva2VuTmFtZSI6ImFjY2Vzc190b2tlbiIsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJhdXRoR3JhbnRJZCI6IjdrUWRNV0p4cnpMWE5lc3IyUFlvckRTSTh4SSIsImF1ZCI6IkRFVi1NQkwiLCJuYmYiOjE3MzM3MzQyODIsImdyYW50X3R5cGUiOiJhdXRob3JpemF0aW9uX2NvZGUiLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiXSwiYXV0aF90aW1lIjoxNzMzNzM0Mjc4LCJyZWFsbSI6Ii9taWNoZWxpbiIsImV4cCI6MTczMzczNzg4MiwiaWF0IjoxNzMzNzM0MjgyLCJleHBpcmVzX2luIjozNjAwLCJqdGkiOiJwdnpSdTE2aEVGNmthYkg0bEwtMWdXLWR1SmsifQ.MidvKbQex2h76J2ISYCBXK6Hl4HV_SHDZSAur_PAp62-VeoM3QoHKOyX4X1W36p0rPew7U-oT-1VVqRQd6XtDpv_YSWZaDtjo5uqkZHTefNDO1TUJ0bVUZ6eCyb5GRcF8kcJjBXJL9WaE1fTu9l0uj3ZYNA0wnNJhnMF6Bdcyh3PGIxS029F0W6DYjEB5bsd7scNHcHXgw2g9i2hQ60ocGE1byaUmPb272_JQRRYfjWLLmgrnmtjGDienV5DqDtxj7xefx4Vngt32MmmqNE2gkiKd_ssEqQIYiUrWaRlG-SNh4KVqaENjmTAhY1Vcq87qAcfLE8pGYgprbYfzk2-fQ";
  }
  const token = localStorage.getItem("token") || "";
  if (!token) {
    console.warn("Token is missing from localStorage.");
  }
  return `Bearer ${token}`;
};

const updateAppCache = async (
  packageName: string,
  platformName: string,
  isRegistered: boolean
) => {
  let appList: any = localStorage.getItem("allApps");
  appList = JSON.parse(appList);
  appList.forEach((app: any) => {
    if (
      app["package-name"] === packageName &&
      app["platform-name"] === platformName
    ) {
      app["platform-registered"] = isRegistered;
      updateStatisticsCache(platformName, isRegistered);
    }
  });
  localStorage.setItem("allApps", JSON.stringify(appList));
};

/**
 * Updates the app statistics in the local storage based on the platform and registration status.
 *
 * This function retrieves the current app statistics from the local storage, updates the statistics
 * based on the provided platform and registration status, and then saves the updated statistics back to the local storage.
 *
 * @param {string} platformName - The name of the platform. It should be either "android" or "ios".
 * @param {boolean} isRegistered - The registration status. If true, the app is registered; if false, the app is unregistered.
 *
 */
const updateStatisticsCache = async (
  platformName: string,
  isRegistered: boolean
) => {
  let statistics: any = localStorage.getItem("appStatistics");
  statistics = JSON.parse(statistics);

  const platformKey = platformName === "android" ? "android" : "ios";
  const increment = isRegistered ? 1 : -1;

  statistics.registered[platformKey] += increment;
  statistics.registered.total += increment;

  statistics.unregistered[platformKey] -= increment;
  statistics.unregistered.total -= increment;

  localStorage.setItem("appStatistics", JSON.stringify(statistics));
};

export {
  formatDate,
  formatNumberToK,
  formatNumberToSign,
  checkFieldEmpty,
  isValidEmail,
  isValidURL,
  clearCacheAndCookies,
  getSliceValueBasedOnScreenSize,
  uploadAppIcon,
  checkIconSize,
  convertToBase64,
  formatReviewDate,
  validateTokenExpiry,
  fetchUserName,
  getUserEmail,
  getAuthToken,
  updateAppCache,
  uploadPersonaIcon,
  imageURLToFile,
};

export const isValueExistsInArray = (arrValues: string[], value: string) => {
  return arrValues?.some(
    (arrVal: string) => arrVal.toLowerCase() === value.toLowerCase()
  );
};
