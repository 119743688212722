import React from "react";
import "../CreatePersona.scss";

const CreatePersonaStepOne = ({
  selectedLB,
  handleOnChange,
  handleOnClick,
  searchedBusinessLine,
  apiResponse,
}: any) => {
  return (
    <li
      className={`StepProgress-item ${
        selectedLB.length > 0 ? "is-done" : "current"
      }`}
    >
      <div className="create-persona-select-business-line">
        Select Business Line
      </div>
      <div className="create-persona-pick-business-line-label">
        Pick any one of the following business lines
      </div>
      <div className="create-persona-search-div-wrapper">
        <input
          type="text"
          className="create-persona-search-box"
          placeholder="Search Business Line"
          onChange={handleOnChange}
        />
      </div>
      <div className="create-persona-business-lines-capsule-container">
        {searchedBusinessLine
          ? searchedBusinessLine.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`persona-business-lines-capsule ${
                    selectedLB?.[0]?.["line-of-business"] ===
                    item["line-of-business"]
                      ? "persona-business-line-capsule-color"
                      : ""
                  }`}
                  onClick={(e) =>
                    handleOnClick(e, item["line-of-business"], "business line")
                  }
                >
                  {item["line-of-business"]}
                </div>
              );
            })
          : apiResponse?.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={`persona-business-lines-capsule ${
                    selectedLB?.[0]?.["line-of-business"] ===
                    item["line-of-business"]
                      ? "persona-business-line-capsule-color"
                      : ""
                  }`}
                  onClick={(e) =>
                    handleOnClick(e, item["line-of-business"], "business line")
                  }
                >
                  {item["line-of-business"]}
                </div>
              );
            })}
      </div>
    </li>
  );
};

export default CreatePersonaStepOne;
