import React, { useState, useEffect, useRef } from "react";
import {
  userPlaceholder,
  editIcon,
  remove_icon_blue,
  moreButtonDots,
} from "../../../assets";
import FallbackImage from "../../appImage/FallBackImage";
import "./PersonaCard.scss";
import AppsToPersonaPopup from "../../../screens/myAppsScreen/AppsToPersonaPopup/AppsToPersonaPopup";
import { useNavigate } from "react-router-dom";

type PersonaProps = {
  personaDetail: any;
  fetchPersonaList: any;
};

const PersonaCard = ({ personaDetail, fetchPersonaList }: PersonaProps) => {
  const navigate = useNavigate();
  const [enablePopup, setEnablePopup] = useState(false);
  const [personImage, setPersonImage] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const moreButtonDotsRef = useRef(null);

  useEffect(() => {
    if (personaDetail["person-logo"]) {
      const image = ` ${process.env.REACT_APP_BASE_URL}/app/download-file?file-name=${personaDetail["person-logo"]}`;
      setPersonImage(image);
    } else {
      setPersonImage(userPlaceholder);
    }
  }, []);

  function useOutsideClickedAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideClickedAlerter(moreButtonDotsRef);

  return (
    <div className="persona-details">
      {enablePopup && (
        <AppsToPersonaPopup
          setEnablePopup={setEnablePopup}
          enablePopup={enablePopup}
          personaId={personaDetail["persona-id"]}
          personaName={personaDetail["persona-name"]}
          fetchPersonaList={fetchPersonaList}
        />
      )}
      <div
        onClick={() =>
          navigate("/personaDetails", {
            state: {
              personaId: personaDetail["persona-id"],
              personImage: personImage,
            },
          })
        }
        className="persona-icon-container"
      >
        <img className="person-image" src={personImage} alt="Person-img" />
      </div>
      <div className="persona-details-container">
        <div className="persona-heading-container" ref={moreButtonDotsRef}>
          <div
            onClick={() =>
              navigate("/personaDetails", {
                state: {
                  personaId: personaDetail["persona-id"],
                  personImage: personImage,
                },
              })
            }
            className="persona-heading"
          >
            {personaDetail["person-name"]}
          </div>
          <>
            <img
              className="card-icon"
              src={moreButtonDots}
              alt="morebutton"
              onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
              <div className="dropdown-menu">
                <div
                  className="dropdown-item"
                  onClick={() => console.log("handle edit")}
                >
                  <img
                    src={editIcon}
                    alt="editIcon"
                    className="dropdown-icon"
                  />{" "}
                  Edit
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => console.log("handle delete")}
                >
                  <img
                    src={remove_icon_blue}
                    alt="deleteIcon"
                    className="dropdown-icon"
                  />{" "}
                  Delete
                </div>
              </div>
            )}
          </>
        </div>

        <div
          onClick={() =>
            navigate("/personaDetails", {
              state: {
                personaId: personaDetail["persona-id"],
                personImage: personImage,
              },
            })
          }
          className="persona-name"
        >
          Persona Type: {personaDetail["persona-name"]}
        </div>
        <div
          onClick={() =>
            navigate("/personaDetails", {
              state: {
                personaId: personaDetail["persona-id"],
                personImage: personImage,
              },
            })
          }
          className="about-persona"
        >
          {personaDetail["about-persona"]}
        </div>
        <div className="persona-apps-button-container">
          <div
            onClick={() =>
              navigate("/personaDetails", {
                state: {
                  personaId: personaDetail["persona-id"],
                  personImage: personImage,
                },
              })
            }
            className="app-logos"
          >
            {personaDetail["persona-apps"]?.map(
              (appData: any, index: number) => {
                if (index < 3) {
                  return (
                    <div key={index} className="app-icon-logo">
                      <FallbackImage
                        src={appData["store-app-logo"]}
                        fallbackSrc={appData["app-logo"]}
                        alt={`${appData["app-name"]} logo`}
                      />
                    </div>
                  );
                }
              }
            )}
            {personaDetail["persona-apps"]?.length > 3 && (
              <div className="more-apps">
                +{personaDetail["persona-apps"]?.length - 3}
              </div>
            )}
          </div>
          <div
            className="persona-card-button"
            onClick={() => setEnablePopup(true)}
          >
            {personaDetail["persona-apps"]?.length > 0
              ? "Edit Assigned Apps"
              : "Assign Apps"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaCard;
