import React, { useEffect, useState } from "react";
import SubTable from "./appAnalyticsByLbContent/SubTable";
import SubTableAppList from "./appAnalyticsByLbContent/SubTableAppList";
import "./AppAnalyticsByLbScreen.scss";
import { initiateApiCall } from "../../../api/index";
import { AxiosError, AxiosResponse } from "axios";
import { clearCacheAndCookies, getAuthToken } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import {
  ErrorPopup,
  ProcessingDialog,
  EmptyMessageComponent,
} from "../../../components";

import { ErrorResponse } from "../../../interfaces/common/ErrorResponse";
import { useAuth } from "../../../routes/useAuth";
import { backIcon } from "../../../assets";

interface AnalyticsApiResponse {
  code: number;
  message: string;
  result: any;
  error: string;
}

const transformData = (appAnalyticsData: any) => {
  // pre-extraction of app names

  const appList = Object.values(appAnalyticsData).map(
    (businessDirDetails: any) => {
      const appDetails = businessDirDetails["app-lb-details"];
      return (appDetails || []).map((app: any) => app["app-name"]);
    }
  );
  const appListUnique = Array.from(new Set(appList.flat())).sort();

  // actual processing logic

  const transformedData = Object.entries(appAnalyticsData).map(
    ([bdName, bdDetails]: [any, any]) => {
      const appLBDetails = bdDetails["app-lb-details"];
      const lineOfBusiness = bdDetails["line-of-business"];
      const countOfAppsPerLB = bdDetails["app-name-count-by-lb"];
      const lineOfBusinessInfo: any = new Map();

      appLBDetails.forEach((appDetails: any, index: number) => {
        const lbName = lineOfBusiness[index];
        if (!lineOfBusinessInfo.has(lbName)) {
          lineOfBusinessInfo.set(lbName, [appDetails]);
        } else {
          const lbAppList = lineOfBusinessInfo.get(lbName);
          lineOfBusinessInfo.set(lbName, [...lbAppList, appDetails]);
        }
      });

      return {
        bdName,
        lineOfBusinessInfo,
        countOfAppsPerLB,
      };
    }
  );

  return [transformedData, appListUnique];
};

const constructTableMatrix = (transformedData: any, appListUnique: any) =>
  transformedData.map((bdDetails: any) => {
    const lineOfBusinessInfo = Array.from(bdDetails.lineOfBusinessInfo);

    const tableMatrix = appListUnique.map((appName: any) =>
      lineOfBusinessInfo.map(([, lbAppList]: any) =>
        lbAppList
          .map((appDetails: any) => appDetails["app-name"])
          .includes(appName)
      )
    );

    return {
      ...bdDetails,
      tableMatrix,
    };
  });
const AppAnalyticsByLbScreen = () => {
  const { setAuthToken }: any = useAuth();
  const [transformedData, setTransformedData] = useState([] as any);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableMatrix, setTableMatrix] = useState([] as any);
  const [appListUnique, setAppListUnique] = useState([] as any);
  const [appListUniquePristine, setAppListUniquePristine] = useState([] as any);
  const [analyticsApiInitiated, setAnalyticsApiInitiated] = useState(true);
  const [isError, setIsError] = useState("");
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const navigate = useNavigate();

  const handleError = (error: ErrorResponse | null) => {
    setErrorPopupData({
      boldMessage: error?.error || "",
      message: error?.message || "",
    });
    setIsErrorPopupVisible(error ? true : false);
  };

  // fetch app analytics data
  const fetchAppAnalyticsData = async () => {
    try {
      const response: AxiosResponse<AnalyticsApiResponse> =
        await initiateApiCall(
          "get",
          `${process.env.REACT_APP_BASE_URL}/app/analytics/by-lbs`,
          null,
          {
            Authorization: getAuthToken(),
            apikey: `${process.env.REACT_APP_API_KEY}`,
          }
        );
      if (response.data.code === 200) {
        if (
          Object.keys(response.data.result["app-result-by-lb"]).length !== 0
        ) {
          setTimeout(() => {
            const [transformedDataPristine, appListUniquePristine] =
              transformData(response.data?.result?.["app-result-by-lb"]);
            const tableMatrixFromTransformedData = constructTableMatrix(
              transformedDataPristine,
              appListUniquePristine
            );
            setAppListUnique(appListUniquePristine);
            setAppListUniquePristine(appListUniquePristine);
            setTableMatrix(tableMatrixFromTransformedData);
            setTransformedData(transformedDataPristine);
            setAnalyticsApiInitiated(false);
          }, 2000);
        } else {
          setAnalyticsApiInitiated(false);
          setIsError(
            "Sorry, you have no apps registered. Please register your app"
          );
        }
      } else if (response.data.code === 401) {
        setAnalyticsApiInitiated(false);
        clearCacheAndCookies();
        setAuthToken("");
        navigate("/");
      } else {
        setAnalyticsApiInitiated(false);
        setErrorPopupData({
          boldMessage: response.data.error,
          message: response.data.message,
        });
        setIsError("something went wrong !");
      }
    } catch (error: any) {
      setAnalyticsApiInitiated(false);
      setIsError("something went wrong !");
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else if (axiosError.response) {
          handleError(axiosError.response.data as ErrorResponse);
        } else {
          handleError({
            code: 500,
            message: "Internal Server Error",
            result: null,
            error: "Internal Server Error",
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchAppAnalyticsData();
  }, []);

  useEffect(() => {
    if (searchQuery && appListUnique.length) {
      const appListUniqueFiltered = appListUnique.filter((appName: string) =>
        appName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const tableMatrixFromTransformedData = constructTableMatrix(
        transformedData,
        appListUniqueFiltered
      );
      setAppListUnique(appListUniqueFiltered);
      setTableMatrix(tableMatrixFromTransformedData);
    } else {
      setAppListUnique(appListUniquePristine);
      const tableMatrixFromTransformedData = constructTableMatrix(
        transformedData,
        appListUniquePristine
      );
      setTableMatrix(tableMatrixFromTransformedData);
    }
  }, [searchQuery]);

  const onSearchQueryChange = (query: string) => {
    setSearchQuery(query);
  };

  if (isErrorPopupVisible) {
    return (
      <ErrorPopup
        boldMessage={errorPopupData.boldMessage}
        message={errorPopupData.message}
        onClose={() => handleError(null)}
      />
    );
  }

  if (isError) {
    return <EmptyMessageComponent message={isError} />;
  }

  return (
    <div className="main-container">
      {analyticsApiInitiated ? (
        <ProcessingDialog message="Loading..." />
      ) : (
        <>
          <div className="heading analytics-by-status-heading">
            <img
              src={backIcon}
              alt="Back Icon"
              className="back-icon"
              onClick={() => navigate(-1)}
            />
            ANALYTICS BY BUSINESS LINE
          </div>
          <div className="app-analytics-sub-container">
            <div className="app-data-table-container">
              <SubTableAppList
                appList={appListUnique}
                onSearchQueryChange={onSearchQueryChange}
              />
              <div className="app-data-sub-table-container">
                <div className="app-data-table">
                  {tableMatrix.map((bdDetails: any) => (
                    <SubTable
                      key={bdDetails.bdName}
                      businessDirName={bdDetails.bdName}
                      lineOfBusinessInfo={bdDetails.lineOfBusinessInfo}
                      tableMatrix={bdDetails.tableMatrix}
                      countOfAppsPerLB={bdDetails.countOfAppsPerLB}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AppAnalyticsByLbScreen;
