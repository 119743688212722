import {
  GET_PERSONA_DETAILS_API_INITIATED,
  GET_PERSONA_DETAILS_API_SUCCEED,
  GET_PERSONA_DETAILS_API_FAILED,
} from "../constants/personaDetailsConstants";

export const getPersonaDetailsApiInitiated = () => ({
  type: GET_PERSONA_DETAILS_API_INITIATED,
});

export const getPersonaDetailsApiSucceed = (payload: any) => ({
  type: GET_PERSONA_DETAILS_API_SUCCEED,
  payload: payload,
});

export const getPersonaDetailsApiFailed = (payload: any) => ({
  type: GET_PERSONA_DETAILS_API_FAILED,
  payload: payload,
});
