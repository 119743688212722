import { GET_TEAM_API_FAILED, GET_TEAM_API_SUCCEED, TEAM_API_FINISHED, TEAM_API_INITIATED } from "../constants/myTeamConstants";

export const getTeamApiInitiated = () => ({
    type: TEAM_API_INITIATED
});

export const getTeamApiSucceed = (payload: any) => ({
    type: GET_TEAM_API_SUCCEED,
    payload: payload,
});

export const getTeamApiFailed = (payload: string) => ({
    type: GET_TEAM_API_FAILED,
    payload: payload,
});

export const getTeamApiFinished = () => ({
    type: TEAM_API_FINISHED
});