import React, { useEffect, useState } from "react";
import { backIcon, tooltipIcon } from "../../assets";
import "./PersonaDetailsScreen.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { appleLogo, playstoreLogo } from "../../assets";
import AssignedAppsCard from "../../components/card/assignnedAppsCard/AssignedAppsCard";
import { AxiosError, AxiosResponse } from "axios";
import { initiateApiCall } from "../../api";
import {
  clearCacheAndCookies,
  getAuthToken,
  getUserEmail,
} from "../../utils/Utils";
import {
  getPersonaDetailsApiFailed,
  getPersonaDetailsApiInitiated,
  getPersonaDetailsApiSucceed,
} from "../../stateManagement/actions/personaDetailsActions";
import { useAuth } from "../../routes/useAuth";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorResponse } from "../../interfaces/common/ErrorResponse";
import ProcessingDialog from "../../components/popup/processingDialog/ProcessingDialog";
import ErrorPopup from "../../components/popup/errorPopup/ErrorPopup";
import EmptyMessageComponent from "../../components/emptyMessageComponent/EmptyMessageComponent";
import { processErrorResponse } from "../../utils/ResponseProcessor";

interface PersonaDetailsResponse {
  code: number;
  message: string;
  result: {
    "persona-id": number;
    "persona-name": string;
    "person-name": string;
    "person-logo": string;
    location: string;
    "contact-email": string;
    "about-person": string;
    goals: string;
    "created-by": string;
    "creator-name": string;
    "created-date": string;
    "platform-registered": boolean;
    deleted: boolean;
    "business-line": {
      "lb-id": number;
      "line-of-business": string;
      "business-directions": string;
      active: boolean;
    };
    "business-category": {
      "business-category-id": number;
      "business-category-name": string;
      "business-category-desc": string;
      "created-by": string;
      "creator-name": string;
      "created-date": string;
      "creation-status": string;
      "is-custom": boolean;
      "is-valid": boolean;
    };
    "business-type": {
      "business-type-id": number;
      "business-type-name": string;
      "business-type-desc": string;
    };
    "customer-type": {
      "customer-type-id": number;
      "customer-type-name": string;
      "customer-type-desc": string;
      "created-by": string;
      "creator-name": string;
      "created-date": string;
      "creation-status": string;
      "is-custom": boolean;
      "is-valid": boolean;
    };
    "persona-approval-status": {
      "approval-status": string;
      "status-updated-by": string;
      reason: string;
      "bc-status": string;
      "is-valid-bc": boolean;
      "ct-status": string;
      "is-valid-ct": boolean;
      "modify-approval-status": string;
      "modified-by": string;
      "delete-approval-status": string;
      "deleted-by": string;
    };
  };
  error: string;
}

const PersonaDetailsScreen = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { setAuthToken }: any = useAuth();
  const dispatch = useDispatch();
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [showEmptyComponent, setShowEmptyComponent] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const handleBackButton = () => {
    navigate(-1);
  };
  const {
    getPersonaDetailsApiCallInitiated,
    getPersonaDetailsApiResponse,
    getPersonaDetailsApiCallFailed,
  } = useSelector((state: any) => state.personaDetailsReducers, shallowEqual);
  const { personaListApiResponse } = useSelector(
    (state: any) => state.personaListReducers,
    shallowEqual
  );
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });

  const handleCloseAPIError = () => {
    setIsErrorPopupVisible(false);
    setShowEmptyComponent(true);
  };

  const getPersonaDetails = async () => {
    dispatch(getPersonaDetailsApiInitiated());
    try {
      const response: AxiosResponse<PersonaDetailsResponse> =
        await initiateApiCall(
          "get",
          `${process.env.REACT_APP_BASE_URL}/persona/persona-detail`,
          null,
          {
            "Persona-Token": process.env.REACT_APP_PERSONA_TOKEN,
            Authorization: getAuthToken(),
            apikey: `${process.env.REACT_APP_API_KEY}`,
            User: getUserEmail(),
          },
          {
            "persona-id": state.personaId,
          }
        );

      if (response.data.code === 200) {
        dispatch(getPersonaDetailsApiSucceed(response.data.result));
      }
    } catch (error: any) {
      processErrorResponse(error, (errorData) => {
        if (errorData.boldMessage === "Unauthorized") {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else {
          dispatch(getPersonaDetailsApiFailed(errorData));
          setErrorPopupData(errorData);
          setIsErrorPopupVisible(true);
        }
      });
    }
  };

  useEffect(() => {
    getPersonaDetails();
  }, []);

  const assignedAppsToPersona = personaListApiResponse.filter(
    (item: any) => item["persona-id"] === state.personaId
  );

  const iconMap: { [key: string]: string } = {
    ios: appleLogo,
    android: playstoreLogo,
  };

  return (
    <>
      {getPersonaDetailsApiCallInitiated ? (
        <ProcessingDialog message="Loading..." />
      ) : (
        <div className="main-container">
          {!showEmptyComponent && (
            <div className="app-details-sub-container">
              <div className="persona-details-heading">
                <div className="persona-details-label-with-icon">
                  <div>
                    <img
                      src={backIcon}
                      alt="Back Icon"
                      className="back-icon"
                      onClick={handleBackButton}
                    />
                  </div>
                  <div className="persona-details-labels">PERSONA DETAILS</div>
                </div>
              </div>
              <div className="persona-details-labels persona-details-persona-name">
                {getPersonaDetailsApiResponse["persona-name"]}
              </div>
              <div className="persona-details-icon-with-name">
                <div>
                  <img
                    src={state.personImage}
                    alt="User Placeholder Icon"
                    className="user-placeholder-icon"
                  />
                </div>
                <div>
                  <div className="persona-details-user-name">
                    {getPersonaDetailsApiResponse["person-name"]}
                  </div>
                  <div className="persona-details-business-information-section">
                    <div>
                      <div className="persona-details-business-customer-section">
                        <span className="persona-details-business-customer-section-key">
                          Business line
                        </span>{" "}
                        :{" "}
                        <span className="persona-details-business-customer-section-value">
                          {
                            getPersonaDetailsApiResponse["business-line"]?.[
                              "line-of-business"
                            ]
                          }
                        </span>
                      </div>
                      <div className="">
                        <span className="persona-details-business-customer-section-key">
                          Customer Type
                        </span>{" "}
                        :{" "}
                        <span className="persona-details-business-customer-section-value">
                          {
                            getPersonaDetailsApiResponse["customer-type"]?.[
                              "customer-type-name"
                            ]
                          }
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="persona-details-business-customer-section">
                        <span className="persona-details-business-customer-section-key">
                          Business Category
                        </span>{" "}
                        :{" "}
                        <span className="persona-details-business-customer-section-value">
                          {
                            getPersonaDetailsApiResponse["business-category"]?.[
                              "business-category-name"
                            ]
                          }
                        </span>
                      </div>
                      <div className="">
                        <span className="persona-details-business-customer-section-key">
                          Business Type
                        </span>{" "}
                        :{" "}
                        <span className="persona-details-business-customer-section-value">
                          {
                            getPersonaDetailsApiResponse["business-type"]?.[
                              "business-type-name"
                            ]
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="persona-details-email-section">
                <div>
                  <div className="persona-details-email-section-keys">
                    Email Address
                  </div>
                  <div className="persona-details-email-section-values">
                    {getPersonaDetailsApiResponse["contact-email"]}
                  </div>
                </div>
                <div className="line"></div>
                <div>
                  <div className="persona-details-email-section-keys">
                    Location
                  </div>
                  <div className="persona-details-email-section-values">
                    {getPersonaDetailsApiResponse["location"]}
                  </div>
                </div>
              </div>
              <div className="persona-details-text-area-sections">
                <div className="persona-details-text-area-labels">
                  Assigned Apps
                </div>
                <div className="assigned-apps">
                  {assignedAppsToPersona[0]["persona-apps"] !== null ? (
                    assignedAppsToPersona[0]["persona-apps"].map((app: any) => (
                      <AssignedAppsCard
                        key={app["app-name"]}
                        imageURL={
                          app["store-app-logo"]
                            ? app["store-app-logo"]
                            : app["app-logo"]
                        }
                        title={app["app-name"]}
                        icon={iconMap[app["platform-name"]]}
                      />
                    ))
                  ) : (
                    <>
                      <img
                        src={tooltipIcon}
                        className="persona-details-no-apps-info-icon"
                        alt="Information Icon"
                      />
                      <span className="persona-details-no-assigned-apps">
                        No application have been assigned to this persona
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="persona-details-text-area-sections">
                <div className="persona-details-text-area-labels">
                  About Persona
                </div>
                <div className="persona-details-text-area-values">
                  {getPersonaDetailsApiResponse["about-person"]}
                </div>
              </div>
              <div className="persona-details-text-area-sections">
                <div className="persona-details-text-area-labels">
                  Needs and Goals
                </div>
                <div className="persona-details-text-area-values">
                  {getPersonaDetailsApiResponse["goals"]}
                </div>
              </div>
            </div>
          )}
          {isErrorPopupVisible && (
            <ErrorPopup
              boldMessage={getPersonaDetailsApiCallFailed.boldMessage}
              message={getPersonaDetailsApiCallFailed.message}
              onClose={handleCloseAPIError}
            />
          )}
          {showEmptyComponent && (
            <EmptyMessageComponent message="No details found!" />
          )}
        </div>
      )}
    </>
  );
};

export default PersonaDetailsScreen;
