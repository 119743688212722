import { combineReducers } from "redux";
import registerAppReducers from "./registerAppReducers";
import myAppsReducers from "./myAppsReducers";
import dashboardReducers from "./dashboardReducers";
import appDetailsReducers from "./appDetailsReducers";
import contactUsReducers from "./contactUsReducers";
import analyticsByStatusReducers from "./analyticsByStatusReducers";
import myTeamReducers from "./myTeamReducers";
import createPersonaReducers from "./createPersonaReducers";
import personaListReducers from "./personaListReducers";
import appsToPersonaReducers from "./appsToPersonaReducers";
import personaDetailsReducers from "./personaDetailsReducers";

export default combineReducers({
  registerAppReducers,
  myAppsReducers,
  dashboardReducers,
  appDetailsReducers,
  contactUsReducers,
  analyticsByStatusReducers,
  myTeamReducers,
  createPersonaReducers,
  personaListReducers,
  appsToPersonaReducers,
  personaDetailsReducers,
});
