import React from "react";
import { editIcon, removeAppIcon } from "../../assets/index";
import { TeamMemberData } from "../../screens/myAppsScreen/myTeam/MyTeam";
import "./TeamTable.scss";

type TeamTableProps = {
    list: TeamMemberData[];
    onEdit: (member: TeamMemberData) => void;
    onDelete: (member: TeamMemberData) => void;
};

const TeamTable: React.FC<TeamTableProps> = ({ list, onEdit, onDelete }) => {
    return (
        <div className="team-table-container">
            <table data-testid="team-table" className="team-table">
                <thead>
                    <tr>
                        <th data-testid="column-name" className="name-column">Name</th>
                        <th data-testid="column-role" className="role-column">Role</th>
                        <th data-testid="column-email" className="email-column">Email</th>
                        <th data-testid="column-actions" className="action-column">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((member, index) => (
                        <tr key={member["member-id"]} data-testid={`team-member-${member["member-id"]}`} className={index % 2 === 0 ? "row-even" : "row-odd"}>
                            <td data-testid={`team-member-name-${member["member-id"]}`}>{member["member-name"]}</td>
                            <td data-testid={`team-member-role-${member["member-id"]}`}>{member["member-role"]["member-role-name"]}</td>
                            <td data-testid={`team-member-email-${member["member-id"]}`}>{member["member-email-id"]}</td>
                            <td className="action-column" data-testid={`team-member-actions-${member["member-id"]}`}>
                                <img
                                    src={editIcon}
                                    alt="Edit"
                                    className="action-icon"
                                    onClick={() => onEdit(member)}
                                    data-testid={`edit-button-${member["member-id"]}`}
                                />
                                <img
                                    src={removeAppIcon}
                                    alt="Delete"
                                    className="action-icon"
                                    onClick={() => onDelete(member)}
                                    data-testid={`delete-button-${member["member-id"]}`}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TeamTable;