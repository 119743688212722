import React, { useRef, useState, useEffect } from "react";
import "./Header.scss";
import { NavLink } from "react-router-dom";
import {
  dashboardIcon,
  dashboardIconActive,
  michelinLogo,
  myAppsIcon,
  myAppsIconActive,
  userIcon,
  appAnalyticsIconActive,
  appAnalyticsIconInactive,
  contactUsIconActive,
  contactUsIconInActive,
  developerPortalIconActive,
  developerPortalIconInActive,
} from "../../assets/index";
import { textStyle, selectedText, plainText } from "./headerStyles";
import ProfilePopup from "../profile/ProfilePopup";

const Header = () => {
  const [profileClick, setProfileClick] = useState(false);
  const userProfileRef = useRef(null);

  const setTextStyle = (isActive: boolean) => {
    if (isActive) {
      return {
        ...textStyle,
        ...selectedText,
      };
    } else {
      return {
        ...textStyle,
        ...plainText,
      };
    }
  };

  const handleOnClick = () => {
    setProfileClick(false);
  };

  const handleProfileClick = () => {
    setProfileClick(!profileClick);
  };

  function useOutsideClickedAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setProfileClick(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideClickedAlerter(userProfileRef);

  return (
    <header className="header">
      <nav className="header-nav">
        <img src={michelinLogo} alt="Michelin Logo" className="logo" />

        <NavLink
          onClick={handleOnClick}
          className={({ isActive }) => "tabs" + (isActive ? " selected" : "")}
          to="/dashboard"
          style={({ isActive }) => {
            return setTextStyle(isActive);
          }}
        >
          {({ isActive }) => {
            return (
              <>
                <img
                  src={isActive ? dashboardIconActive : dashboardIcon}
                  alt="Dashboard Icon"
                  className="icon"
                />
                <span className="menu-text">Dashboard</span>
              </>
            );
          }}
        </NavLink>
        <NavLink
          onClick={handleOnClick}
          className={({ isActive }) => "tabs" + (isActive ? " selected" : "")}
          to="/myApps"
          style={({ isActive }) => {
            return setTextStyle(isActive);
          }}
        >
          {({ isActive }) => {
            return (
              <>
                <img
                  src={isActive ? myAppsIconActive : myAppsIcon}
                  alt="My Apps Icon"
                  className="icon"
                />
                <span className="menu-text">My Apps</span>
              </>
            );
          }}
        </NavLink>
        <NavLink
          onClick={handleOnClick}
          className={({ isActive }) => "tabs" + (isActive ? " selected" : "")}
          to="/home-analytics"
          style={({ isActive }) => {
            return setTextStyle(isActive);
          }}
        >
          {({ isActive }) => {
            return (
              <>
                <img
                  src={
                    isActive ? appAnalyticsIconActive : appAnalyticsIconInactive
                  }
                  alt="My Apps Icon"
                  className="icon"
                />
                <span className="menu-text">Apps Analytics</span>
              </>
            );
          }}
        </NavLink>
        <NavLink
          onClick={handleOnClick}
          className={({ isActive }) => "tabs" + (isActive ? " selected" : "")}
          to="/contactUs"
          style={({ isActive }) => {
            return setTextStyle(isActive);
          }}
        >
          {({ isActive }) => {
            return (
              <>
                <img
                  src={isActive ? contactUsIconActive : contactUsIconInActive}
                  alt="My Apps Icon"
                  className="icon"
                />
                <span className="menu-text">Contact Us</span>
              </>
            );
          }}
        </NavLink>
        <NavLink
          onClick={handleOnClick}
          className={({ isActive }) => "tabs" + (isActive ? " selected" : "")}
          to="/developerPortal"
          style={({ isActive }) => {
            return setTextStyle(isActive);
          }}
        >
          {({ isActive }) => {
            return (
              <>
                <img
                  src={
                    isActive
                      ? developerPortalIconActive
                      : developerPortalIconInActive
                  }
                  alt="developer portal Icon"
                  className="icon"
                />
                <span className="menu-text">Developer Portal</span>
              </>
            );
          }}
        </NavLink>

        <div className="user-profile-wrapper" ref={userProfileRef}>
          <a className="user-profile">
            <img
              src={userIcon}
              alt="User Icon"
              className="user-icon"
              onClick={handleProfileClick}
            />
          </a>
          {profileClick && <ProfilePopup />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
