import React, { useState } from "react";
import "./ReviewCardComponent.scss";
import { profileIcon, editIcon } from "../../assets";
import StarRatingsComponent from "../starRatingsComponent/StarRatingsComponent";
import InputField from "../inputField/InputField";
import {
  formatReviewDate,
  getUserEmail,
  getAuthToken,
  clearCacheAndCookies,
} from "../../utils/Utils";
import { AxiosResponse } from "axios";
import { initiateApiCall } from "../../api";
import {
  processErrorResponse,
  processSuccessResponse,
} from "../../utils/ResponseProcessor";
import { useAuth } from "../../routes/useAuth";
import { useNavigate } from "react-router-dom";
import { ErrorPopup, SuccessPopup } from "../../components";

type Props = {
  review: any;
  reviewCardBackground?: string;
  appDetails: any;
  textAreaBackground?: string;
  setReviewList: any;
  setReviewData: any;
};
const reviewCardBackgroundColors: any = {
  default: "review-card-background-white",
  primary: "review-card-background-grey",
};
const reviewReplyCardBackgroundColors: any = {
  default: "review-card-background-grey",
  primary: "review-card-background-white",
};

export interface ReplyToReviewPostResponse {
  code: number;
  message: string;
  result: object;
  error: string;
}

const ReviewCardComponent = ({
  review,
  reviewCardBackground = "default",
  appDetails,
  textAreaBackground = "default",
  setReviewList,
  setReviewData,
}: Props) => {
  const [reply, setReply] = useState(false);
  const [replyText, setReplyText] = useState(
    review["reply-to-review"]?.["reply-text"] || ""
  );
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [errorPopupData, setErrorPopupData] = useState({
    boldMessage: "",
    message: "",
  });
  const [successPopupData, setSuccessPopupData] = useState({
    boldMessage: "",
    message: "",
  });

  const { setAuthToken }: any = useAuth();
  const navigate = useNavigate();

  const onFieldValueChange = (value: string, field: string) => {
    if (field === "feedback-desc") {
      setReplyText(value);
    }
  };

  const updateReviewList = (actionType: string) => {
    setReviewData((prev: any) => {
      return {
        ...prev,
        ["app-review-list"]: prev["app-review-list"].map((item: any) => {
          if (item["review-id"] === review["review-id"]) {
            return {
              ...item,
              "reply-to-review":
                actionType === "cancle"
                  ? null
                  : {
                      "reply-text": replyText,
                      "store-modified-date": new Date().toISOString(),
                    },
            };
          }
          return item;
        }),
      };
    });

    setReviewList((prev: any) => {
      return prev.map((item: any) => {
        if (item["review-id"] === review["review-id"]) {
          return {
            ...item,
            "reply-to-review":
              actionType === "cancle"
                ? null
                : {
                    "reply-text": replyText,
                    "store-modified-date": new Date().toISOString(),
                  },
          };
        }
        return item;
      });
    });
  };

  const handleCloseError = () => {
    setIsErrorPopupVisible(false);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupVisible(false);
  };

  function handleSuccessMessage(message: string) {
    setSuccessPopupData({
      boldMessage: "",
      message: message,
    });
    setIsSuccessPopupVisible(true);
  }

  const postReplyToReview = async () => {
    try {
      const response: AxiosResponse<ReplyToReviewPostResponse> =
        await initiateApiCall(
          "post",
          `${process.env.REACT_APP_BASE_URL}/app/reviews/reply`,
          {
            "reply-text": replyText,
            "review-id": review["review-id"],
            "package-name": appDetails["package-name"],
            "platform-name": appDetails["platform-name"],
          },
          {
            "MBL-APP-ID": appDetails["app-secrets"]["APP-ID"],
            "MBL-APP-SECRET": appDetails["app-secrets"]["APP-SECRET"],
            "App-Token": `${process.env.REACT_APP_REGISTRATION_TOKEN}`,
            User: getUserEmail(),
            Authorization: getAuthToken(),
            apikey: `${process.env.REACT_APP_API_KEY}`,
          }
        );

      if (response.status == 200) {
        processSuccessResponse(
          response.data,
          (errorData) => {
            if (errorData.boldMessage === "Unauthorized") {
              clearCacheAndCookies();
              setAuthToken("");
              navigate("/");
            } else {
              setErrorPopupData(errorData);
              setIsErrorPopupVisible(true);
            }
          },
          (message) => {
            handleSuccessMessage(message);
            setIsSuccessPopupVisible(true);
          }
        );
      }
    } catch (error: any) {
      processErrorResponse(error, (errorData) => {
        if (errorData.boldMessage === "Unauthorized") {
          clearCacheAndCookies();
          setAuthToken("");
          navigate("/");
        } else {
          setErrorPopupData(errorData);
          setIsErrorPopupVisible(true);
        }
      });
    }
  };

  const handlePostReplyToReview = () => {
    postReplyToReview();
    //state update to show the reply
    updateReviewList("post");
    setReply(false);
  };

  const cancleReplyToReview = () => {
    setReply(false);
    setReplyText(review["reply-to-review"]?.["reply-text"]);

    // // API Call to cancle reply to review

    //state update to show cancled reply
    //updateReviewList("cancle");
  };

  return (
    <>
      <div
        className={`card-container ${reviewCardBackgroundColors[reviewCardBackground]}`}
      >
        <img src={profileIcon} alt="profile icon" className="profile-icon" />
        <div className="content-container">
          <div className="user-name">{review["reviewer-nickname"]}</div>
          <div className="ratings-container">
            <div className="ratings">
              <StarRatingsComponent rating={review["app-rating"]} />
            </div>
            <div className="date">
              {formatReviewDate(review["review-created-date"], "en-US")}
            </div>
          </div>
          <div className="card-description">{review["app-review-body"]}</div>
          {reply ? (
            <div className="reviewReplyTextEditor">
              <InputField
                title="Reply to this review"
                placeholder="Please Enter your Reply to this review here ..."
                styleType=""
                value={replyText}
                type="textArea"
                textAreaBackground={textAreaBackground}
                onChange={(value: string) => {
                  onFieldValueChange(value, "feedback-desc");
                }}
              />
              <div className="submit-review-buttons-container">
                <button
                  className="cancel-review"
                  onClick={() => {
                    cancleReplyToReview();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="submit-review"
                  onClick={handlePostReplyToReview}
                >
                  Post
                </button>
              </div>
            </div>
          ) : review["reply-to-review"] ? (
            <div
              className={`review-reply-container ${reviewReplyCardBackgroundColors[reviewCardBackground]}`}
            >
              <div className="review-reply-heading-container">
                <div className="review-reply-heading">
                  Developer Response
                  <div className="reply-date">
                    {formatReviewDate(
                      review["reply-to-review"]["store-modified-date"],
                      "en-US"
                    )}
                  </div>
                </div>
                {appDetails["registrar-details"]["registrar-email"] ===
                  getUserEmail() ||
                appDetails["contact-details"]["owner-email"] ===
                  getUserEmail() ? (
                  <img
                    src={editIcon}
                    alt="edit icon"
                    className="icon-size"
                    onClick={() => setReply(true)}
                  />
                ) : null}
              </div>
              <div className="reply-discription">
                {review["reply-to-review"]["reply-text"]}
              </div>
            </div>
          ) : appDetails["registrar-details"]["registrar-email"] ===
              getUserEmail() ||
            appDetails["contact-details"]["owner-email"] === getUserEmail() ? (
            <div className="reply-link" onClick={() => setReply(true)}>
              <u>Reply</u>
            </div>
          ) : null}
        </div>
        {isErrorPopupVisible && (
          <ErrorPopup
            boldMessage={errorPopupData.boldMessage}
            message={errorPopupData.message}
            onClose={handleCloseError}
          />
        )}
        {isSuccessPopupVisible && (
          <SuccessPopup
            message={successPopupData.message}
            boldMessage={""}
            onClose={handleCloseSuccessPopup}
          />
        )}
      </div>
    </>
  );
};

export default ReviewCardComponent;
