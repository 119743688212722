import {
  GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_INITIATED,
  GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_SUCCEED,
  GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_FAILED,
  POST_CREATE_PERSONA_API_INITIATED,
  POST_CREATE_PERSONA_API_SUCCEED,
  POST_CREATE_PERSONA_API_FAILED,
} from "../constants/createPersonaConstants";

const initialState = {
  apiCallInitiated: false,
  apiResponse: [],
  apiCallFailed: {},
  postCreatePersonaApiCallInitiated: false,
  postCreatePersonaApiResponse: {},
  postCreatePersonaApiCallFailed: {},
};

const createPersonaReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_INITIATED:
      return {
        apiCallInitiated: true,
        apiResponse: [],
        apiCallFailed: {},
      };
    case GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_SUCCEED:
      return {
        apiCallInitiated: false,
        apiResponse: action.payload,
        apiCallFailed: {},
      };
    case GET_LINE_OF_BUSINESS_WITH_CATEGORIES_API_FAILED:
      return {
        apiCallInitiated: false,
        apiResponse: [],
        apiCallFailed: action.payload,
      };
    case POST_CREATE_PERSONA_API_INITIATED:
      return {
        postCreatePersonaApiCallInitiated: true,
        postCreatePersonaApiCallResponse: {},
        postCreatePersonaApiCallFailed: {},
      };
    case POST_CREATE_PERSONA_API_SUCCEED:
      return {
        postCreatePersonaApiCallInitiated: false,
        postCreatePersonaApiCallResponse: action.payload,
        postCreatePersonaApiCallFailed: {},
      };
    case POST_CREATE_PERSONA_API_FAILED:
      return {
        postCreatePersonaApiCallInitiated: false,
        postCreatePersonaApiCallResponse: {},
        postCreatePersonaApiCallFailed: action.payload,
      };
    default:
      return state;
  }
};

export default createPersonaReducers;
