import {
    GET_TEAM_API_FAILED,
    GET_TEAM_API_SUCCEED,
    TEAM_API_FINISHED,
    TEAM_API_INITIATED
} from "../constants/myTeamConstants";

const initialState = {
    apiCallInitiated: true,
    createTeamApiResponse: [],
    createTeamApiFailed: {},
    myTeamApiResponse: [],
    myTeamApiFailed: ""
};

const myTeamReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case TEAM_API_INITIATED:
            return {
                apiCallInitiated: true,
                createTeamApiResponse: [],
                createTeamApiFailed: {}
            };
        case GET_TEAM_API_SUCCEED:
            return {
                apiCallInitiated: false,
                myTeamApiResponse: action.payload,
                myTeamApiFailed: {}
            };
        case GET_TEAM_API_FAILED:
            return {
                apiCallInitiated: false,
                myTeamApiResponse: [],
                myTeamApiFailed: action.payload
            };
        case TEAM_API_FINISHED:
            return {
                apiCallInitiated: false,
            };
        default:
            return state;
    }
};

export default myTeamReducers;